import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Page } from '../../../../common';

const useStyles = makeStyles((theme) => ({
  page: {
    height: `calc(100vh - 64px)`,
    overflow: 'hidden',
    display: 'flex',
  },
}));

const Home = () => {
  const classes = useStyles();

  return <Page className={classes.page}>HOME</Page>;
};

Home.propTypes = {};

export default Home;
