import React from 'react';

import RecentActorsIcon from '@material-ui/icons/RecentActors';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

import i18n from '../../i18n';

const menuItems = [
  {
    name: i18n.t`Client`,
    icon: <RecentActorsIcon />,
    subItems: [
      {
        name: i18n.t`List`,
        path: '/admin/clients',
        icon: <BuildOutlinedIcon fontSize="small" />,
      },
    ],
    access: ['superadmin', 'manager'],
  },
];

export default menuItems;
