import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';

import OrderInfo from './OrderInfo';
import Complete from './Complete';
import Decline from './Decline';
import Fail from './Fail';
import EditOrderForm from '../../EditOrderForm';

import useOrderChangeHandler from './helpers/useOrderChangeHandler';
import { addOrder, removeOrder } from '../../../../reducers/ordersToClose';
import VIEWS from './views';
import { useShifts } from '../../../../../user/hooks';
import useClient from '../../../../../client/hooks/useClient';

const AppointmentTooltipContent = (props) => {
  const ref = useRef();
  const { appointmentData, onComplete } = props;
  const { client, load } = useClient();
  const { items: shifts = [] } = useShifts();
  const dispatch = useDispatch();

  const [currentView, setCurrentView] = useState(VIEWS.OrderInfo);
  const { id: orderId, userId, client: orderClient, startDate, endDate, services } = appointmentData;
  const { handleOnOrderChange, loading: orderEditing } = useOrderChangeHandler({ orderId, onComplete });

  useEffect(() => {
    load(orderClient.id);
    // eslint-disable-next-line
  }, []);

  const user = useMemo(() => shifts.find((_) => _.user.id === userId).user, [shifts, userId]);

  const initialValues = useMemo(() => {
    return {
      startTime: startDate,
      endTime: endDate,
      client,
      services,
    };
  }, [startDate, endDate, services, client]);

  const handleOnOrderEditComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const handleOnComplete = useCallback(() => {
    setCurrentView(VIEWS.Complete);
  }, [setCurrentView]);

  const handleOnEdit = useCallback(() => {
    setCurrentView(VIEWS.Edit);
  }, [setCurrentView]);

  const handleOnAdd = useCallback(
    (isAdd) => {
      if (isAdd) {
        dispatch(addOrder(appointmentData));
      } else {
        dispatch(removeOrder(appointmentData));
      }
    },
    [dispatch, appointmentData]
  );

  const handleOnDecline = useCallback(() => {
    setCurrentView(VIEWS.Decline);
  }, [setCurrentView]);

  const handleOnFail = useCallback(() => {
    setCurrentView(VIEWS.Fail);
  }, [setCurrentView]);

  return useMemo(() => {
    let content;

    if (currentView === VIEWS.OrderInfo && client) {
      content = (
        <OrderInfo
          {...appointmentData}
          client={client}
          user={user}
          onComplete={handleOnComplete}
          onEdit={handleOnEdit}
          onDecline={handleOnDecline}
          onAdd={handleOnAdd}
          onFail={handleOnFail}
          onOrderChange={handleOnOrderChange}
          loading={orderEditing}
        />
      );
    }

    if (currentView === VIEWS.Complete) {
      content = <Complete {...appointmentData} onComplete={onComplete} />;
    }

    if (currentView === VIEWS.Decline) {
      content = <Decline {...appointmentData} onComplete={onComplete} />;
    }

    if (currentView === VIEWS.Fail) {
      content = <Fail {...appointmentData} onComplete={onComplete} />;
    }

    if (currentView === VIEWS.Edit) {
      content = (
        <EditOrderForm
          initialValues={initialValues}
          userId={user.id}
          orderId={orderId}
          onComplete={handleOnOrderEditComplete}
        />
      );
    }

    return <Box ref={ref}>{content}</Box>;
    // eslint-disable-next-line
  }, [currentView, appointmentData, client, user, initialValues, orderId, endDate, services, startDate, orderEditing]);
};

AppointmentTooltipContent.propTypes = {
  appointmentData: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

AppointmentTooltipContent.defaultProps = {
  onComplete: () => {},
};

export default AppointmentTooltipContent;
