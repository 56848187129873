import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { Text, formatPhone } from '../../../../common';
import { useStyle } from './styles';

const ClientCard = (props) => {
  const { isActive, onClick, client } = props;
  const classes = useStyle();

  return (
    <Paper className={clsx(classes.cardWrapper, isActive && classes.cardSelected)} onClick={onClick}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Text ghost italic>
            #{client.id}
          </Text>
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Text>
              {client.firstName} {client.lastName}
            </Text>
          </Box>
          <Box>
            <Text h5 ghost bold>
              {client.instagram || ''}
            </Text>
          </Box>
          <Box>
            <Text h5 bold>
              {formatPhone(client.phoneNumber)}
            </Text>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

ClientCard.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    instagram: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export default ClientCard;
