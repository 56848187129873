import takayama from '../../../../../assets/images/inarifood/rolls/takayama.webp';
import nikibi from '../../../../../assets/images/inarifood/rolls/nikibi.webp';
import futomakTuna from '../../../../../assets/images/inarifood/rolls/futomak_tuna.jpeg';
import kitsune from '../../../../../assets/images/inarifood/rolls/kitsune.webp';
import yamogatoClassic from '../../../../../assets/images/inarifood/rolls/yamogato_classic.webp';
import blackDragonClassic from '../../../../../assets/images/inarifood/rolls/black_dragon_classic.webp';
import philaClassic from '../../../../../assets/images/inarifood/rolls/phila_classic.webp';
import phila from '../../../../../assets/images/inarifood/rolls/phila.webp';
import philaTuna from '../../../../../assets/images/inarifood/rolls/phila_tuna.webp';
import natsu from '../../../../../assets/images/inarifood/rolls/natsu.webp';
import blackDragon from '../../../../../assets/images/inarifood/rolls/black_dragon.webp';
import californiaSake from '../../../../../assets/images/inarifood/rolls/california_sake.webp';
import piraHot from '../../../../../assets/images/inarifood/rolls/pira_hot.webp';
import philaQuri from '../../../../../assets/images/inarifood/rolls/phila_quri.webp';
import rikkiRoll from '../../../../../assets/images/inarifood/rolls/rikki_roll.webp';
import chisu from '../../../../../assets/images/inarifood/rolls/chisu.webp';
import tobirama from '../../../../../assets/images/inarifood/rolls/tobirama.webp';
import satori from '../../../../../assets/images/inarifood/rolls/satori.webp';
import kurosaki from '../../../../../assets/images/inarifood/rolls/kurosaki.webp';
import kasama from '../../../../../assets/images/inarifood/rolls/kasama.webp';
import californiaCaviarShrimp from '../../../../../assets/images/inarifood/rolls/california_caviar_shrimp.webp';
import californiaCaviarSalmon from '../../../../../assets/images/inarifood/rolls/california_caviar_salmon.webp';
import californiaCaviarUnagi from '../../../../../assets/images/inarifood/rolls/california_caviar_unagi.webp';
import californiaCaviarTuna from '../../../../../assets/images/inarifood/rolls/california_caviar_tuna.webp';
import segu from '../../../../../assets/images/inarifood/rolls/segu.webp';
import tsunade from '../../../../../assets/images/inarifood/rolls/tsunade.webp';
import yamagato from '../../../../../assets/images/inarifood/rolls/yamagato.webp';
import philaTiger from '../../../../../assets/images/inarifood/rolls/phila_tiger.webp';
import bilisi from '../../../../../assets/images/inarifood/rolls/bilisi.webp';
import greenDragon from '../../../../../assets/images/inarifood/rolls/green_dragon.webp';
import sakeRed from '../../../../../assets/images/inarifood/rolls/sake_red.webp';
import philaMix from '../../../../../assets/images/inarifood/rolls/phila_mix.webp';
import philaLight from '../../../../../assets/images/inarifood/rolls/phila_light.webp';
import taripuru from '../../../../../assets/images/inarifood/rolls/taripuru.webp';
import rainbowDragon from '../../../../../assets/images/inarifood/rolls/rainbow_dragon.webp';
import philaGreen from '../../../../../assets/images/inarifood/rolls/phila_green.webp';
import fiji from '../../../../../assets/images/inarifood/rolls/fiji.webp';
import philaAvocado from '../../../../../assets/images/inarifood/rolls/phila_avocado.webp';

export default [
  {
    title: 'Такаяма',
    ingredients: ['окунь в кляре', 'кунжут', 'сливочный сыр', 'спайс соус', 'огурец', 'рис', 'нори'],
    image: takayama,
    price: 101,
  },
  {
    title: 'Никиби',
    ingredients: ['угорь', 'манго', 'сливочный сыр', 'тобико, кунжут', 'унаги соус', 'рис', 'нори'],
    image: nikibi,
    price: 199,
  },
  {
    title: 'Футомак тунец',
    ingredients: ['тунец', 'сливочный сыр', 'огурец', 'рис', 'нори'],
    image: futomakTuna,
    price: 122,
  },
  {
    title: 'Кицунэ',
    ingredients: ['форель', 'окунь', 'огурец', 'спайси соус', 'салат айсберг', 'рис', 'нори'],
    image: kitsune,
    price: 100,
  },
  {
    title: 'Ямагато классик',
    ingredients: ['форель', 'креветка тигровая', 'огурец', 'рис', 'нори'],
    image: yamogatoClassic,
    price: 156,
  },
  {
    title: 'Черный дракон классик',
    ingredients: ['угорь', 'огурец', 'красная икра тобико', 'рис', 'нори', 'сыр сливочный', 'унаги соус'],
    image: blackDragonClassic,
    price: 101,
  },
  {
    title: 'Филадельфия классик',
    ingredients: ['форель', 'огурец', 'рис', 'нори', 'сыр сливочный'],
    image: philaClassic,
    price: 145,
  },
  {
    title: 'Филадельфия',
    ingredients: ['форель', 'авокадо', 'огурец', 'рис', 'нори', 'сыр сливочный'],
    image: phila,
    price: 236,
  },
  {
    title: 'Филадельфия тунец',
    ingredients: ['тунец', 'авокадо', 'сыр сливочный', 'огурец', 'рис', 'нори'],
    image: philaTuna,
    price: 225,
  },
  {
    title: 'Нацу',
    ingredients: ['креветка темпура', 'икра Тобико', 'спайси соус', 'лист салата', 'рис', 'нори'],
    image: natsu,
    price: 158,
  },
  {
    title: 'Черный дракон',
    ingredients: ['угорь', 'авокадо', 'красная икра тобико', 'рис', 'нори', 'сыр сливочный', 'унаги соус'],
    image: blackDragon,
    price: 335,
  },
  {
    title: 'Калифорния сякэ',
    ingredients: ['форель', 'авокадо', 'сыр сливочный', 'кунжут', 'рис', 'нори'],
    image: californiaSake,
    price: 198,
  },
  {
    title: 'Пира хот',
    ingredients: ['кляр - снежный краб', 'сыр чеддер', 'икра тобико красная', 'спайси соус', 'помидор', 'рис', 'нори'],
    image: piraHot,
    price: 169,
  },
  {
    title: 'Филадельфия кюри',
    ingredients: ['форель', 'огурец', 'красная икра тобико', 'сыр сливочный', 'рис', 'нори'],
    image: philaQuri,
    price: 166,
  },
  {
    title: 'Рикки ролл',
    ingredients: [
      'форель',
      'креветка кляр',
      'авокадо',
      'японский майонез',
      'икра тобико чёрная',
      'красная',
      'рис',
      'нори',
    ],
    image: rikkiRoll,
    price: 309,
  },
  {
    title: 'Чизу',
    ingredients: ['креветка тигровая', 'снежный краб', 'сыр чедер', 'помидор', 'спайси соус', 'рис', 'нори'],
    image: chisu,
    price: 176,
  },
  {
    title: 'Тобирама',
    ingredients: ['форель', 'тунец', 'креветка тигровая', 'авокадо', 'зелёная икра тобико', 'икра красная', 'нори'],
    image: tobirama,
    price: 278,
  },
  {
    title: 'Сатори',
    ingredients: [
      'форель',
      'тунец',
      'авокадо',
      'икра красная',
      'сыр сливочный',
      'огурец',
      'красная икра тобико',
      'нори',
    ],
    image: satori,
    price: 345,
  },
  {
    title: 'Куросаки',
    ingredients: [
      'угорь',
      'креветка тигровая',
      'авокадо',
      'икра тобико оранжевая',
      'чёрная',
      'сыр сливочный',
      'снежный краб',
      'спайси соус',
      'нори',
    ],
    image: kurosaki,
    price: 355,
  },
  {
    title: 'Казама',
    ingredients: ['форель', 'креветка тигровая', 'тунец', 'сыр сливочный', 'красная икра тобико', 'авокадо', 'нори'],
    image: kasama,
    price: 330,
  },
  {
    title: 'Калифорния икра креветка',
    ingredients: [
      'креветка тигровая',
      'тунец',
      'красная икра тобико',
      'авокадо',
      'огурец',
      'майонез японский',
      'рис',
      'нори',
    ],
    image: californiaCaviarShrimp,
    price: 294,
  },
  {
    title: 'Калифорния икра лосось',
    ingredients: ['форель', 'красная икра тобико', 'авокадо', 'рис', 'майонез японский', 'огурец нори'],
    image: californiaCaviarSalmon,
    price: 252,
  },
  {
    title: 'Калифорния икра унаги',
    ingredients: ['угорь', 'майонез японский', 'красная икра тобико', 'огурец', 'авокадо', 'нори'],
    image: californiaCaviarUnagi,
    price: 315,
  },
  {
    title: 'Калифорния икра тунец',
    ingredients: ['тунец', 'красная икра тобико', 'авокадо', 'огурец', 'рис', 'японский майонез', 'нори'],
    image: californiaCaviarTuna,
    price: 283,
  },
  {
    title: 'Сёгу',
    ingredients: ['тунец', 'форель', 'авокадо', 'окунь', 'сыр сливочный', 'икра тобико зелёная', 'нори'],
    image: segu,
    price: 345,
  },
  {
    title: 'Цунадэ',
    ingredients: ['форель', 'тунец', 'креветка тигровая', 'авокадо', 'икра тобико зелёная', 'нори в панировке'],
    image: tsunade,
    price: 336,
  },
  {
    title: 'Ямагато',
    ingredients: ['форель', 'сыр сливочный', 'огурец', 'креветка темпура', 'унаги соус', 'кунжут', 'рис', 'нори'],
    image: yamagato,
    price: 309,
  },
  {
    title: 'Филадельфия тигровая',
    ingredients: ['тигровая креветка', 'авокадо', 'икра тобико', 'сыр сливочный', 'нори', 'рис'],
    image: philaTiger,
    price: 278,
  },
  {
    title: 'Билиси',
    ingredients: ['угорь', 'форель', 'огурец', 'икра тобико чёрная', 'красная', 'сыр сливочный', 'нори', 'рис'],
    image: bilisi,
    price: 325,
  },
  {
    title: 'Зеленый дракон',
    ingredients: ['угорь', 'огурец', 'красная икра тобико', 'авокадо', 'сыр сливочный', 'унаги соус', 'рис', 'нори'],
    image: greenDragon,
    price: 271,
  },
  {
    title: 'Сяке RED',
    ingredients: ['форель', 'красная икра тобико', 'сыр сливочный', 'дикий рис', 'нори'],
    image: sakeRed,
    price: 187,
  },
  {
    title: 'Филадельфия микс',
    ingredients: ['форель', 'угорь', 'сыр сливочный', 'авокадо'],
    image: philaMix,
    price: 320,
  },
  {
    title: 'Филадельфия лайт',
    ingredients: ['форель', 'сыр сливочный', 'рис', 'нори'],
    image: philaLight,
    price: 225,
  },
  {
    title: 'Тарипуру',
    ingredients: ['форель', 'креветка', 'сыр сливочный', 'авокадо', 'сыр', 'икра тобико красная', 'рис', 'нори'],
    image: taripuru,
    price: 313,
  },
  {
    title: 'Радужный дракон',
    ingredients: [
      'форель',
      'угорь',
      'окунь',
      'тунец',
      'авокадо',
      'сыр сливочный',
      'огурец',
      'рис',
      'красная икра тобико',
      'нори',
    ],
    image: rainbowDragon,
    price: 278,
  },
  {
    title: ' Филадельфия GREEN',
    ingredients: ['форель', 'красная икра тобико', 'сыр сливочный', 'авокадо', 'огурец', 'рис', 'нори'],
    image: philaGreen,
    price: 215,
  },
  {
    title: 'Фиджи',
    ingredients: [
      'угорь',
      'креветка',
      'унаги соус',
      'авокадо',
      'сыр сливочный',
      'оранжевая икра тобико',
      'рис',
      'нори',
    ],
    image: fiji,
    price: 315,
  },
  {
    title: 'Филадельфия с авокадо',
    ingredients: ['форель', 'авокадо', 'сыр сливочный', 'рис', 'нори'],
    image: philaAvocado,
    price: 246,
  },
];
