import React, { useEffect, useState } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { TimePicker } from '@material-ui/pickers';

const FormTimePicker = ({ field, form, className = '', ...props }) => {
  const [value, setValue] = useState(form.values[props.id]);
  const error = form.errors[props.id];

  useEffect(() => {
    if (value) {
      form.setFieldValue(props.id, value);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleOnChange = (date) => {
    date && setValue(date);
  };

  return (
    <>
      <FormControl fullWidth className={`${className}`}>
        <TimePicker {...props} {...field} clearable ampm={false} value={value} onChange={handleOnChange} />
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default FormTimePicker;
