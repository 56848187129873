import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editShiftUrl = `${REACT_APP_API_URL}/user/shift`;

const useRemoveShift = () => {
  const { del } = useRequest();

  const removeShift = ({ id, onStart, onSuccess, onError }) => {
    onStart();
    del({
      url: `${editShiftUrl}/${id}`,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { removeShift };
};

export default useRemoveShift;
