import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    isNotifiedIcon: {
      color: theme.palette.text.disabled,
    },
    notNotified: {
      color: theme.palette.text.disabled,
    },
    buttonWrapper: {
      padding: theme.spacing(0.5),
    },
  };
});
