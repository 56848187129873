import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ProductsCartContext = createContext({});

const ProductsCartProvider = (props) => {
  const { children } = props;
  const [cart, setCart] = useState({});

  const itemsInCartById = useCallback(
    (id) => {
      const key = `${id}`;
      return cart[key] || 0;
    },
    [cart]
  );

  const addItemToCart = useCallback(
    ({ id }) => {
      const currentValue = itemsInCartById(id);
      setCart({ ...cart, [`${id}`]: currentValue + 1 });
    },
    [cart, itemsInCartById]
  );

  const emptyCart = useCallback(() => setCart({}), []);

  return (
    <ProductsCartContext.Provider value={{ addItemToCart, cart, emptyCart }}>{children}</ProductsCartContext.Provider>
  );
};

ProductsCartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductsCartProvider;

export const useProductsCartProvider = () => {
  const { addItemToCart, cart, emptyCart } = useContext(ProductsCartContext);
  return { addItemToCart, cart, emptyCart };
};
