import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    cashIcon: {
      color: theme.palette.success.light,
    },
    cardIcon: {
      color: theme.palette.warning.light,
    },
    atmIcon: {
      color: theme.palette.info.light,
    },
  };
});
