import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';

export const SellCardContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const SellCardItem = styled(Paper)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 20px;
`;

export const SellCardItemUpLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SellCardItemDownLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
