import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const serviceUrl = `${REACT_APP_API_URL}/service`;

const useService = (id, skipLanguage) => {
  const { get } = useRequest();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (service) => {
    setService(service);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    get({ url: `${serviceUrl}/${id}`, skipLanguage })
      .then(handleSuccess)
      .catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { service, loading, setService, setLoading };
};

export default useService;
