import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import { loadMessagesStarted, loadMessagesSuccess, loadMessagesFailed } from '../reducers/messages';

const { REACT_APP_API_URL } = process.env;

const createMessageUrl = `${REACT_APP_API_URL}/message`;

const useCreateMessage = () => {
  const { post } = useRequest();
  const { data } = useSelector(({ messages }) => messages);
  const dispatch = useDispatch();

  const start = () => {
    dispatch(loadMessagesStarted());
  };

  const onSuccess = (message) => {
    dispatch(
      loadMessagesSuccess({
        ...data,
        items: [message, ...data.items],
      })
    );
  };

  const handleError = (error) => {
    console.log(error);
    dispatch(loadMessagesFailed());
  };

  const load = ({ text }) => {
    start();
    post({
      url: createMessageUrl,
      body: { text },
    })
      .then(onSuccess)
      .catch(handleError);
  };

  return { load };
};

export default useCreateMessage;
