import React from 'react';

import { DayView } from '@devexpress/dx-react-scheduler-material-ui';

import { useStyle } from './styles';

const CellComponent = (props) => {
  const { groupingInfo } = props;
  const classes = useStyle();

  return <td className={classes.dayCell}>{groupingInfo[0].text}</td>;
};

const DayScaleLayout = (props) => {
  return <DayView.DayScaleLayout {...props} groupingPanelComponent={() => null} cellComponent={CellComponent} />;
};

DayScaleLayout.propTypes = {};

export default DayScaleLayout;
