import { makeStyles } from '@material-ui/core/styles';

const siderWidth = 300;

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      height: 'calc(100vh - 64px)',
      display: 'flex',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    sider: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: siderWidth,
        height: '100%',
      },
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    mainData: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${siderWidth}px)`,
        height: '100%',
        overflow: 'auto',
        paddingLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    tabContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    stickyContainer: {
      position: 'sticky',
      top: 0,
    },
    addButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
      transition: '.5s',
    },
    addButtonScrolled: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.background.default,
      transition: '.5s',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
      },
    },
  };
});
