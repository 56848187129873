import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  price: {
    marginRight: theme.spacing(2),
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));
