import { css } from 'styled-components';

export const media = {
  xs: (styles) =>
    css`
      @media (min-width: 320px) {
        ${styles}
      }
    `,
  sm: (styles) =>
    css`
      @media (min-width: 768px) {
        ${styles}
      }
    `,
  md: (styles) =>
    css`
      @media (min-width: 1024px) {
        ${styles}
      }
    `,
  lg: (styles) =>
    css`
      @media (min-width: 1440px) {
        ${styles}
      }
    `,
  xl: (styles) =>
    css`
      @media (min-width: 1920px) {
        ${styles}
      }
    `,
};
