import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@material-ui/pickers';

import { RangePickerContainer } from './atoms';

const RangePicker = (props) => {
  const { onRangeSelected, disableSecondDate } = props;
  const [dateFrom, setDateFrom] = useState(moment(new Date()));
  const [dateTo, setDateTo] = useState(moment(new Date()));
  const { t } = useTranslation();

  useEffect(() => {
    if (dateFrom.isSameOrBefore(dateTo)) {
      onRangeSelected(dateFrom, dateTo);
    }
    // eslint-disable-next-line
  }, [dateFrom, dateTo]);

  const handleOnDateFromChange = (date) => {
    setDateFrom(date);
  };

  const handleOnDateToChange = (date) => {
    setDateTo(date);
  };

  return (
    <RangePickerContainer>
      <DatePicker
        label={t`From`}
        value={dateFrom}
        onChange={handleOnDateFromChange}
        format="DD MMM, YYYY"
        animateYearScrolling
        autoOk
      />
      <DatePicker
        label={t`To`}
        value={dateTo}
        onChange={handleOnDateToChange}
        format="DD MMM, YYYY"
        animateYearScrolling
        autoOk
        disabled={disableSecondDate}
      />
    </RangePickerContainer>
  );
};

RangePicker.propTypes = {
  onRangeSelected: PropTypes.func.isRequired,
  disableSecondDate: PropTypes.bool,
};

export default RangePicker;
