import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import idx from 'idx';

import Grid from '@material-ui/core/Grid';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Fab from '@material-ui/core/Fab';

import { resetOrdersToClose } from '../../../reducers/ordersToClose';
import { useChangeOrderStatusBulk, useOrders } from '../../../hooks';
import OrderToClose from './OrderToClose';
import { LoadingButton, ModalButton, MoneyType, Text } from '../../../../common';
import { useStyle } from './styles';
import { OrderStatus } from '../../../constants';

const BulkCloseOrders = () => {
  const { items } = useSelector(({ ordersToClose }) => ordersToClose);
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [isValid, setIsValid] = useState(true);
  const classes = useStyle();
  const { items: orders, set, start, fail, isLoading } = useOrders();
  const { changeOrderStatusBulk } = useChangeOrderStatusBulk();
  const dispatch = useDispatch();

  const getTotalPrice = useCallback(
    (id) => {
      const item = idx(items, (_) => _.find((_) => _.id === id));
      return item ? item.services.reduce((sum, { price }) => sum + +price, 0) : 0;
    },
    [items]
  );

  useEffect(() => {
    setData(
      items.reduce(
        (acc, { id, services }) => ({
          ...acc,
          [id]: {
            payments: services.map(({ price, amount }) => ({ amount: +price * amount, paymentType: MoneyType.CASH })),
            note: '',
            totalPrice: getTotalPrice(id),
          },
        }),
        {}
      )
    );
  }, [items, getTotalPrice]);

  const total = useMemo(
    () =>
      Object.keys(data)
        .map((key) => data[key].payments)
        .flat()
        .reduce((sum, { amount }) => sum + amount, 0),
    [data]
  );

  const handleOnChange = useCallback(
    ({ id, values, note, isValid }) => {
      setData({
        ...data,
        [id]: {
          payments: values,
          note,
          totalPrice: getTotalPrice(id),
        },
      });
      setIsValid(isValid);
    },
    [getTotalPrice, data]
  );

  const onStart = () => {
    start();
  };

  const onSuccess = (result) => {
    set(
      orders.map((_) => {
        const updatedOrder = result[_.id];

        if (!updatedOrder) return _;

        const { status, payments } = updatedOrder;

        return {
          ..._,
          status,
          payments,
        };
      })
    );
    dispatch(resetOrdersToClose());
  };

  const onError = (error) => {
    console.log(error);
    fail();
  };

  const handleOnBulkOrdersClose = useCallback(
    () => {
      const params = {
        orders: Object.keys(data).map((id) => ({
          id: +id,
          data: {
            ...data[id],
            status: OrderStatus.COMPLETED,
          },
        })),
      };
      changeOrderStatusBulk({ params, onSuccess, onError, onStart });
    },
    // eslint-disable-next-line
    [data, changeOrderStatusBulk]
  );

  return useMemo(() => {
    if (!items.length) return null;

    return (
      <ModalButton
        buttonComponent={
          <Fab color="primary" size="medium" className={classes.closeOrdersButton}>
            <Text pushRight>{items.length}</Text>
            <DoneAllIcon fontSize="small" />
          </Fab>
        }
        title={t`Close orders`}
      >
        <Grid container>
          {items.map((item) => (
            <OrderToClose
              {...item}
              payments={idx(data, (_) => _[item.id].payments)}
              key={`bill-${item.id}`}
              onChange={handleOnChange}
            />
          ))}
          <Grid container>
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              disabled={!isValid || isLoading}
              onClick={handleOnBulkOrdersClose}
              loading={isLoading}
            >
              {t(`Pay {{total}} UAH and close`, { total })}
            </LoadingButton>
          </Grid>
        </Grid>
      </ModalButton>
    );
    // eslint-disable-next-line
  }, [items, classes, t, total, handleOnBulkOrdersClose]);
};

BulkCloseOrders.propTypes = {};

export default BulkCloseOrders;
