import React from 'react';
import PropTypes from 'prop-types';

import { RoleContext } from '.';
import { useRoles } from '../hooks';

const RolesProvider = (props) => {
  const { children } = props;
  const { loading, setLoading, setRoles, roles } = useRoles();

  return <RoleContext.Provider value={{ loading, setLoading, setRoles, roles }}>{children}</RoleContext.Provider>;
};

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  departmentId: PropTypes.number,
};

export default RolesProvider;
