import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editUserUrl = `${REACT_APP_API_URL}/user`;

const useEditUser = () => {
  const { patch } = useRequest();

  const editUser = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    patch({
      url: `${editUserUrl}/${id}`,
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { editUser };
};

export default useEditUser;
