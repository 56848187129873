import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormTimePicker } from '../../../../../form';

const TimeFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        id="startTime"
        name="startTime"
        label={t`Start time`}
        placeholder={t`Start time`}
        minutesStep={5}
        component={FormTimePicker}
      />
      <Field
        id="endTime"
        name="endTime"
        label={t`End time`}
        placeholder={t`End time`}
        minutesStep={5}
        component={FormTimePicker}
      />
    </>
  );
};

TimeFields.propTypes = {};

export default TimeFields;
