import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const cashboxHistoryUrl = `${REACT_APP_API_URL}/cashbox`;

const useCashboxHistory = () => {
  const { get } = useRequest();
  const [operations, setOperations] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccess = useCallback(
    (orders) => {
      setOperations(orders);
      setLoading(false);
    },
    [setOperations, setLoading]
  );

  const handleError = useCallback(
    (error) => {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setLoading(false);
    },
    [enqueueSnackbar, setLoading]
  );

  const loadCashbox = useCallback(
    ({ dateFrom, dateTo }) => {
      setLoading(true);
      get({
        url: cashboxHistoryUrl,
        queryParams: {
          ...(dateFrom ? { dateFrom } : {}),
          ...(dateTo ? { dateTo } : {}),
        },
      })
        .then(handleSuccess)
        .catch(handleError);
    },
    [setLoading, get, handleSuccess, handleError]
  );

  return { operations, loading, setOperations, setLoading, loadCashbox };
};

export default useCashboxHistory;
