import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { skipEmptyValues } from '../../../../common';
import { useClients, useCreateClient } from '../../../hooks';
import { RowWrapper, CircularProgressWrapper } from './atoms';
import { FirstNameField, InstagramField, LastNameField, PhoneNumberField, SexField } from './fields';

const initialValues = {
  firstName: '',
  lastName: '',
  instagram: '',
  phoneNumber: '+380',
  sex: 'F',
};

const validationSchema = object().shape({
  firstName: string().required('Required'),
  lastName: string(),
  instagram: string(),
  phoneNumber: string()
    .required('Required')
    .matches(/^\+380(50|95|66|99|39|67|68|96|97|98|63|93|73)\d{7}$/, 'Not valid phone number'),
  sex: string().required('Required').matches(/F|M/),
});

const CreateClientForm = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const { createClient } = useCreateClient();
  const { load } = useClients();
  const { enqueueSnackbar } = useSnackbar();
  const [clientsLoading, setClientsLoading] = useState(false);

  const onStart = useCallback(() => {
    setClientsLoading(true);
  }, [setClientsLoading]);

  const onSuccess = useCallback(
    (newClient) => {
      load({ offset: 0, limit: 30 });
      setClientsLoading(false);
      onSubmit();
    },
    [onSubmit, setClientsLoading, load]
  );

  const onError = useCallback(
    (error) => {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      console.log(error);
      setClientsLoading(false);
    },
    [enqueueSnackbar, setClientsLoading]
  );

  const handleSubmit = useCallback(
    (values) => {
      createClient({
        params: skipEmptyValues(values),
        onStart,
        onSuccess,
        onError,
      });
    },
    [createClient, onError, onStart, onSuccess]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <RowWrapper>
              <FirstNameField disabled={clientsLoading} />
            </RowWrapper>
            <RowWrapper>
              <LastNameField disabled={clientsLoading} />
            </RowWrapper>
            <RowWrapper>
              <InstagramField disabled={clientsLoading} />
            </RowWrapper>
            <RowWrapper>
              <PhoneNumberField disabled={clientsLoading} />
            </RowWrapper>
            <RowWrapper>
              <SexField disabled={clientsLoading} />
            </RowWrapper>
            <RowWrapper>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || clientsLoading} fullWidth>
                  {clientsLoading && <CircularProgressWrapper size={16} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </RowWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateClientForm.propTypes = {};

export default CreateClientForm;
