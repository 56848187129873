import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
  state: STATE_INIT,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadUsersStarted(state) {
      return {
        ...state,
        state: STATE_LOADING,
      };
    },
    loadUsersSuccess(state, { payload: items }) {
      return {
        ...state,
        items,
        state: STATE_SUCCESS,
      };
    },
    loadUsersFailed(state) {
      return {
        ...state,
        state: STATE_FAILED,
      };
    },
    addUser(state, { payload: user }) {
      return {
        ...state,
        items: [...state.items, user],
      };
    },
    resetUsers() {
      return { ...initialState };
    },
  },
});

export const { loadUsersStarted, loadUsersSuccess, loadUsersFailed, addUser, resetUsers } = usersSlice.actions;

export default usersSlice.reducer;
