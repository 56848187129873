import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { number, object, string, ref, mixed, array } from 'yup';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { CircularProgressWrapper, RowWrapper } from './atoms';
import { FormInput, FormSelect } from '../../../../form';
import { RoleContext, UserContext } from '../../../providers';
import { useCreateUser } from '../../../hooks';
import { skipEmptyValues } from '../../../../common';
import { useAppData } from '../../../../storage/providers';

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  password: '',
  rePassword: '',
  roleId: '',
  type: '',
  departmentIds: [],
};

const validationSchema = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .matches(/^\+380(50|95|66|99|39|67|68|96|97|98|63|93|73)\d{7}$/, 'Not valid phone number'),
  password: string().required('Required'),
  rePassword: mixed()
    .oneOf([ref('password'), null], 'Should match password')
    .required('Required'),
  roleId: number().required('Required'),
  type: string(),
  departmentIds: array().of(number()),
});

const CreateUserForm = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const { createUser } = useCreateUser();
  const { loading, setLoading, setUsers, users } = useContext(UserContext);
  const { data } = useAppData();
  const { roles = [] } = useContext(RoleContext);
  const { enqueueSnackbar } = useSnackbar();
  const { departments = [] } = data;

  const onStart = () => {
    setLoading(true);
  };

  const onSuccess = (newUser) => {
    setUsers([...users, newUser]);
    setLoading(false);
    onSubmit();
  };

  const onError = (error) => {
    if (error.message) {
      if (typeof error.message === 'string') {
        enqueueSnackbar(error.message, { variant: 'error' });
      }

      if (typeof error.message === 'object') {
        Object.keys(error.message[0].constraints).forEach((key) =>
          enqueueSnackbar(error.message[0].constraints[key], { variant: 'error' })
        );
      }
    }
    console.log(error);
    setLoading(false);
  };

  const handleSubmit = (values) => {
    createUser({
      params: skipEmptyValues(values),
      onStart,
      onSuccess,
      onError,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <RowWrapper>
              <Field
                id="firstName"
                name="firstName"
                label={t`First name`}
                placeholder={t`First name`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="lastName"
                name="lastName"
                label={t`Last name`}
                placeholder={t`Last name`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="phoneNumber"
                name="phoneNumber"
                label={t`Phone number`}
                placeholder={t`Phone number`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="password"
                name="password"
                label={t`Password`}
                placeholder={t`Password`}
                type="password"
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="rePassword"
                name="rePassword"
                label={t`Confirm password`}
                placeholder={t`Confirm password`}
                type="password"
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="departmentIds"
                name="departmentIds"
                label={t`Departments`}
                placeholder={t`Departments`}
                component={FormSelect}
                disabled={loading}
                multiple
              >
                {departments.map(({ id, name }) => (
                  <MenuItem key={`dep-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Field
                id="roleId"
                name="roleId"
                label={t`Role`}
                placeholder={t`Role`}
                component={FormSelect}
                disabled={loading}
              >
                {roles.map(({ id, name }) => (
                  <MenuItem key={`role-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Field
                id="type"
                name="type"
                label={t`Type`}
                placeholder={t`Type`}
                component={FormSelect}
                disabled={loading}
              >
                <MenuItem key={`type-master`} value="master">
                  {t`Master`}
                </MenuItem>
                <MenuItem key={`type-top-master`} value="top">
                  {t`Top master`}
                </MenuItem>
                <MenuItem key={`type-art-director`} value="artDirector">
                  {t`Art director`}
                </MenuItem>
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgressWrapper size={16} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </RowWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateUserForm.propTypes = {};

export default CreateUserForm;
