import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const serviceUrl = `${REACT_APP_API_URL}/service`;

const useServices = (serviceGroupId) => {
  const { get } = useRequest();
  const [services, setServices] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (services) => {
    setServices(services);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    get({
      url: serviceUrl,
      queryParams: { serviceGroupId },
    })
      .then(handleSuccess)
      .catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { services, setServices, loading, setLoading };
};

export default useServices;
