import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { array, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';

import PaymentsField from '../../../PaymentsField';
import { useStyle } from './styles';
import { FormInput } from '../../../../../../form';
import { LoadingButton, MoneyType } from '../../../../../../common';

const validationSchema = object().shape({
  note: string(),
  totalPrice: number(),
  payments: array().of(
    object().shape({
      amount: number().min(0),
      paymentType: string(),
    })
  ),
});

const CompleteForm = (props) => {
  const { price, loading, onSubmit } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        note: '',
        totalPrice: price,
        payments: [
          { amount: price, paymentType: MoneyType.CASH },
          { amount: 0, paymentType: MoneyType.CARD },
          { amount: 0, paymentType: MoneyType.ATM },
        ],
      }}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, values, setFieldError, errors, setFieldValue, isSubmitting }) => {
        const { totalPrice, note, payments } = values;

        const paymentsTotal = payments.reduce((acc, _) => acc + _.amount, 0);

        const isPaymentsEquals = totalPrice === paymentsTotal;
        const isPaymentsLessThenTotal = totalPrice < paymentsTotal;
        const isNoteEmpty = !note.length;

        if (isPaymentsLessThenTotal && !errors.payments) {
          setFieldError('payments', t`Total cannot be lass then payments`);
        } else if (!isPaymentsEquals && isNoteEmpty && !errors.note && !errors.payments) {
          setFieldError('note', t`Services prices not matches total price, specify the reason`);
        }

        const handleOnChange = (values) => {
          setFieldValue('payments', values);
        };

        return (
          <Form>
            <Box className={classes.row}>
              <Field
                id="note"
                name="note"
                component={FormInput}
                multiline
                rowsMax={4}
                label={t`Note`}
                placeholder={t`Note`}
              />
            </Box>
            <Box className={classes.row}>
              <PaymentsField onChange={handleOnChange} initialTotal={price} />
              {!!errors.payments && <FormHelperText error>{errors.payments}</FormHelperText>}
            </Box>
            <Box className={classes.row}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={isSubmitting || !isValid || loading}
                fullWidth
                loading={isSubmitting || loading}
              >
                {t`Submit`}
              </LoadingButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CompleteForm.propTypes = {
  price: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default CompleteForm;
