import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import idx from 'idx';

import { DatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

import useNotReturnedClients from '../../../hooks/useNotReturnedClients';
import DepartmentsGrid from '../../widgets/DepartmentsGrid';
import UsersGrid from '../../widgets/UsersGrid';
import DepartmentsGraph from '../../widgets/DepartmentsGraph';
import UserGraph from '../../widgets/UserGraph';
import { LoadingButton, Page } from '../../../../common';
import { useAppData } from '../../../../storage/providers';

const today = moment(new Date());

const NotReturnedClients = () => {
  const { data } = useAppData();
  const { departments } = data;
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [date, setDate] = useState(today);
  const [showGraph, setShowGraph] = useState('department');
  const { t } = useTranslation();
  const { totals, loadNotReturnedClients } = useNotReturnedClients();

  const users = useMemo(() => {
    return selectedDepartments
      .map((_) => _.users)
      .flat()
      .filter((_) => _.status === 1)
      .reduce((acc, user) => (acc.some((_) => _.id === user.id) ? acc : [...acc, user]), []);
  }, [selectedDepartments]);

  const handleOnDateFromChange = useCallback(
    (date) => {
      setDate(date);
    },
    [setDate]
  );

  const handleOnDepartmentClick = useCallback(
    (selectedDepartment) => {
      if (selectedDepartments.some((_) => _.id === selectedDepartment.id)) {
        setSelectedDepartments(selectedDepartments.filter((_) => _.id !== selectedDepartment.id));
      } else {
        setSelectedDepartments([...selectedDepartments, selectedDepartment]);
      }
    },
    [selectedDepartments, setSelectedDepartments]
  );

  const handleOnUserClick = useCallback(
    (user) => {
      setSelectedUser(selectedUser && selectedUser.id === user.id ? null : user);
    },
    [selectedUser, setSelectedUser]
  );

  const handleOnConfirm = useCallback(() => {
    const userId = idx(selectedUser, (_) => _.id);
    loadNotReturnedClients({
      date: date.format('YYYY-MM-DD'),
      departmentIds: selectedDepartments.map((_) => _.id).join(','),
      userId,
    });
    setShowGraph(userId ? 'user' : 'department');
  }, [date, selectedDepartments, selectedUser, loadNotReturnedClients]);

  return (
    <Page>
      <Grid container justify="center" style={{ marginBottom: 20 }}>
        <DatePicker
          label={t`Date`}
          value={date}
          onChange={handleOnDateFromChange}
          format="DD MMM, YYYY"
          animateYearScrolling
          autoOk
          style={{ marginRight: 10 }}
        />
        <LoadingButton fullWidth={false} onClick={handleOnConfirm} disabled={!selectedDepartments.length} />
      </Grid>
      <Grid container>
        <DepartmentsGrid
          departments={departments}
          selectedDepartments={selectedDepartments}
          onDepartmentClick={handleOnDepartmentClick}
        />
        <UsersGrid onUserClick={handleOnUserClick} selectedUser={selectedUser} users={users} />
        {showGraph === 'department' && <DepartmentsGraph totals={totals} />}
        {showGraph === 'user' && <UserGraph totals={totals} />}
      </Grid>
    </Page>
  );
};

export default NotReturnedClients;
