import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';

import Calculations from '../Calculations';
import CreateServiceForm from '../CreateServiceForm';
import EditServiceForm from '../EditServiceForm';
import { ModalButton, Skeleton, Text } from '../../../../common';
import { useStyles } from './styles';
import { useArchiveService } from '../../../hooks';
import { Access, Roles } from '../../../../auth';

const Services = (props) => {
  const { services, serviceGroupId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { editService } = useArchiveService();
  const [, setLoading] = useState(false);

  const onStart = () => {
    setLoading(true);
  };

  const onError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const onSuccess = (id) => {
    setLoading(false);
  };

  const handleArchiveService =
    ({ id }) =>
    () => {
      editService({
        id,
        onStart,
        onError,
        onSuccess,
      });
    };

  if (!services) return <Skeleton rows={8} />;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.gridItem}>
        <Table className={classes.table} aria-label="services table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '30%' }}>
                <Text h5 bold upper>{t`Name`}</Text>
              </TableCell>
              <TableCell>
                <Text bold upper>{t`Price`}</Text>
              </TableCell>
              <TableCell>
                <Text bold upper>{t`Price TOP`}</Text>
              </TableCell>
              <TableCell>
                <Text bold upper>{t`Price ART`}</Text>
              </TableCell>
              <TableCell>
                <Text bold upper>{t`Approximate time (min)`}</Text>
              </TableCell>
              <Access roles={[Roles.superAdmin]}>
                <TableCell>
                  <Text bold upper>{t`Calculation`}</Text>
                </TableCell>
              </Access>
              <TableCell>
                <Text bold upper>{t`Actions`}</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell>
                  <Text>{service.name}</Text>
                </TableCell>
                <TableCell>
                  <Text>{service.price}</Text>
                </TableCell>
                <TableCell>
                  <Text>{service.priceTop}</Text>
                </TableCell>
                <TableCell>
                  <Text>{service.priceArt}</Text>
                </TableCell>
                <TableCell>
                  <Text>{service.approximateTimeMin || ''}</Text>
                </TableCell>
                <Access roles={[Roles.superAdmin]}>
                  <TableCell>
                    <ModalButton
                      title={t`Calculation`}
                      buttonComponent={
                        <IconButton>
                          <EmojiSymbolsIcon fontSize="small" />
                        </IconButton>
                      }
                    >
                      <Calculations service={service} />
                    </ModalButton>
                  </TableCell>
                </Access>
                <TableCell>
                  <ModalButton
                    buttonComponent={
                      <Tooltip title={t`Edit`} placement="top">
                        <CreateIcon className={classes.editIcon} />
                      </Tooltip>
                    }
                    title={t`Edit service`}
                    contentProps={{ onSubmit: () => {} }}
                  >
                    <EditServiceForm serviceId={service.id} />
                  </ModalButton>
                  <Tooltip title={t`Delete`} placement="top">
                    <DeleteOutlineIcon className={classes.removeIcon} onClick={handleArchiveService(service)} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <ModalButton
          buttonComponent={
            <Button color="primary" className={classes.addButton}>
              {t`Create service`}
            </Button>
          }
          title={t`Create service`}
          contentProps={{
            onSubmit: () => {},
          }}
        >
          <CreateServiceForm serviceGroupId={serviceGroupId} />
        </ModalButton>
      </Grid>
    </Grid>
  );
};

Services.propTypes = {
  services: PropTypes.array.isRequired,
  serviceGroupId: PropTypes.number.isRequired,
};

export default Services;
