import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const workingPlacesUrl = `${REACT_APP_API_URL}/working-place`;

const useWorkingPlaces = () => {
  const { get } = useRequest();
  const [workingPlaces, setWorkingPlaces] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (workingPlaces) => {
    setWorkingPlaces(workingPlaces);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    get({ url: workingPlacesUrl }).then(handleSuccess).catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { workingPlaces, loading, setWorkingPlaces, setLoading };
};

export default useWorkingPlaces;
