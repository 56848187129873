import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import { MoneyIcon, MoneyType } from '../../../../common';
import { useStyle } from './styles';

const moneyTypes = [MoneyType.CASH, MoneyType.CARD, MoneyType.ATM];

let timeout;

const PaymentsField = (props) => {
  const { onChange, initialTotal, initialPayments } = props;
  const { t } = useTranslation();
  const classes = useStyle();
  const [payments, setPayments] = useState(initialPayments || [{ amount: initialTotal, paymentType: MoneyType.CASH }]);

  useEffect(() => {
    if (onChange) {
      timeout && clearTimeout(timeout);
      timeout = setTimeout(() => {
        const totalPayments = payments.reduce((acc, _) => acc + +_.amount, 0);
        onChange(
          payments.map((_) => ({ ..._, amount: +_.amount })),
          totalPayments === initialTotal
        );
      }, 200);
    }
    // eslint-disable-next-line
  }, [payments]);

  const handleOnPaymentTypeChange = useCallback(
    (idx) =>
      ({ target }) => {
        const paymentType = target.value;

        if (payments.find((_) => _.paymentType === paymentType)) return;

        setPayments(
          payments.map((_, i) => {
            return idx === i ? { ..._, paymentType } : _;
          })
        );
      },
    [payments]
  );

  const handleOnValueChange = useCallback(
    (paymentType) =>
      ({ target }) => {
        setPayments(
          payments.map((_) => {
            return _.paymentType === paymentType ? { ..._, amount: target.value } : _;
          })
        );
      },
    [payments]
  );

  const handleOnAddPayment = useCallback(() => {
    if (payments.length === moneyTypes.length) return;

    const paymentType = moneyTypes.filter((_) => !payments.map(({ paymentType }) => paymentType).includes(_))[0];

    setPayments([...payments, { amount: 0, paymentType }]);
  }, [payments]);

  const handleOnRemovePayment = useCallback(() => {
    const paymentToRemove = payments[payments.length - 1];
    setPayments(payments.filter((_) => _.paymentType !== paymentToRemove.paymentType));
  }, [payments]);

  const managePaymentTypes = useCallback(
    (idx) => {
      const isPlusVisible = payments.length < 3 && payments.length === idx + 1;
      const isMinusVisible = payments.length > 1 && idx > 0 && idx + 1 === payments.length;

      return (
        <InputAdornment position="end">
          {isPlusVisible && (
            <IconButton style={{ padding: 0 }} onClick={handleOnAddPayment}>
              <AddBoxIcon />
            </IconButton>
          )}
          {isMinusVisible && (
            <IconButton style={{ padding: 0 }} onClick={handleOnRemovePayment}>
              <IndeterminateCheckBoxIcon />
            </IconButton>
          )}
        </InputAdornment>
      );
      // eslint-disable-next-line
    },
    [payments, handleOnAddPayment, handleOnRemovePayment]
  );

  return payments.map(({ amount, paymentType }, idx) => (
    <Grid key={`payment=${idx}`} container className={classes.row}>
      <Select value={paymentType} onChange={handleOnPaymentTypeChange(idx)} className={classes.select}>
        <MenuItem value={MoneyType.CASH}>
          <MoneyIcon className={classes.moneyIcon} type={MoneyType.CASH} />
        </MenuItem>
        <MenuItem value={MoneyType.CARD}>
          <MoneyIcon className={classes.moneyIcon} type={MoneyType.CARD} />
        </MenuItem>
        <MenuItem value={MoneyType.ATM}>
          <MoneyIcon className={classes.moneyIcon} type={MoneyType.ATM} />
        </MenuItem>
      </Select>
      <Input
        key={`input-${paymentType}`}
        placeholder={t`Amount`}
        type="number"
        onChange={handleOnValueChange(paymentType)}
        value={amount}
        className={classes.text}
        endAdornment={managePaymentTypes(idx)}
      />
    </Grid>
  ));
};

PaymentsField.propTypes = {
  onChange: PropTypes.func,
  initialTotal: PropTypes.number,
  initialPayments: PropTypes.array,
};

PaymentsField.defaultValues = {
  initialTotal: 0,
};

export default PaymentsField;
