import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormInput } from '../../../../form';
import { useStyles } from './styles';

const initialValues = {
  name: '',
};

const validationSchema = object().shape({
  name: string().required('Required'),
});

const CreateWorkingPlaceForm = (props) => {
  const { onSubmit, loading } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <Box className={classes.row}>
              <Field
                id="name"
                name="name"
                label={t`Name`}
                placeholder={t`Name`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <div className={classes.row}>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgress size={16} className={classes.loadingIcon} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateWorkingPlaceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CreateWorkingPlaceForm;
