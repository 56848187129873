import styled, { css } from 'styled-components/macro';

export const QuantitySelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 71px;
  ${(_) =>
    _.vertical &&
    css`
      flex-direction: column;
    `}
`;

export const QuantitySelectorButton = styled.button`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid #202020;
  position: relative;
  background-color: ${(_) => (_.isBlack ? '#202020' : 'white')};
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    background-color: ${(_) => (_.isBlack ? 'white' : '#202020')};
    left: 50%;
    transform: translateX(-50%);
  }

  ${(_) =>
    _.plus &&
    css`
      &:after {
        content: '';
        position: absolute;
        height: 9px;
        width: 1px;
        background-color: ${(_) => (_.isBlack ? 'white' : '#202020')};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

export const QuantityNumber = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #202020;
`;
