import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MaterialModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

import Text from '../Text';
import { useStyles } from './styles';

const Modal = (props) => {
  const { title, isOpen, onClose, onClick, onCloseIconClick, children, fullscreen, headerColor } = props;
  const classes = useStyles();

  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <MaterialModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={isOpen}
      onClose={onClose}
      onClick={handleOnClick}
    >
      <Fade in={isOpen}>
        <div className={clsx(classes.paper, fullscreen && classes.fullscreen)}>
          <div className={classes.header} style={headerColor && { backgroundColor: headerColor }}>
            <div className={classes.headerTitle}>
              <Text h3 upper bold>
                {title}
              </Text>
            </div>
            <CloseIcon className={classes.closeIcon} onClick={onCloseIconClick} />
          </div>
          <div className={classes.content}>{children}</div>
        </div>
      </Fade>
    </MaterialModal>
  );
};

Modal.propTypes = {
  title: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onCloseIconClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
  headerColor: PropTypes.string,
};

export default Modal;
