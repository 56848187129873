import React from 'react';
import PropTypes from 'prop-types';

import { DepartmentContext } from '.';
import { useDepartments } from '../hooks';

const DepartmentsProvider = (props) => {
  const { children } = props;
  const { departments, loading, setDepartments, setLoading } = useDepartments();

  return (
    <DepartmentContext.Provider value={{ departments, loading, setDepartments, setLoading }}>
      {children}
    </DepartmentContext.Provider>
  );
};

DepartmentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DepartmentsProvider;
