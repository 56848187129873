import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';

import { useStyles } from './styles';

const Page = (props) => {
  const { children, className = {}, ...restProps } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.page, className)} {...restProps}>
      {children}
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
