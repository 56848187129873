import React from 'react';
import { Field, Form, Formik } from 'formik';
import { string, object } from 'yup';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import jwt from '../../../../jwt';
import { useRequest } from '../../../../network';
import { useUserStore } from '../../../../user';
import { FormInput } from '../../../../form/adapters';
import { RowWrapper, TitleWrapper, CircularProgressWrapper } from './atoms';
import { Text } from '../../../../common';

const initialValues = {
  phoneNumber: '',
  password: '',
};

const validationSchema = object().shape({
  phoneNumber: string().required('Required'),
  password: string().required('Required'),
});

const SignInForm = () => {
  const { t } = useTranslation();
  const { patch } = useRequest();
  const { setUser, setUserLoading, userState } = useUserStore();
  const { userLoading } = userState;

  const handleOnSubmit = (values) => {
    const { REACT_APP_API_URL } = process.env;

    setUserLoading(true);

    patch({
      url: `${REACT_APP_API_URL}/auth/signIn`,
      body: values,
    })
      .then(({ token: accessToken, user }) => {
        jwt.store({ accessToken });
        setUser(user);
      })
      .catch((error) => {
        console.log(error);
        setUserLoading(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <TitleWrapper>
              <Text gutterBottom>{t`Sign In`}</Text>
            </TitleWrapper>
            <RowWrapper>
              <Field
                id="phoneNumber"
                name="phoneNumber"
                label={t`Phone number`}
                placeholder={t`Phone number`}
                component={FormInput}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="password"
                name="password"
                label={t`Password`}
                placeholder={t`Password`}
                type="password"
                component={FormInput}
              />
            </RowWrapper>
            <RowWrapper>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || userLoading} fullWidth>
                  {userLoading && <CircularProgressWrapper size={16} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </RowWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

SignInForm.propTypes = {};

export default SignInForm;
