import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { array, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FaceIcon from '@material-ui/icons/Face';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CommentIcon from '@material-ui/icons/Comment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';

import ActionButtons from './ActionButtons';
import UsersSelectField from './UsersSelectField';
import {
  Text,
  removeMillisFromTime,
  Editable,
  Bill,
  Skeleton,
  ModalButton,
  LoadingButton,
} from '../../../../../../common';
import { Access, Roles } from '../../../../../../auth';

import PaymentsField from '../../../PaymentsField';
import TimeFields from '../../../CreateOrderForm/TimeFields';
import { OrderStatus } from '../../../../../constants';
import { useStyle } from './styles';
import { useEditOrderPayments } from '../../../../../hooks';
import { FormInput } from '../../../../../../form/adapters';

const OrderInfo = (props) => {
  const {
    id: orderId,
    user,
    client,
    payments = [],
    notes,
    startTime,
    endTime,
    startDate,
    endDate,
    services,
    totalSalary,
    onComplete,
    onEdit,
    onDecline,
    onFail,
    onAdd,
    status,
    onOrderChange,
    loading,
  } = props;
  const classes = useStyle();
  const { editOrderPayments } = useEditOrderPayments();
  const { t } = useTranslation();
  const { items } = useSelector(({ ordersToClose }) => ordersToClose);

  const isChecked = useMemo(() => {
    return items.some((_) => _.id === orderId);
  }, [items, orderId]);

  const handleEditPayments = useCallback(
    (params) => {
      editOrderPayments({
        id: orderId,
        params,
        onStart: () => {},
        onError: () => {},
        onSuccess: () => {},
      });
    },
    [editOrderPayments, orderId]
  );

  if (loading) return <Skeleton />;

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText className={classes.listItemText}>
          <Access
            roles={[Roles.superAdmin, Roles.manager]}
            andCondition={status === OrderStatus.CREATED}
            elseComponent={
              <Text bold>
                {removeMillisFromTime(startTime)} - {removeMillisFromTime(endTime)}
              </Text>
            }
          >
            <Editable
              field={
                <Box style={{ display: 'flex' }}>
                  <TimeFields />
                </Box>
              }
              onSubmit={onOrderChange}
              initialValues={{
                startTime: startDate,
                endTime: endDate,
              }}
            >
              {removeMillisFromTime(startTime)} - {removeMillisFromTime(endTime)}
            </Editable>
          </Access>
        </ListItemText>
      </ListItem>
      <Divider variant="inset" component="li" />
      <Access roles={[Roles.superAdmin, Roles.manager]}>
        <ListItem>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            <Access
              roles={[Roles.superAdmin]}
              orCondition={status === OrderStatus.CREATED}
              elseComponent={
                <Text>
                  {user.firstName} {user.lastName}
                </Text>
              }
            >
              <Editable
                field={<UsersSelectField user={user} />}
                validationSchema={{
                  userId: number().required('Required'),
                }}
                initialValues={{ userId: user.id }}
                onSubmit={onOrderChange}
              >
                {user.firstName} {user.lastName}
              </Editable>
            </Access>
          </ListItemText>
        </ListItem>
        <Divider variant="inset" component="li" />
        {!!client.notes.length && (
          <ListItem>
            <ListItemIcon>
              <FaceIcon />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              <Box style={{ marginBottom: 10 }}>
                <Grid container>
                  {client.notes.map(({ id, note: clientNote }) => (
                    <Grid key={`c-${id}`} item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <AttachFileIcon style={{ marginRight: 10, fontSize: 10 }} />
                      <Text h5>{clientNote}</Text>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </ListItemText>
          </ListItem>
        )}
      </Access>
      <ListItem>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText className={classes.listItemText}>
          {notes.map((orderNote) => (
            <Box key={`note-${orderNote.id}`}>
              <Text h4>{orderNote.note}</Text>
            </Box>
          ))}
          <Access roles={[Roles.manager, Roles.superAdmin]}>
            <Editable
              field={<Field id="note" name="note" component={FormInput} />}
              onSubmit={onOrderChange}
              initialValues={{ note: '' }}
              validationSchema={{ note: string().required('') }}
              disableDashing
            >
              <IconButton color="primary">
                <AddIcon fontSize="small" />
              </IconButton>
            </Editable>
          </Access>
        </ListItemText>
      </ListItem>
      <Access roles={[Roles.manager, Roles.superAdmin]}>
        <ListItem>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText>
            <Bill services={services} payments={payments} totalSalary={totalSalary} showTotal />
            <Access roles={[Roles.superAdmin]} andCondition={status === OrderStatus.COMPLETED || !!payments.length}>
              <Box className={classes.totalWrapper}>
                <ModalButton
                  buttonComponent={<Box>{t`Change total`}</Box>}
                  title={t`Change total`}
                  contentProps={{
                    onSubmit: handleEditPayments,
                  }}
                >
                  <Formik
                    initialValues={{
                      payments: payments.reduce((acc, { paymentType, amount }) => {
                        return [...acc, { amount: +amount, paymentType }];
                      }, []),
                    }}
                    validationSchema={object().shape({
                      payments: array().of(
                        object().shape({
                          amount: number().min(0),
                          paymentType: string(),
                        })
                      ),
                    })}
                  >
                    {(props) => {
                      const { setFieldValue, isSubmitting, isValid, dirty } = props;

                      const handleOnChange = (values) => {
                        setFieldValue('payments', values);
                      };

                      return (
                        <Form>
                          <Box className={classes.row}>
                            <PaymentsField
                              initialPayments={payments.reduce((acc, { paymentType, amount }) => {
                                return [...acc, { amount: +amount, paymentType }];
                              }, [])}
                              onChange={handleOnChange}
                            />
                          </Box>
                          <Box className={classes.row}>
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              disabled={!dirty || isSubmitting || !isValid || loading}
                              fullWidth
                              loading={isSubmitting || loading}
                            >
                              {t`Submit`}
                            </LoadingButton>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </ModalButton>
              </Box>
            </Access>
          </ListItemText>
        </ListItem>
      </Access>
      <Access roles={[Roles.manager, Roles.superAdmin]} andCondition={status === OrderStatus.CREATED}>
        <ActionButtons
          onComplete={onComplete}
          onEdit={onEdit}
          onDecline={onDecline}
          onFail={onFail}
          onAdd={onAdd}
          checked={isChecked}
        />
      </Access>
    </List>
  );
};

OrderInfo.propTypes = {
  user: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      approximateTimeMin: PropTypes.number,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  onComplete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  payments: PropTypes.array,
  notes: PropTypes.array,
  isClientNotified: PropTypes.bool,
  loading: PropTypes.bool,
};

export default OrderInfo;
