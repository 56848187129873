import { useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const totalByMasterUrl = `${REACT_APP_API_URL}/statistic/total-by-master`;

const useTotalByMaster = () => {
  const { get } = useRequest();
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (totals) => {
    setTotals(totals);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const loadTotal = ({ dateFrom, dateTo }) => {
    setLoading(true);
    get({
      url: totalByMasterUrl,
      queryParams: {
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
      },
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  return { totals, loading, loadTotal };
};

export default useTotalByMaster;
