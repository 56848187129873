import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import { MoneyIcon, Text } from '..';
import { useAppData } from '../../../../storage/providers';
import { useStyle } from './styles';

const DynamicView = (props) => {
  const { services, showTotal, payments, onIncrease, onDecrease, onRemove } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const { data } = useAppData();
  const { departments } = data;

  return (
    <Box className={classes.tableWrapper}>
      {services.map((service) => {
        const { id, name, price, serviceGroupId, amount } = service;

        const department = departments.find((d) => d.serviceGroups.find((sg) => +sg.id === +serviceGroupId)) || {};

        return (
          <Box key={`service-${id}`} alignItems="center" className={classes.gridRow}>
            <Box
              className={classes.cell}
              style={{
                borderLeft: `5px solid ${department.color}`,
                justifyContent: 'space-between',
              }}
            >
              <Text pushRight>{name}</Text>
              <Box className={classes.amountWrapper}>
                <RemoveCircleOutlineIcon
                  fontSize="small"
                  className={clsx(classes.removeAmountIcon, amount === 1 && classes.disabledIcon)}
                  onClick={() => onDecrease(service)}
                />
                <AddCircleOutlineIcon
                  fontSize="small"
                  className={classes.addAmountIcon}
                  onClick={() => onIncrease(service)}
                />
              </Box>
            </Box>
            <Box className={classes.cell}>
              <Text h5>x{amount}</Text>
            </Box>
            <Box className={classes.cell}>
              <Text bold pushRight>
                {+price * amount}
              </Text>
              <CloseIcon fontSize="small" className={classes.deleteButton} onClick={() => onRemove(service)} />
            </Box>
          </Box>
        );
      })}
      {showTotal && !payments.length && (
        <Text ghost h5>
          {t`No payments`}
        </Text>
      )}
      {!!(showTotal && payments.length) &&
        payments.map(({ paymentType, amount }, idx) => (
          <Box className={classes.gridTotalRow} key={`pay-${idx}`}>
            <Box className={classes.totalCell} />
            <Box className={classes.totalCell}>
              <MoneyIcon type={paymentType} className={classes.paymentType} />
            </Box>
            <Box className={classes.totalCell}>
              <Text bold pushRight>
                {+amount}
              </Text>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

DynamicView.propTypes = {};

export default DynamicView;
