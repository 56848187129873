import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      padding: theme.spacing(2),
    },
    datesWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    loadButton: {
      marginLeft: theme.spacing(2),
    },
    spinner: {
      marginRight: theme.spacing(1),
    },
    timeLineWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});
