import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './styles';
import { WorkingPlaceContext } from '../../../providers';
import { ModalButton, Skeleton, Text } from '../../../../common';
import { useCreateWorkingPlace } from '../../../hooks';
import CreateWorkingPlaceForm from '../CreateWorkingPlaceForm';

const WorkingPlaces = (props) => {
  const { selectedDepartmentId } = props;
  const { workingPlaces, setWorkingPlaces, setLoading, loading } = useContext(WorkingPlaceContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { createWorkingPlace } = useCreateWorkingPlace();

  const onSuccess = (newWorkingPlace) => {
    setWorkingPlaces([...workingPlaces, newWorkingPlace]);
    setLoading(false);
  };

  const onError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const onStart = () => {
    setLoading(true);
  };
  const handleCreateWorkingPlace = ({ name }) => {
    const params = { departmentId: selectedDepartmentId, name };
    createWorkingPlace({ params, onStart, onSuccess, onError });
  };

  const workingPlacesByDepartment = useMemo(() => {
    if (workingPlaces && selectedDepartmentId) {
      return workingPlaces.filter((_) => _.department.id === selectedDepartmentId);
    }
    return null;
  }, [selectedDepartmentId, workingPlaces]);

  const content = useMemo(() => {
    if (!selectedDepartmentId) return <Text h4 ghost>{t`Select department`}</Text>;

    if (loading) return <Skeleton rows={10} />;

    return (
      <Grid container>
        {workingPlacesByDepartment &&
          workingPlacesByDepartment.map(({ id, name }) => (
            <Grid item xs={12} key={`wp-${id}`} className={classes.row}>
              <Text h4 upper>
                {name}
              </Text>
            </Grid>
          ))}
        <Grid item xs={12} className={classes.buttonRow}>
          <ModalButton
            buttonComponent={<Button color="primary">{t`Create working place`}</Button>}
            title={t`Create working place`}
          >
            <CreateWorkingPlaceForm onSubmit={handleCreateWorkingPlace} loading={loading} />
          </ModalButton>
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line
  }, [selectedDepartmentId, loading]);

  return <Box className={classes.container}>{content}</Box>;
};

WorkingPlaces.propTypes = {
  selectedDepartmentId: PropTypes.number,
};

export default WorkingPlaces;
