import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';

import SellCard from './SellCard';
import { Divider, LoadingButton, RangePicker, Text } from '../../../common';
import { useSells } from '../../hooks';
import { CalculationContainer, RangePickerContainer, SellsContainer, SellsItemsContainer } from './atoms';

const SoldProductsByRange = () => {
  const { t } = useTranslation();
  const [{ from, to }, setRange] = useState({});
  const { load, items, isLoading } = useSells();
  const { items: users } = useSelector(({ users }) => users);

  const summaryItems = useMemo(() => {
    if (isEmpty(items)) return null;

    const productItems = items.map(({ products, sellerId }) => products.map((_) => ({ ..._, sellerId }))).flat();

    const amazeCalculation = productItems
      .filter((_) => !_.buyerId)
      .reduce(
        (acc, _) =>
          acc + (+_.price - +_.cost) * _.quantity,
        0
      );

    const suppliersCalculation = productItems.reduce((acc, _) => {
      const cost = acc[_.supplier.name] || 0;
      return { ...acc, [_.supplier.name]: cost + (+_.cost * _.quantity) };
    }, {});

    const sellersCalculation = productItems.reduce((acc, _) => {
      const cost = acc[_.sellerId] || 0;
      return { ...acc, [_.sellerId]: cost + (+_.price - +_.cost) * _.quantity };
    }, {});

    return [
      { name: 'AMAZE', value: amazeCalculation * 0.4 },
      { name: 'KSU', value: amazeCalculation * 0.4 },
      ...keys(sellersCalculation).map((sellerId) => {
        const { firstName, lastName } = users.find((_) => `${_.id}` === `${sellerId}`);
        return {
          name: `${firstName} ${lastName}`,
          value: sellersCalculation[sellerId] * 0.2,
        };
      }),
      ...keys(suppliersCalculation).map((supplierName) => ({
        name: supplierName,
        value: suppliersCalculation[supplierName],
      })),
    ];
  }, [items, users]);

  const handleOnRangeSelected = useCallback((from, to) => {
    setRange({ from, to });
  }, []);

  const handleOnSubmit = useCallback(() => {
    load({
      dateFrom: from.format('YYYY-MM-DD'),
      dateTo: to.format('YYYY-MM-DD'),
    });
  }, [from, to, load]);

  return (
    <SellsContainer>
      <RangePickerContainer>
        <RangePicker onRangeSelected={handleOnRangeSelected} />
        <Divider color="transparent" top={5} bottom={5} />
        <LoadingButton
          color="primary"
          style={{ width: 'auto' }}
          disabled={!from || !to || isLoading}
          onClick={handleOnSubmit}
          loading={isLoading}
        >
          {t`Get sells`}
        </LoadingButton>
      </RangePickerContainer>

      <CalculationContainer>
        {summaryItems &&
          summaryItems.map(({ name, value }) => (
            <div key={name} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text h4 pushRight>
                {name}
              </Text>
              <Text h4>{value} ₴</Text>
            </div>
          ))}
      </CalculationContainer>

      <SellsItemsContainer>
        {items.map(({ id, products, paymentTypes, supplier, sellerId, buyerId, createdAt }) => (
          <SellCard
            key={`sell-${id}`}
            sellId={id}
            products={products}
            paymentTypes={paymentTypes}
            supplier={supplier}
            sellerId={sellerId}
            buyerId={buyerId}
            createdAt={createdAt}
          />
        ))}
      </SellsItemsContainer>
    </SellsContainer>
  );
};

SoldProductsByRange.propTypes = {};

export default SoldProductsByRange;
