import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';

import { Text } from '../../../../common';
import { useStyle } from './styles';

const UsersGrid = (props) => {
  const { users, onUserClick, selectedUser } = props;
  const classes = useStyle();

  return useMemo(() => {
    return (
      <Box style={{ marginRight: 10 }}>
        {users.map((user) => {
          const { id, firstName, lastName } = user;

          return (
            <Box key={`dep-${id}`} className={classes.selectableItem} onClick={() => onUserClick(user)}>
              <Grid container alignItems="center">
                <Text pushRight>
                  {firstName} {lastName}
                </Text>
                {idx(selectedUser, (_) => _.id) === id && <DoneIcon color="primary" fontSize="small" />}
              </Grid>
            </Box>
          );
        })}
      </Box>
    );
  }, [onUserClick, users, selectedUser, classes.selectableItem]);
};

UsersGrid.propTypes = {
  users: PropTypes.array,
  onUserClick: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
};

export default UsersGrid;
