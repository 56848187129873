import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  tablePaper: {
    width: 650,
    marginBottom: theme.spacing(3),
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  expandedButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  addButton: {
    width: 650,
  },
}));
