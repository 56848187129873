import React from 'react';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';

import AppointmentTooltipContent from './AppointmentTooltipContent';

const AppointmentTooltipLayout = (props) => {
  return (
    <AppointmentTooltip.Layout {...props} headerComponent={() => null} contentComponent={AppointmentTooltipContent} />
  );
};

AppointmentTooltipLayout.propTypes = {};

export default AppointmentTooltipLayout;
