import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  clientContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clientInfo: {
    flexGrow: 1,
  },
  submitButtonContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
