import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  globalLoaderWrapper: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
}));
