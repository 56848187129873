import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      overflow: 'auto',
    },
    datesWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    loadButton: {
      marginLeft: theme.spacing(2),
    },
    spinner: {
      marginRight: theme.spacing(1),
    },
    cardsWrapper: {
      display: 'flex',
      flexFlow: 'inherit',
      justifyContent: 'center',
    },
    ordersWrapper: {
      display: 'flex',
      flexFlow: 'inherit',
      justifyContent: 'center',
      padding: theme.spacing(1),
    },
    cardWrapper: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
      cursor: 'pointer',
    },
    card: {
      display: 'inline-block',
      marginBottom: theme.spacing(2),
      width: '100%',
      transition: theme.transitions.create(['box-shadow'], { duration: theme.transitions.duration.complex }),
      boxShadow: 'none',
    },
    cardActive: {
      transition: theme.transitions.create(['box-shadow'], { duration: theme.transitions.duration.complex }),
      boxShadow: `
        0px 2px 1px -1px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14),
        0px 1px 3px 0px rgba(0,0,0,0.12)
      `,
    },
    cardHeader: {
      backgroundColor: '#cccccc',
      transition: theme.transitions.create(['background-color'], { duration: theme.transitions.duration.complex }),
    },
  };
});
