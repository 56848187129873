import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useStyles } from './styles';
import ResourceCell from './ResourceCell';
import Cell from './Cell';
import { Text } from '../../../../common';

const Header = (props) => {
  const { data, onNextDates, onPrevDates, disablePrev } = props;
  const classes = useStyles();

  const dates = useMemo(() => {
    return data.map(({ date }) => {
      const [day, month] = date.format('DD.MM').split('.');
      return (
        <>
          <Text bold>{day}</Text>
          <Text ghost italic>
            .{month}
          </Text>
        </>
      );
    });
  }, [data]);

  return (
    <Box className={classes.headerContainer}>
      <Box>
        <ResourceCell
          name={
            <Box className={classes.datesSwitcher}>
              <ArrowBackIosIcon
                fontSize="small"
                className={clsx(classes.arrowLeftButton, disablePrev && classes.disabled)}
                onClick={onPrevDates}
              />
              <Text>
                {data[0].date.format('DD.MM')} - {data[data.length - 1].date.format('DD.MM')}
              </Text>
              <ArrowForwardIosIcon fontSize="small" className={classes.arrowRightButton} onClick={onNextDates} />
            </Box>
          }
        />
      </Box>
      <Box style={{ display: 'flex' }}>
        {dates.map((date, idx) => (
          <Cell key={`date-${idx}`} text={date} />
        ))}
      </Box>
    </Box>
  );
};

Header.propTypes = {
  data: PropTypes.array.isRequired,
  onNextDates: PropTypes.func.isRequired,
  onPrevDates: PropTypes.func.isRequired,
  disablePrev: PropTypes.bool,
};

export default Header;
