import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import { loadOrdersSuccess, loadOrdersFailed, loadOrdersStarted, resetOrders, STATE_LOADING } from '../reducers/orders';
import { useCallback } from 'react';

const { REACT_APP_API_URL } = process.env;

const ordersUrl = `${REACT_APP_API_URL}/order`;

const useOrders = () => {
  const { get } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ orders }) => orders);
  const isLoading = state === STATE_LOADING;

  const start = useCallback(() => {
    dispatch(loadOrdersStarted());
  }, [dispatch]);

  const set = useCallback(
    (orders) => {
      dispatch(loadOrdersSuccess(orders));
    },
    [dispatch]
  );

  const fail = useCallback(() => {
    dispatch(loadOrdersFailed());
  }, [dispatch]);

  const handleError = useCallback(
    (error) => {
      console.log(error);
      dispatch(loadOrdersFailed());
    },
    [dispatch]
  );

  const load = useCallback(
    ({ dateFrom, dateTo, userId, clientId }) => {
      start();
      get({
        url: ordersUrl,
        queryParams: {
          ...(dateFrom ? { dateFrom } : {}),
          ...(dateTo ? { dateTo } : {}),
          ...(userId ? { userId } : {}),
          ...(clientId ? { clientId } : {}),
        },
      })
        .then(set)
        .catch(handleError);
    },
    [get, handleError, set, start]
  );

  const reset = useCallback(() => {
    dispatch(resetOrders());
  }, [dispatch]);

  return {
    items,
    isLoading,
    start,
    load,
    set,
    fail,
    reset,
  };
};

export default useOrders;
