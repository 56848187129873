import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      overflow: 'auto',
      display: 'flex',
    },
    calendar: {
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    manager: {
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
      },
    },
    closeOrdersButton: {
      marginTop: theme.spacing(1),
    },
  };
});
