import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const serviceGroupUrl = `${REACT_APP_API_URL}/service-group`;

const useServiceGroup = (id, skipLanguage) => {
  const { get } = useRequest();
  const [serviceGroup, setServiceGroup] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (serviceGroup) => {
    setServiceGroup(serviceGroup);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    get({ url: `${serviceGroupUrl}/${id}`, skipLanguage })
      .then(handleSuccess)
      .catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { serviceGroup, loading, setServiceGroup, setLoading };
};

export default useServiceGroup;
