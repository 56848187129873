import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

const Content = (props) => {
  const { children } = props;
  const { isOpen } = useSelector(({ sideBar }) => sideBar);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const isShifted = !isSmall && isOpen;

  return (
    <Box
      style={{
        width: '100vw',
        marginTop: 64,
        marginLeft: isShifted ? 240 : 0,
        transition: 'margin-left .3s ease-in-out',
      }}
    >
      {children}
    </Box>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
