import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(() => {
  return {
    tabContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none',
    },
  };
});
