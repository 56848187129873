import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import ServicesSelector from './ServicesSelector';
import { ModalButton, priceMasterType, Bill } from '../../../../common';
import { useStyles } from './styles';

const ServicesField = (props) => {
  const { t } = useTranslation();
  const { field, form, user, edit } = props;
  const { values } = form;
  const classes = useStyles();
  const [orderServices, setOrderServices] = useState(values[field.name]);

  useEffect(() => {
    form.setFieldValue(field.name, orderServices);
    // eslint-disable-next-line
  }, [orderServices]);

  const handleOnServiceSelect = (service) => {
    const priceField = priceMasterType[user.type];

    const isSelected = orderServices.find((_) => _.id === service.id);

    if (!isSelected) {
      setOrderServices([
        ...orderServices,
        {
          ...service,
          amount: 1,
          price: service[priceField],
        },
      ]);
    }
  };

  const handleOnServiceRemove = (service) => {
    setOrderServices(orderServices.filter((_) => _.id !== service.id));
  };

  const handleDecreaseAmount = ({ amount, ...service }) => {
    setOrderServices(orderServices.map((_) => (_.id === service.id ? { ...service, amount: amount - 1 } : _)));
  };

  const handleIncreaseAmount = ({ amount, ...service }) => {
    setOrderServices(orderServices.map((_) => (_.id === service.id ? { ...service, amount: amount + 1 } : _)));
  };

  return (
    <Box>
      <Box className={classes.row}>
        <Bill services={orderServices} showTotal />
      </Box>
      <Box className={classes.row}>
        <ModalButton
          title={'Add services'}
          buttonComponent={
            <Button size="small" fullWidth>
              {edit ? t`Edit services` : t`Add services`}
            </Button>
          }
          fullscreen
        >
          <ServicesSelector
            user={user}
            onSelect={handleOnServiceSelect}
            selectedServices={orderServices}
            onRemove={handleOnServiceRemove}
            onDecreaseAmount={handleDecreaseAmount}
            onIncreaseAmount={handleIncreaseAmount}
          />
        </ModalButton>
      </Box>
    </Box>
  );
};

ServicesField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default ServicesField;
