import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import idx from 'idx';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Card from '@material-ui/core/Card';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Box from '@material-ui/core/Box';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { RowDivider, Text } from '../../../../common';
import { useStyle } from './styles';
import { useSelector } from 'react-redux';

const CashboxOperations = (props) => {
  const { operations } = props;
  const { t } = useTranslation();
  const classes = useStyle();
  const { items: users } = useSelector(({ users }) => users);

  return (
    <Timeline align="left" className={classes.timeLine}>
      {operations.map((o) => {
        const { id, operation, balance, amount, createdAt, user, note, order, productSell } = o;
        const isIncome = operation === 'In';

        const card = (
          <Card className={classes.card}>
            <Box
              className={clsx(
                classes.row,
                isIncome ? classes.operationGeneralInfoRight : classes.operationGeneralInfoLeft
              )}
            >
              <Text h4 ghost style={{ marginRight: 10 }}>
                {moment(createdAt).format('DD.MM')}
              </Text>
              <Text h4 bold ghost>
                {moment(createdAt).format('HH:mm')}
              </Text>
              <Box>
                <Text h5 ghost>
                  {user.firstName} {user.lastName}
                </Text>
              </Box>
            </Box>
            <RowDivider text={t`Balance`} />
            <Box className={classes.row}>
              <Text h3 bold>
                {+balance}{' '}
              </Text>
              <Text h3 ghost bold>
                UAH{' '}
              </Text>
            </Box>
            <Box className={classes.row}>
              <Text h4 error={!isIncome} success={isIncome}>
                {isIncome ? '+' : '-'}
                {+amount}{' '}
              </Text>
              <Text h4 ghost>
                UAH
              </Text>
            </Box>
            <Box className={classes.row}>
              {note && (
                <Text h4 italic>
                  {note || `#${idx(order, (_) => _.id) || ''}`}
                </Text>
              )}
              {order && (
                <Text h4 italic>
                  {`#${idx(order, (_) => _.id) || ''}`}
                </Text>
              )}
              {productSell &&
                productSell.products.map(({ id: productId, name, quantity }) => {
                  const { buyerId } = productSell;
                  const buyer = buyerId && users.find((_) => `${_.id}` === `${buyerId}`);

                  return (
                    <div key={`product-${productId}`}>
                      <Text h4 italic pushRight ghost>
                        {productId}
                      </Text>
                      <Text h4 italic pushRight>
                        {name}
                      </Text>
                      <Text h4 italic pushRight>
                        x{quantity}
                      </Text>
                      {buyer && (
                        <Text h5 ghost>
                          {buyer.firstName} {buyer.lastName}
                        </Text>
                      )}
                    </div>
                  );
                })}
            </Box>
          </Card>
        );

        return (
          <TimelineItem key={`operation-${id}`}>
            <TimelineOppositeContent style={isMobileOnly ? { maxWidth: 0, padding: 0 } : {}}>
              {!isIncome && !isMobileOnly && card}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={isIncome ? 'primary' : 'secondary'} variant="outlined">
                {isIncome ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{(isIncome || isMobileOnly) && card}</TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

CashboxOperations.propTypes = {
  operations: PropTypes.array.isRequired,
};

export default CashboxOperations;
