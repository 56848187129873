import React from 'react';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import i18n from '../../i18n';

const menuItems = [
  {
    name: i18n.t`Cashbox`,
    icon: <MonetizationOnIcon />,
    subItems: [
      {
        name: i18n.t`History`,
        path: '/admin/cashbox/history',
        icon: <MenuBookIcon fontSize="small" />,
      },
    ],
    access: ['superadmin', 'manager'],
  },
];

export default menuItems;
