import blackDragon from '../../../../../assets/images/inarifood/rolls/black_dragon.webp';
import senBon from '../../../../../assets/images/inarifood/rolls/sen_bon.webp';
import gaara from '../../../../../assets/images/inarifood/rolls/gaara.webp';
import redDragon from '../../../../../assets/images/inarifood/rolls/red_dragon.webp';
import bilisi from '../../../../../assets/images/inarifood/rolls/bilisi.webp';
import inYan from '../../../../../assets/images/inarifood/rolls/in_yan.jpeg';
import goldenDragon from '../../../../../assets/images/inarifood/rolls/golden_dragon.webp';
import greenDragon from '../../../../../assets/images/inarifood/rolls/green_dragon.webp';
import philaMix from '../../../../../assets/images/inarifood/rolls/phila_mix.webp';
import rainbowDragon from '../../../../../assets/images/inarifood/rolls/rainbow_dragon.webp';
import fiji from '../../../../../assets/images/inarifood/rolls/fiji.webp';

export default [
  {
    title: 'Черный дракон',
    ingredients: ['угорь', 'авокадо', 'красная икра тобико', 'рис', 'нори', 'сыр сливочный', 'унаги соус'],
    image: blackDragon,
    price: 335,
  },
  {
    title: 'Сен Бон',
    ingredients: [
      'креветка тигровая',
      'угорь',
      'форель',
      'снежный краб',
      'сладкий перец',
      'японский майонез',
      'унаги соус',
      'кунжут рис',
      'нори',
    ],
    image: senBon,
    price: 225,
  },
  {
    title: 'Гаара',
    ingredients: [
      'угорь',
      'креветка тигровая',
      'авокадо',
      'лист салата',
      'икра тобико зелёная и чёрная',
      'японский майонез',
      'нори',
      'рис',
    ],
    image: gaara,
    price: 335,
  },
  {
    title: 'Красный дракон',
    ingredients: ['форель', 'угорь', 'огурец', 'оранжевая икра тобико', 'сыр сливочный', 'рис', 'нори'],
    image: redDragon,
    price: 310,
  },
  {
    title: 'Билиси',
    ingredients: ['угорь', 'форель', 'огурец', 'икра тобико чёрная', 'красная', 'сыр сливочный', 'нори', 'рис'],
    image: bilisi,
    price: 325,
  },
  {
    title: 'Инь Янь',
    ingredients: ['угорь', 'лосось', 'авокадо', 'огурец', 'икра тобико', 'рис', 'нори'],
    image: inYan,
    price: 184,
  },
  {
    title: 'Золотой дракон',
    ingredients: [
      'угорь сверху и внутри',
      'авокадо',
      'красная икра тобико',
      'рис',
      'нори',
      'сыр сливочный',
      'унаги соус',
    ],
    image: goldenDragon,
    price: 335,
  },
  {
    title: 'Зеленый дракон',
    ingredients: ['угорь', 'огурец', 'красная икра тобико', 'авокадо', 'сыр сливочный', 'унаги соус', 'рис', 'нори'],
    image: greenDragon,
    price: 271,
  },
  {
    title: 'Филадельфия микс',
    ingredients: ['форель', 'угорь', 'сыр сливочный', 'авокадо'],
    image: philaMix,
    price: 320,
  },
  {
    title: 'Радужный дракон',
    ingredients: [
      'форель',
      'угорь',
      'окунь',
      'тунец',
      'авокадо',
      'сыр сливочный',
      'огурец',
      'рис',
      'красная икра тобико',
      'нори',
    ],
    image: rainbowDragon,
    price: 278,
  },
  {
    title: 'Фиджи',
    ingredients: [
      'угорь',
      'креветка',
      'унаги соус',
      'авокадо',
      'сыр сливочный',
      'оранжевая икра тобико',
      'рис',
      'нори',
    ],
    image: fiji,
    price: 315,
  },
];
