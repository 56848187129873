import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { Text } from '../../../../../common';
import useClient from '../../../../../client/hooks/useClient';
import ClientWidget from '../../../../../order/components/widgets/ClientWidget';

const UserGraphItem = (props) => {
  const { clientId, state, seqNumber } = props;
  const { load, client, clientLoading } = useClient();

  useEffect(() => {
    load(clientId);
    // eslint-disable-next-line
  }, []);

  return useMemo(() => {
    if (clientLoading) return <div>Loading...</div>;
    if (!client) return null;

    return (
      <Box style={{ marginBottom: 10 }}>
        <Text pushRight>{seqNumber}</Text>
        <ClientWidget client={client} color={state === 'notReturned' ? '#c72929' : undefined} />
      </Box>
    );
  }, [client, clientLoading, state, seqNumber]);
};

UserGraphItem.propTypes = {
  clientId: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  seqNumber: PropTypes.number.isRequired,
};

export default UserGraphItem;
