import piraHot from '../../../../../assets/images/inarifood/rolls/pira_hot.webp';
import chisu from '../../../../../assets/images/inarifood/rolls/chisu.webp';
import unagiHot from '../../../../../assets/images/inarifood/rolls/unagi_hot.webp';
import tsunade from '../../../../../assets/images/inarifood/rolls/tsunade.webp';
import shrimpTempura from '../../../../../assets/images/inarifood/rolls/shrimp_tempura.webp';
import kayo from '../../../../../assets/images/inarifood/rolls/kayo.webp';
import sumo from '../../../../../assets/images/inarifood/rolls/sumo.webp';
import ebiHot from '../../../../../assets/images/inarifood/rolls/ebi_hot.webp';

export default [
  {
    title: 'Пира хот',
    ingredients: ['кляр - снежный краб', 'сыр чеддер', 'икра тобико красная', 'спайси соус', 'помидор', 'рис', 'нори'],
    image: piraHot,
    price: 169,
  },
  {
    title: 'Чизу',
    ingredients: ['креветка тигровая', 'снежный краб', 'сыр чедер', 'помидор', 'спайси соус', 'рис', 'нори'],
    image: chisu,
    price: 176,
  },
  {
    title: 'Унаги хот',
    ingredients: [
      'угорь',
      'авокадо',
      'сыр сливочный',
      'креветка тигровая',
      'икра тобика оранжевая',
      'унаги соус',
      'кунжут',
      'рис',
      'нори',
    ],
    image: unagiHot,
    price: 287,
  },
  {
    title: 'Цунадэ',
    ingredients: ['форель', 'тунец', 'креветка тигровая', 'авокадо', 'икра тобико зелёная', 'нори в панировке'],
    image: tsunade,
    price: 336,
  },
  {
    title: 'Креветка в темпура',
    ingredients: ['креветка тигровая в кляре 3 шт', 'соус чили'],
    image: shrimpTempura,
    price: 99,
  },
  {
    title: 'Каё',
    ingredients: [
      'кляр - форель',
      'тунец',
      'креветка тигровая',
      'сыр сливочный',
      'икра тобико',
      'лист салата',
      'рис',
      'нори',
    ],
    image: kayo,
    price: 225,
  },
  {
    title: 'Сумо',
    ingredients: ['форель', 'снежный краб', 'сыр чедер', 'помидор', 'красная икра тобико', 'рис', 'нори'],
    image: sumo,
    price: 235,
  },
  {
    title: ' Эби хот',
    ingredients: ['кляр - креветка', 'мидия', 'огурец', 'сыр сливочный', 'икра тобико', 'рис', 'нори'],
    image: ebiHot,
    price: 165,
  },
];
