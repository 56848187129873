import shrimpTempura from '../../../../../assets/images/inarifood/rolls/shrimp_tempura.webp';
import fiji from '../../../../../assets/images/inarifood/rolls/fiji.webp';
import natsu from '../../../../../assets/images/inarifood/rolls/natsu.webp';
import philaTiger from '../../../../../assets/images/inarifood/rolls/phila_tiger.webp';
import akita from '../../../../../assets/images/inarifood/rolls/akita.webp';
import rikkiRoll from '../../../../../assets/images/inarifood/rolls/rikki_roll.webp';
import chisu from '../../../../../assets/images/inarifood/rolls/chisu.webp';
import yamagato from '../../../../../assets/images/inarifood/rolls/yamagato.webp';
import tobirama from '../../../../../assets/images/inarifood/rolls/tobirama.webp';
import kurosaki from '../../../../../assets/images/inarifood/rolls/kurosaki.webp';
import kasama from '../../../../../assets/images/inarifood/rolls/kasama.webp';
import tsunade from '../../../../../assets/images/inarifood/rolls/tsunade.webp';
import senBon from '../../../../../assets/images/inarifood/rolls/sen_bon.webp';
import gaara from '../../../../../assets/images/inarifood/rolls/gaara.webp';
import chidori from '../../../../../assets/images/inarifood/rolls/chidori.webp';
import taripuru from '../../../../../assets/images/inarifood/rolls/taripuru.webp';
import takeshi from '../../../../../assets/images/inarifood/rolls/takeshi.webp';

export default [
  {
    title: 'Креветка в темпура',
    ingredients: ['креветка тигровая в кляре 3 шт', 'соус чили'],
    image: shrimpTempura,
    price: 99,
  },
  {
    title: 'Фиджи',
    ingredients: [
      'угорь',
      'креветка',
      'унаги соус',
      'авокадо',
      'сыр сливочный',
      'оранжевая икра тобико',
      'рис',
      'нори',
    ],
    image: fiji,
    price: 315,
  },
  {
    title: 'Нацу',
    ingredients: ['креветка темпура', 'икра Тобико', 'спайси соус', 'лист салата', 'рис', 'нори'],
    image: natsu,
    price: 158,
  },
  {
    title: 'Филадельфия тигровая',
    ingredients: ['тигровая креветка', 'авокадо', 'икра тобико', 'сыр сливочный', 'нори', 'рис'],
    image: philaTiger,
    price: 278,
  },
  {
    title: 'Акита',
    ingredients: ['форель', 'креветка тигровая', 'манго', 'сыр сливочный', 'красная икра тобико', 'рис', 'нори'],
    image: akita,
    price: 265,
  },
  {
    title: 'Рикки ролл',
    ingredients: [
      'форель',
      'креветка кляр',
      'авокадо',
      'японский майонез',
      'икра тобико чёрная',
      'красная',
      'рис',
      'нори',
    ],
    image: rikkiRoll,
    price: 309,
  },
  {
    title: 'Чизу',
    ingredients: ['креветка тигровая', 'снежный краб', 'сыр чедер', 'помидор', 'спайси соус', 'рис', 'нори'],
    image: chisu,
    price: 176,
  },
  {
    title: 'Ямагато',
    ingredients: ['форель', 'сыр сливочный', 'огурец', 'креветка темпура', 'унаги соус', 'кунжут', 'рис', 'нори'],
    image: yamagato,
    price: 309,
  },
  {
    title: 'Тобирама',
    ingredients: ['форель', 'тунец', 'креветка тигровая', 'авокадо', 'зелёная икра тобико', 'икра красная', 'нори'],
    image: tobirama,
    price: 278,
  },
  {
    title: 'Куросаки',
    ingredients: [
      'угорь',
      'креветка тигровая',
      'авокадо',
      'икра тобико оранжевая',
      'чёрная',
      'сыр сливочный',
      'снежный краб',
      'спайси соус',
      'нори',
    ],
    image: kurosaki,
    price: 355,
  },
  {
    title: 'Казама',
    ingredients: ['форель', 'креветка тигровая', 'тунец', 'сыр сливочный', 'красная икра тобико', 'авокадо', 'нори'],
    image: kasama,
    price: 330,
  },
  {
    title: 'Цунадэ',
    ingredients: ['форель', 'тунец', 'креветка тигровая', 'авокадо', 'икра тобико зелёная', 'нори в панировке'],
    image: tsunade,
    price: 336,
  },
  {
    title: 'Сен Бон',
    ingredients: [
      'креветка тигровая',
      'угорь',
      'форель',
      'снежный краб',
      'сладкий перец',
      'японский майонез',
      'унаги соус',
      'кунжут рис',
      'нори',
    ],
    image: senBon,
    price: 225,
  },
  {
    title: 'Гаара',
    ingredients: [
      'угорь',
      'креветка тигровая',
      'авокадо',
      'лист салата',
      'икра тобико зелёная и чёрная',
      'японский майонез',
      'нори',
      'рис',
    ],
    image: gaara,
    price: 335,
  },
  {
    title: 'Чидори',
    ingredients: [
      'форель',
      'креветка тигровая темпура',
      'сыр чедер',
      'снежный краб',
      'спайси',
      'авокадо',
      'икра тобико оранжевая',
      'рис',
      'нори',
    ],
    image: chidori,
    price: 215,
  },
  {
    title: 'Тарипуру',
    ingredients: ['форель', 'креветка', 'сыр сливочный', 'авокадо', 'сыр', 'икра тобико красная', 'рис', 'нори'],
    image: taripuru,
    price: 313,
  },
  {
    title: 'Такеши',
    ingredients: ['креветка тигровая', 'манго', 'сыр сливочный', 'авокадо', 'черная икра тобико', 'дикий рис', 'нори'],
    image: takeshi,
    price: 259,
  },
];
