import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';

import { FormSelect } from '../../../../../../form';
import { useAppData } from '../../../../../../storage/providers';
import { useShifts } from '../../../../../../user/hooks';

const UsersSelectField = (props) => {
  const { disabled, user } = props;
  const { t } = useTranslation();
  const { items: shifts } = useShifts();
  const { data } = useAppData();
  const { departments } = data;

  const departmentsUsers = useMemo(
    () =>
      departments
        .filter((d) => d.users.find((u) => u.id === user.id))
        .map((_) => _.users)
        .flat(),
    [departments, user]
  );

  const users = useMemo(
    () => shifts.map((_) => _.user).filter((u) => departmentsUsers.find((du) => du.id === u.id)),
    [shifts, departmentsUsers]
  );

  return (
    <Field
      id="userId"
      name="userId"
      label={t`Master`}
      placeholder={t`Master`}
      component={FormSelect}
      disabled={disabled}
    >
      {users.map(({ id, firstName, lastName }) => (
        <MenuItem key={`dep-${id}`} value={id}>
          {firstName} {lastName}
        </MenuItem>
      ))}
    </Field>
  );
};

UsersSelectField.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

export default UsersSelectField;
