import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  text: {
    cursor: 'pointer',
    borderBottom: `1px dashed ${theme.palette.action.disabled}`,
  },
  noDashing: {
    borderBottom: 'none',
  },
  input: {
    padding: theme.spacing(1),
    width: 300,
  },
  submitBtn: {
    marginTop: theme.spacing(1),
  },
}));
