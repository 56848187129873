import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { FormInput } from '../../../../form';
import { CircularProgressWrapper } from '../../../../auth/components/widgets/SignInForm/atoms';

const initialValues = {
  name: '',
  percentage: '',
};

const validationSchema = object().shape({
  name: string().required('Required'),
  percentage: number(),
});

const CreateSupplierForm = (props) => {
  const { isLoading, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <Box style={{ marginBottom: 20 }}>
              <Field id="name" name="name" label={t`Name`} placeholder={t`Name`} component={FormInput} />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field
                id="percentage"
                name="percentage"
                label={t`Percentage`}
                placeholder={t`Percentage`}
                component={FormInput}
                type="number"
              />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Button type="submit" variant="contained" disabled={!dirty || !isValid || isLoading} fullWidth>
                {isLoading && <CircularProgressWrapper size={16} />}
                {t`Submit`}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateSupplierForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateSupplierForm;
