import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  divider: {
    display: 'inline-flex',
    height: 10,
    width: 1,
    borderLeft: `solid 1px ${theme.palette.grey.A200}`,
    margin: `0 ${theme.spacing(1)}px`,
  },
}));
