import { useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const monthDynamicUrl = `${REACT_APP_API_URL}/statistic/month-dynamic`;

const useMonthDynamic = () => {
  const { get } = useRequest();
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (totals) => {
    setTotals(totals);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const loadMonthDynamic = ({ month, year }) => {
    setLoading(true);
    get({
      url: monthDynamicUrl,
      queryParams: { month, year },
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  return { totals, loading, loadMonthDynamic };
};

export default useMonthDynamic;
