import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const departmentUrl = `${REACT_APP_API_URL}/department`;

const useDepartment = (id, skipLanguage) => {
  const { get } = useRequest();
  const [department, setDepartment] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (department) => {
    setDepartment(department);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    get({ url: `${departmentUrl}/${id}`, skipLanguage })
      .then(handleSuccess)
      .catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { department, loading, setDepartment, setLoading };
};

export default useDepartment;
