import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { UserContext } from '.';
import { useUsers } from '../hooks';

const UsersProvider = (props) => {
  const { children, loadOnInit, departmentId, date } = props;
  const { loading, setLoading, setUsers, users, loadUsers } = useUsers({ loadOnInit, departmentId, date });

  return (
    <UserContext.Provider
      value={{
        loading,
        setLoading,
        setUsers,
        users,
        departmentId,
        loadUsers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.node.isRequired,
  departmentId: PropTypes.number,
  date: PropTypes.objectOf(moment),
  loadOnInit: PropTypes.bool,
};

export default UsersProvider;
