import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import {
  loadClientsSuccess,
  loadClientsFailed,
  loadClientsStarted,
  updateClient,
  resetClients,
  STATE_LOADING,
} from '../reducers/clients';

const { REACT_APP_API_URL } = process.env;

const clientsUrl = `${REACT_APP_API_URL}/client`;

const useClients = () => {
  const { get } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ clients }) => clients);
  const isLoading = state === STATE_LOADING;

  const start = useCallback(() => {
    dispatch(loadClientsStarted());
  }, [dispatch]);

  const set = useCallback(
    (orders) => {
      dispatch(loadClientsSuccess(orders));
    },
    [dispatch]
  );

  const fail = useCallback(() => {
    dispatch(loadClientsFailed());
  }, [dispatch]);

  const handleError = useCallback(
    (error) => {
      console.log(error);
      dispatch(loadClientsFailed());
    },
    [dispatch]
  );

  const load = useCallback(
    ({ offset, limit, searchText }) => {
      start();
      get({
        url: clientsUrl,
        queryParams: {
          offset,
          limit,
          ...(searchText ? { searchText } : {}),
        },
      })
        .then(set)
        .catch(handleError);
    },
    [get, handleError, set, start]
  );

  const update = useCallback(
    (updatedClient) => {
      dispatch(updateClient(updatedClient));
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(resetClients());
  }, [dispatch]);

  return {
    items,
    isLoading,
    start,
    load,
    set,
    fail,
    reset,
    updateClient: update,
  };
};

export default useClients;
