import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';

const ModalButton = forwardRef((props, ref) => {
  const isMounted = useRef(true);
  const { buttonComponent, children, title, contentProps, onOpen, fullscreen } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useImperativeHandle(ref, () => ({
    close: () => setIsOpen(false),
  }));

  const triggerModal = useCallback(
    (e) => {
      e && e.stopPropagation && e.stopPropagation();
      e && e.preventDefault && e.preventDefault();
      !isOpen && onOpen && onOpen();
      setIsOpen(!isOpen);
    },
    [isOpen, onOpen]
  );

  const handleOnClick = useCallback((e) => {
    e && e.stopPropagation && e.stopPropagation();
  }, []);

  const { onSubmit } = contentProps;
  const onSubmitProp = onSubmit
    ? {
        onSubmit: (values) => {
          onSubmit(values);
          if (isMounted.current) {
            triggerModal();
          }
        },
      }
    : {};

  const childrenProps = {
    ...contentProps,
    ...onSubmitProp,
    onCancel: triggerModal,
  };

  const button = React.cloneElement(buttonComponent, { onClick: triggerModal });
  const content = React.cloneElement(children, childrenProps);

  return (
    <>
      {button}
      <Modal
        isOpen={isOpen}
        onClose={triggerModal}
        onCloseIconClick={triggerModal}
        onClick={handleOnClick}
        title={title}
        fullscreen={fullscreen}
      >
        {content}
      </Modal>
    </>
  );
});

ModalButton.propTypes = {
  buttonComponent: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  contentProps: PropTypes.object,
  onOpen: PropTypes.func,
  fullscreen: PropTypes.bool,
};

ModalButton.defaultProps = {
  contentProps: {},
};

export default ModalButton;
