import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { useStyle } from './styles';
import { RangePicker, ModalButton } from '../../../../common';
import { useCashboxHistory, useCreateOperation } from '../../../hooks';
import { CashboxOperations, CreateOperationForm } from '../../widgets';

const CashboxHistory = () => {
  const classes = useStyle();
  const [{ dateFrom, dateTo }, setDates] = useState({});
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { loadCashbox, operations, loading, setLoading, setOperations } = useCashboxHistory();
  const { createOperation } = useCreateOperation();
  const isValid = dateFrom && dateTo;

  useEffect(() => {
    if (!operations && dateFrom && dateTo) {
      handleOnLoad();
    }
    // eslint-disable-next-line
  }, [operations, dateFrom, dateTo]);

  const onRangeSelected = (dateFrom, dateTo) => {
    setDates({ dateFrom, dateTo });
  };

  const handleOnLoad = () => {
    loadCashbox({
      dateFrom: moment(dateFrom).set({ hour: 0, minutes: 0, seconds: 0 }).utcOffset(0).format('YYYY-MM-DD HH:mm'),
      dateTo: moment(dateTo).set({ hour: 23, minutes: 59, seconds: 59 }).utcOffset(0).format('YYYY-MM-DD HH:mm'),
    });
  };

  const handleCreateOperation =
    (operation) =>
    ({ amount, note }) => {
      const params = { operation, amount, note };

      const onError = (error) => {
        if (error.message) {
          if (typeof error.message === 'string') {
            enqueueSnackbar(error.message, { variant: 'error' });
          }

          if (typeof error.message === 'object') {
            Object.keys(error.message[0].constraints).forEach((key) =>
              enqueueSnackbar(error.message[0].constraints[key], { variant: 'error' })
            );
          }
        }

        setLoading(false);
      };

      const onStart = () => {
        setLoading(true);
      };

      const onSuccess = (newOperation) => {
        setOperations([newOperation, ...operations]);
        setLoading(false);
      };

      createOperation({ params, onError, onSuccess, onStart });
    };

  return (
    <Box className={classes.page}>
      <Grid container>
        <Grid item xs={12} className={classes.datesWrapper}>
          <RangePicker onRangeSelected={onRangeSelected} disableSecondDate />
          <Button
            variant="outlined"
            color="primary"
            disabled={!isValid || loading}
            onClick={handleOnLoad}
            className={classes.loadButton}
          >
            {loading && <CircularProgress size={16} className={classes.spinner} />}
            {t`Load`}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.timeLineWrapper}>
          <ModalButton
            title={t`Income`}
            buttonComponent={
              <IconButton color="primary" disabled={!operations}>
                <AddIcon />
              </IconButton>
            }
            contentProps={{
              onSubmit: handleCreateOperation('In'),
            }}
          >
            <CreateOperationForm />
          </ModalButton>
          <ModalButton
            title={t`Outcome`}
            buttonComponent={
              <IconButton color="secondary" disabled={!operations}>
                <RemoveIcon />
              </IconButton>
            }
            contentProps={{
              onSubmit: handleCreateOperation('Out'),
            }}
          >
            <CreateOperationForm />
          </ModalButton>
        </Grid>
        <Grid item xs={12} className={classes.timeLineWrapper}>
          {operations && <CashboxOperations operations={operations} />}
        </Grid>
      </Grid>
    </Box>
  );
};

CashboxHistory.propTypes = {};

export default CashboxHistory;
