import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';

export const RowWrapper = styled.div`
  margin-bottom: 20px;
`;

export const CircularProgressWrapper = styled(CircularProgress)`
  margin-right: 5px;
`;
