import React from 'react';
import PropTypes from 'prop-types';

import DeclineForm from './DeclineForm';
import { useChangeOrderStatus, useOrders } from '../../../../../hooks';
import { OrderStatus } from '../../../../../constants';

const Decline = (props) => {
  const { id, onComplete } = props;
  const { items: orders, start, set, fail, isLoading } = useOrders();
  const { changeOrderStatus } = useChangeOrderStatus();

  const onStart = () => {
    start();
  };

  const onSuccess = (result) => {
    set(
      orders.map((_) => {
        const updatedOrder = result[_.id];

        if (!updatedOrder) return _;

        const { status, note } = updatedOrder;

        return {
          ..._,
          status,
          notes: note ? [..._.notes, note] : _.notes,
        };
      })
    );
    onComplete();
  };

  const onError = (error) => {
    console.log(error);
    fail();
  };

  const handleOnSubmit = (values) => {
    const params = { ...values, status: OrderStatus.DECLINED };
    changeOrderStatus({
      id,
      params,
      onStart,
      onSuccess,
      onError,
    });
  };

  return <DeclineForm onSubmit={handleOnSubmit} loading={isLoading} />;
};

Decline.propTypes = {
  id: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default Decline;
