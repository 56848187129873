import React from 'react';

import { Page } from '../../../common';
import SuppliersProvider from '../../providers/SuppliersProvider';
import SoldProductsByRange from '../../components/SoldProductsByRange';

const Sells = () => {
  return (
    <SuppliersProvider>
      <Page>
        <SoldProductsByRange />
      </Page>
    </SuppliersProvider>
  );
};

Sells.propTypes = {};

export default Sells;
