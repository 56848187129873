import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoData from '../NoData';
import { useRequest } from '../../../../network';
import { ModalButton, Text } from '..';
import { useStyle } from './styles';

const { REACT_APP_API_URL } = process.env;

const statementsUrl = `${REACT_APP_API_URL}/banking/statements`;

const Statements = () => {
  const { get } = useRequest();
  const [statements, setStatements] = useState(null);
  const [loading, setLoading] = useState(false);
  const [secondsToRetry, setSecondsToRetry] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isMounted = useRef();
  const classes = useStyle();

  const isEnabledToGetStatements = useMemo(() => secondsToRetry === 0, [secondsToRetry]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (secondsToRetry > 0) {
      setTimeout(() => {
        isMounted.current && setSecondsToRetry(secondsToRetry - 1);
      }, 1000);
    }
  }, [secondsToRetry]);

  const handleSuccess = useCallback(
    (orders) => {
      setStatements(orders);
      setLoading(false);
    },
    [setStatements, setLoading]
  );

  const handleError = useCallback(
    (error) => {
      if (error.message) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
      setLoading(false);
      setSecondsToRetry(20);
    },
    [enqueueSnackbar, setLoading, t]
  );

  const loadStatements = useCallback(
    (cardNumber) => {
      setLoading(true);
      get({
        url: `${statementsUrl}/${cardNumber}`,
      })
        .then(handleSuccess)
        .catch(handleError);
    },
    [setLoading, get, handleSuccess, handleError]
  );

  const handleLoadStatement = useCallback(
    (cardNumber) => () => {
      loadStatements(cardNumber);
      setSecondsToRetry(5);
    },
    [loadStatements, setSecondsToRetry]
  );

  return useMemo(() => {
    return (
      <ModalButton
        buttonComponent={
          <IconButton>
            <CardMembershipIcon style={{ color: 'white' }} />
          </IconButton>
        }
        title={t`Statements`}
      >
        <Grid container justify="center">
          <Grid container style={{ marginBottom: 20 }}>
            <Grid item xs={4}>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadStatement('0125')}
                  disabled={!isEnabledToGetStatements}
                >
                  <Text h4 bold>
                    0125
                  </Text>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadStatement('6391')}
                  disabled={!isEnabledToGetStatements}
                >
                  <Text h4 bold>
                    6391
                  </Text>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadStatement('6897')}
                  disabled={!isEnabledToGetStatements}
                >
                  <Text h4 bold>
                    6897
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="center" style={{ marginBottom: 20 }}>
            {!isEnabledToGetStatements && (
              <Text h4 upper>
                {t('Statement update will be available in {{secondsToRetry}} seconds', { secondsToRetry })}
              </Text>
            )}
          </Grid>

          {loading && (
            <Grid container justify="center">
              <CircularProgress size={16} />
            </Grid>
          )}

          {statements && !statements.length && !loading && (
            <Grid container justify="center" style={{ padding: 20 }}>
              <NoData />
            </Grid>
          )}

          {statements && !!statements.length && !loading && (
            <Grid container justify="center" style={{ padding: 20 }}>
              <Grid item xs={12}>
                {statements.map(({ id, description, time, amount }) => (
                  <Card key={`statement-${id}`} className={classes.card}>
                    <Grid container>
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Text h4 ghost style={{ marginRight: 10 }}>
                          {moment(time).format('DD.MM')}
                        </Text>
                        <Text h4 bold ghost>
                          {moment(time).format('HH:mm')}
                        </Text>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Text h4>{description}</Text>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Text h3 bold success>
                          {amount} UAH
                        </Text>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </ModalButton>
    );
  }, [loading, statements, classes.card, handleLoadStatement, isEnabledToGetStatements, t, secondsToRetry]);
};

Statements.propTypes = {};

export default Statements;
