import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';

import { Home } from './modules/home';
import { SignIn, AuthRoute } from './modules/auth';
import { EditDepartment, departmentMenu } from './modules/department';
import { Inarifood } from './modules/collaboration';
import { EditServiceGroup, serviceMenu } from './modules/service';
import { EditUsers, EditShifts, userMenu } from './modules/user';
import { ClientsList, clientMenu, clientReducers } from './modules/client';
import { ManageOrders, orderMenu, orderReducers } from './modules/order';
import { TotalByMaster, MonthDynamic, ClientsDynamic, NotReturnedClients, statisticMenu } from './modules/statistic';
import { CasboxHistory, cashboxMenu } from './modules/cashbox';
import { Landing } from './modules/landing';
import { Init, appReducers } from './modules/storage';
import { Messages, messageReducers } from './modules/message';
import { Products, Suppliers, Sells, productMenu, productReducers } from './modules/product';

import { userReducers } from './modules/user';
import { sideBarReducers, Layout, GlobalLoader } from './modules/common';

import theme from './theme';

import 'moment/locale/ru';
import 'moment/locale/uk';

const store = createStore(
  combineReducers(
    Object.assign(
      {},
      userReducers,
      sideBarReducers,
      appReducers,
      orderReducers,
      messageReducers,
      clientReducers,
      productReducers
    )
  )
);

const menu = [
  ...departmentMenu,
  ...serviceMenu,
  ...userMenu,
  ...clientMenu,
  ...orderMenu,
  ...statisticMenu,
  ...cashboxMenu,
  ...productMenu,
];

const App = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={language} libInstance={moment}>
          <Provider store={store}>
            <BrowserRouter>
              <Init />
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/collaboration/inarifood" component={Inarifood} />
              </Switch>
              <Layout menu={menu}>
                <Switch>
                  <AuthRoute exact path="/admin" component={Home} redirect="/admin/signIn" />
                  <AuthRoute exact path="/admin/signIn" component={SignIn} reverse redirect="/admin" />

                  <AuthRoute exact path="/admin/service/edit" component={EditServiceGroup} redirect="/admin/signIn" />

                  <AuthRoute exact path="/admin/department/edit" component={EditDepartment} redirect="/admin/signIn" />

                  <AuthRoute exact path="/admin/users" component={EditUsers} redirect="/admin/signIn" />
                  <AuthRoute exact path="/admin/users/shift" component={EditShifts} redirect="/admin/signIn" />

                  <AuthRoute exact path="/admin/clients" component={ClientsList} redirect="/admin/signIn" />

                  <AuthRoute exact path="/admin/orders" component={ManageOrders} redirect="/admin/signIn" />

                  <AuthRoute
                    exact
                    path="/admin/statistic/total-by-master"
                    component={TotalByMaster}
                    redirect="/admin/signIn"
                  />
                  <AuthRoute
                    exact
                    path="/admin/statistic/month-dynamic"
                    component={MonthDynamic}
                    redirect="/admin/signIn"
                  />
                  <AuthRoute
                    exact
                    path="/admin/statistic/clients-dynamic"
                    component={ClientsDynamic}
                    redirect="/admin/signIn"
                  />
                  <AuthRoute
                    exact
                    path="/admin/statistic/not-returned-clients"
                    component={NotReturnedClients}
                    redirect="/admin/signIn"
                  />

                  <AuthRoute exact path="/admin/cashbox/history" component={CasboxHistory} redirect="/admin/signIn" />

                  <AuthRoute exact path="/admin/messages" component={Messages} redirect="/admin/signIn" />

                  <AuthRoute exact path="/admin/products" component={Products} redirect="/admin/signIn" />
                  <AuthRoute exact path="/admin/products/sells" component={Sells} redirect="/admin/signIn" />
                  <AuthRoute exact path="/admin/suppliers" component={Suppliers} redirect="/admin/signIn" />
                </Switch>
              </Layout>
              <GlobalLoader />
            </BrowserRouter>
          </Provider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
