import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import ProductCard from './ProductCard';
import CreateSupplierForm from './CreateProductForm';
import ProductsCart from '../ProductsCart';
import { useProductsProvider } from '../../providers/ProductsProvider';
import { useProductsCartProvider } from '../../providers/ProductsCartProvider';
import { ModalButton } from '../../../common';
import { CartIcon, ContentContainer, ContentManagement, PageContainer } from './atoms';

const ProductsList = () => {
  const { isLoading, products, create } = useProductsProvider();
  const { cart } = useProductsCartProvider();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const createModalRef = useRef();
  const sellProductsRef = useRef();

  const isCartWithItems = useMemo(() => Object.keys(cart).length, [cart]);

  const sortedProducts = useMemo(() => {
    const productsWithQuantity = products.filter((_) => _.quantity);

    const productsWithoutQuantity = products.filter((_) => !_.quantity);

    return productsWithQuantity.concat(productsWithoutQuantity);
  }, [products]);

  const filteredProducts = useMemo(() => {
    if (!searchText) return sortedProducts;

    return sortedProducts.filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()));
  }, [searchText, sortedProducts]);

  const handleOnSubmitCreate = useCallback(
    (values) => {
      create({
        body: values,
        onSuccess: () => createModalRef.current && createModalRef.current.close(),
      });
    },
    [create]
  );

  const handleOnSearchChange = useCallback(({ target }) => {
    setSearchText(target.value);
  }, []);

  const handleOnSearchClear = useCallback(() => {
    setSearchText('');
  }, []);

  return useMemo(
    () => (
      <PageContainer>
        <ContentContainer>
          <ContentManagement>
            <ModalButton
              ref={createModalRef}
              buttonComponent={
                <Button color="primary">
                  <AddIcon />
                </Button>
              }
              title={t`Create product`}
            >
              <CreateSupplierForm onSubmit={handleOnSubmitCreate} isLoading={isLoading} />
            </ModalButton>
            <Box style={{ width: '100%', marginTop: 20 }}>
              <OutlinedInput
                value={searchText}
                style={{ width: '100%' }}
                onChange={handleOnSearchChange}
                placeholder={t`Start typing product name`}
                endAdornment={
                  searchText && (
                    <InputAdornment>
                      <IconButton onClick={handleOnSearchClear}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Box>
          </ContentManagement>

          {filteredProducts.map((product) => (
            <ProductCard key={`product-${product.id}-${product.quantity}`} product={product} />
          ))}
        </ContentContainer>

        {!!isCartWithItems && (
          <ModalButton
            ref={sellProductsRef}
            buttonComponent={
              <CartIcon color="secondary" aria-label="add">
                <DoneAllIcon />
              </CartIcon>
            }
            title={t`Sell products`}
          >
            <ProductsCart />
          </ModalButton>
        )}
      </PageContainer>
    ),
    [
      handleOnSubmitCreate,
      handleOnSearchChange,
      handleOnSearchClear,
      isLoading,
      filteredProducts,
      t,
      searchText,
      isCartWithItems,
    ]
  );
};

ProductsList.propTypes = {};

export default ProductsList;
