import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: undefined,
  menu: undefined,
};

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    setOpen(state, { payload: isOpen }) {
      return {
        ...state,
        isOpen,
      };
    },
    setMenu(state, { payload: menu }) {
      return {
        ...state,
        menu,
      };
    },
  },
});

export const { setOpen, setMenu } = sideBarSlice.actions;

export default sideBarSlice.reducer;
