import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    filterContainer: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    filterWrapper: {
      overflowX: 'auto',

      '& .filter-item': {
        marginRight: theme.spacing(1),
      },
      '& .filter-item:last-child': {
        marginRight: 0,
      },
    },
  };
});
