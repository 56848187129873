import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import { useStyle } from './styles';

const ActionButtons = (props) => {
  const { onComplete, onEdit, onDecline, onFail, onAdd, checked } = props;
  const { t } = useTranslation();
  const classes = useStyle();

  const handleOnChange = (value) => {
    onAdd(value.target.checked);
  };

  return (
    <ListItem className={classes.actionContainer}>
      <Tooltip title={t`Add to completes`}>
        <Checkbox color="primary" onChange={handleOnChange} checked={checked} />
      </Tooltip>
      <Box>
        <Tooltip title={t`Complete`}>
          <IconButton className={clsx(classes.actionButton, 'complete')} onClick={onComplete} size="small">
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t`Edit`}>
          <IconButton className={clsx(classes.actionButton, 'edit')} onClick={onEdit} size="small">
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t`Decline`}>
          <IconButton className={clsx(classes.actionButton, 'decline')} onClick={onDecline} size="small">
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t`Fail`}>
          <IconButton className={clsx(classes.actionButton, 'fail')} onClick={onFail} size="small">
            <WarningIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </ListItem>
  );
};

ActionButtons.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

export default ActionButtons;
