import React, { Fragment, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import StarIcon from '@material-ui/icons/Star';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useStyles } from './styles';
import { Skeleton, ModalButton, Text, formatPhone } from '../../../../common';
import { Access, Roles } from '../../../../auth';
import { UserContext } from '../../../providers';
import CreateUserForm from '../CreateUserForm';
import EditUserForm from '../EditUserForm';
import { useRemoveUser } from '../../../hooks';

const getRoleIcon = (role) => {
  switch (role) {
    case Roles.superAdmin:
      return <StarIcon style={{ color: '#e8cf44' }} />;
    case Roles.manager:
      return <EventNoteIcon style={{ color: '#4343a5' }} />;
    case Roles.lead:
      return <SupervisorAccountIcon style={{ color: '#a57643' }} />;
    case Roles.master:
      return <PersonIcon style={{ color: '#568d51' }} />;
    default:
      return <PersonIcon />;
  }
};

const tabs = [
  { id: 0, name: 'Active', status: 1 },
  { id: 1, name: 'Inactive', status: 2 },
];

const Users = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { users, loading, setLoading, setUsers } = useContext(UserContext);
  const { removeUser } = useRemoveUser();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const sortedUsers = useMemo(() => {
    if (!users) return users;

    return users
      .sort((a, b) => {
        const { departments: departmentsA } = a;
        const { departments: departmentsB } = b;
        const [{ name: nameA }] = departmentsA.length ? departmentsA : [{}];
        const [{ name: nameB }] = departmentsB.length ? departmentsB : [{}];

        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
        return 0;
      })
      .filter((user) => user.status === activeTab.status);
  }, [users, activeTab]);

  const handleRemoveUser = (id) => () => {
    const onStart = () => {
      setLoading(true);
    };

    const onSuccess = (user) => {
      setUsers(users.map((_) => (_.id === user.id ? user : _)));
      setLoading(false);
    };

    const onError = (error) => {
      console.log(error);
    };

    removeUser({ id, onStart, onSuccess, onError });
  };

  const handleTabChange = (_, id) => {
    setActiveTab(tabs.find((_) => _.id === id));
  };

  if (!sortedUsers || loading) return <Skeleton rows={8} />;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.row}>
        <Tabs centered value={activeTab.id} onChange={handleTabChange} indicatorColor="primary">
          {tabs.map(({ id, name }) => (
            <Tab key={`tab-${id}`} label={t(name)} value={id} />
          ))}
        </Tabs>
      </Grid>
      {sortedUsers.map((user) => {
        return (
          <Fragment key={`user-${user.id}`}>
            <Grid item xs={12} className={classes.paperWrapper}>
              <Paper className={classes.paper}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={8} className={classes.userInfo}>
                    <Box>
                      <Box className={classes.row}>
                        <Text ghost>#{user.id} </Text>
                        <Text bold>
                          {user.firstName} {user.lastName}{' '}
                        </Text>
                        <Text ghost>{`[${user.role.name}]`}</Text>
                      </Box>
                      <Box className={classes.row}>
                        <Text>{formatPhone(user.phoneNumber)}</Text>
                      </Box>
                      <Box className={classes.row}>
                        {user.departments.map(({ id, color, name }) => (
                          <Chip
                            key={`dep-${id}`}
                            size="small"
                            label={name}
                            style={{
                              border: `2px solid ${color}`,
                              backgroundColor: 'transparent',
                              marginRight: 5,
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box>
                      <Tooltip title={user.role.name} placement="top">
                        {getRoleIcon(user.role.name)}
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Access
                    roles={user.role.name === Roles.superAdmin ? [Roles.superAdmin] : [Roles.superAdmin, Roles.manager]}
                    andCondition={activeTab.status === 1}
                  >
                    <Grid item xs={12} sm={4} className={classes.actionsWrapper}>
                      <ModalButton
                        buttonComponent={
                          <Tooltip title={t`Edit`} placement="top">
                            <CreateIcon className={classes.editIcon} />
                          </Tooltip>
                        }
                        title={t`Edit user`}
                        contentProps={{
                          onSubmit: () => {},
                        }}
                      >
                        <EditUserForm user={user} />
                      </ModalButton>
                      <Access roles={[Roles.superAdmin]}>
                        <Tooltip title={t`Delete`} placement="top">
                          <DeleteOutlineIcon className={classes.removeIcon} onClick={handleRemoveUser(user.id)} />
                        </Tooltip>
                      </Access>
                    </Grid>
                  </Access>
                </Grid>
              </Paper>
            </Grid>
          </Fragment>
        );
      })}
      <Grid item xs={12} className={classes.addButtonWrapper}>
        <ModalButton
          buttonComponent={
            <Button color="primary" className={classes.addButton}>
              {t`Create user`}
            </Button>
          }
          title={t`Create user`}
          contentProps={{
            onSubmit: () => {},
          }}
        >
          <CreateUserForm />
        </ModalButton>
      </Grid>
    </Grid>
  );
};

Users.propTypes = {};

export default Users;
