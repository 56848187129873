import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createDepartmentUrl = `${REACT_APP_API_URL}/department`;

const useCreateDepartment = () => {
  const { post } = useRequest();

  const createDepartment = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    post({ url: createDepartmentUrl, body: params }).then(onSuccess).catch(onError);
  };

  return { createDepartment };
};

export default useCreateDepartment;
