import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import MenuItem from '@material-ui/core/MenuItem';

import { FormDatePicker, FormInput, FormSelect } from '../../../../form/adapters';

export const FirstNameField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      id="firstName"
      name="firstName"
      label={t`First name`}
      placeholder={t`First name`}
      component={FormInput}
      disabled={disabled}
    />
  );
};

FirstNameField.propTypes = {
  disabled: PropTypes.bool,
};

export const LastNameField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      id="lastName"
      name="lastName"
      label={t`Last name`}
      placeholder={t`Last name`}
      component={FormInput}
      disabled={disabled}
    />
  );
};

LastNameField.propTypes = {
  disabled: PropTypes.bool,
};

export const InstagramField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      id="instagram"
      name="instagram"
      label={t`Instagram`}
      placeholder={t`Instagram`}
      component={FormInput}
      disabled={disabled}
    />
  );
};

InstagramField.propTypes = {
  disabled: PropTypes.bool,
};

export const PhoneNumberField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      id="phoneNumber"
      name="phoneNumber"
      label={t`Phone number`}
      placeholder={t`Phone number`}
      component={FormInput}
      disabled={disabled}
    />
  );
};

PhoneNumberField.propTypes = {
  disabled: PropTypes.bool,
};

export const EmailField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field id="email" name="email" label={t`Email`} placeholder={t`Email`} component={FormInput} disabled={disabled} />
  );
};

EmailField.propTypes = {
  disabled: PropTypes.bool,
};

export const BirthDateField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field
      id="birthDate"
      name="birthDate"
      label={t`Birth date`}
      placeholder={t`Birth date`}
      component={FormDatePicker}
      disabled={disabled}
    />
  );
};

BirthDateField.propTypes = {
  disabled: PropTypes.bool,
};

export const SexField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field id="sex" name="sex" label={t`Sex`} placeholder={t`Sex`} component={FormSelect} disabled={disabled}>
      <MenuItem key="F" value="F">{t`Female`}</MenuItem>
      <MenuItem key="M" value="M">{t`Male`}</MenuItem>
    </Field>
  );
};

SexField.propTypes = {
  disabled: PropTypes.bool,
};

export const NoteField = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();

  return (
    <Field id="note" name="note" label={t`Note`} placeholder={t`Note`} component={FormInput} disabled={disabled} />
  );
};
