import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const removeUserUrl = `${REACT_APP_API_URL}/user`;

const useRemoveUser = () => {
  const { del } = useRequest();

  const removeUser = ({ id, onStart, onSuccess, onError }) => {
    onStart();
    del({
      url: `${removeUserUrl}/${id}`,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { removeUser };
};

export default useRemoveUser;
