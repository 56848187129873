import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const ordersHistoryUrl = `${REACT_APP_API_URL}/order/history`;

const useOrdersHistory = () => {
  const { get } = useRequest();
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccess = (orders) => {
    setOrders(orders);
    setLoading(false);
  };

  const handleError = (error) => {
    if (error.message) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  const loadOrders = ({ dateFrom, dateTo, userId }) => {
    setLoading(true);
    get({
      url: ordersHistoryUrl,
      queryParams: {
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
        ...(userId ? { userId } : {}),
      },
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  return { orders, loading, setOrders, setLoading, loadOrders };
};

export default useOrdersHistory;
