import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ForumIcon from '@material-ui/icons/Forum';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';

import Text from '../Text';
import Statements from '../Statements';
import { setOpen } from '../../../reducers/sideBar';
import { useLogout } from '../../../../auth/providers';
import { Access, Roles } from '../../../../auth';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 64,
    backgroundColor: '#212121',
    zIndex: 3,
    [theme.breakpoints.up('sm')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  middle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const Header = () => {
  const { isOpen } = useSelector(({ sideBar }) => sideBar);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { logout } = useLogout();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setOpen(false));
    // eslint-disable-next-line
  }, [pathname]);

  const handleOnClick = useCallback(() => {
    dispatch(setOpen(!isOpen));
  }, [dispatch, isOpen]);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Box className={classes.header}>
      <Grid container justify="center" style={{ height: '100%' }}>
        <Grid item xs={4} className={classes.left}>
          <IconButton onClick={handleOnClick}>
            {isOpen ? <CloseIcon style={{ color: 'white' }} /> : <MenuIcon style={{ color: 'white' }} />}
          </IconButton>
        </Grid>
        <Grid item xs={4} className={classes.middle}>
          <Text h1 upper white>
            Amaze
          </Text>
        </Grid>
        <Grid item xs={4} className={classes.right}>
          <Statements />
          <Access roles={[Roles.manager, Roles.superAdmin]}>
            <Fab component={Link} to="/admin/messages" style={{ backgroundColor: 'transparent' }}>
              <Badge color="primary" badgeContent={0}>
                <ForumIcon style={{ color: 'white' }} />
              </Badge>
            </Fab>
          </Access>
          <IconButton onClick={handleLogout}>
            <ExitToAppIcon style={{ color: 'white' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

Header.propTypes = {};

export default Header;
