import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';

import { useStyles } from './styles';
import { ClientsList } from '../../../../../client';
import { ClientCard } from '../../../../../client';
import { ModalButton, Text } from '../../../../../common';

const ClientField = (props) => {
  const { t } = useTranslation();
  const { field, form } = props;
  const { values, setFieldValue } = form;
  const classes = useStyles();

  const client = values.client;

  const handleSelectClient = useCallback(
    (client) => {
      setFieldValue(field.name, client);
    },
    [setFieldValue, field.name]
  );

  const clientComponent = useMemo(() => {
    if (typeof client === 'object') {
      return <ClientCard client={client} />;
    }

    return <Text ghost>{t`No client chosen`}</Text>;
  }, [t, client]);

  return (
    <Grid container className={classes.clientContainer}>
      <Grid item className={classes.clientInfo}>
        {clientComponent}
      </Grid>
      <Grid item className={classes.submitButtonContainer}>
        <ModalButton
          buttonComponent={
            <IconButton variant="contained" color="primary" size="small">
              {client ? <SwapHorizontalCircleIcon /> : <AddIcon />}
            </IconButton>
          }
          title={'Pick client'}
          fullscreen
        >
          <ClientsList onClientSelected={handleSelectClient} />
        </ModalButton>
      </Grid>
    </Grid>
  );
};

ClientField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default ClientField;
