import { useCallback } from 'react';

import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createServiceGroupUrl = `${REACT_APP_API_URL}/service-group`;

const useCreateServiceGroup = () => {
  const { post } = useRequest();

  const createServiceGroup = useCallback(
    ({ params, onStart, onSuccess, onError }) => {
      onStart();
      post({ url: createServiceGroupUrl, body: params }).then(onSuccess).catch(onError);
    },
    [post]
  );

  return { createServiceGroup };
};

export default useCreateServiceGroup;
