import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { date, string } from 'yup';
import { useSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Badge from '@material-ui/core/Badge';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';

import { Editable, RowDivider, Text, ConfirmDialog } from '../../../../common';
import { useStyle } from './styles';
import {
  BirthDateField,
  EmailField,
  FirstNameField,
  LastNameField,
  InstagramField,
  PhoneNumberField,
  SexField,
  NoteField,
} from '../CreateClientForm/fields';
import { useEditClient, useRemoveClientNote } from '../../../hooks';

const ClientEditor = (props) => {
  const { client, updateClient, className } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const { editClient } = useEditClient();
  const { removeClientNote } = useRemoveClientNote();
  const { enqueueSnackbar } = useSnackbar();

  const onEditClientStart = useCallback(() => {}, []);

  const onEditClientSuccess = useCallback(
    (editedClient) => {
      updateClient(editedClient);
    },
    [updateClient]
  );

  const onEditClientError = useCallback(
    (error) => {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      console.log(error);
    },
    [enqueueSnackbar]
  );

  const onSubmitEditClient = useCallback(
    (params) => {
      editClient({
        id: client.id,
        params,
        onStart: onEditClientStart,
        onError: onEditClientError,
        onSuccess: onEditClientSuccess,
      });
    },
    [client.id, editClient, onEditClientStart, onEditClientError, onEditClientSuccess]
  );

  const onRemoveNoteStart = useCallback(() => {}, []);

  const onRemoveNoteSuccess = useCallback(() => {
    // updateClients(clients.map(_ => {
    //   return _.id === client.id
    //     ? {
    //         ..._,
    //         notes: _.notes.filter(n => n.id !== noteId)
    //       }
    //     : _;
    // }));
  }, []);

  const onRemoveNoteError = useCallback(
    (error) => {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      console.log(error);
    },
    [enqueueSnackbar]
  );

  const handleOnRemoveNoteClick = useCallback(
    (noteId) => () => {
      removeClientNote({
        id: noteId,
        onStart: onRemoveNoteStart,
        onError: onRemoveNoteError,
        onSuccess: onRemoveNoteSuccess,
      });
    },
    [removeClientNote, onRemoveNoteStart, onRemoveNoteError, onRemoveNoteSuccess]
  );

  // if (clientsLoading) return <Skeleton rows={15} />;

  if (!client) return <Text ghost>{t`Client is not selected`}</Text>;

  return (
    <div className={`${classes.content} ${className}`}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.centeredRow}>
            <Avatar
              alt={`${client.firstName} ${client.lastName}`}
              src="/static/images/avatar/1.jpg"
              className={classes.avatar}
            />
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Grid container justify="center">
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Badge badgeContent={client.success} style={{ marginRight: 20 }}>
                  <CheckCircleOutlineIcon fontSize="large" style={{ color: 'green' }} />
                </Badge>
                <Badge badgeContent={client.declined} style={{ marginRight: 20 }}>
                  <ErrorOutlineIcon fontSize="large" style={{ color: 'gray' }} />
                </Badge>
                <Badge badgeContent={client.failed} style={{ marginRight: 20 }}>
                  <HighlightOffIcon fontSize="large" style={{ color: 'red' }} />
                </Badge>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`First name`} />
            <Editable
              field={<FirstNameField />}
              validationSchema={{
                firstName: string().required('Required'),
              }}
              initialValues={{ firstName: client.firstName || '' }}
              onSubmit={onSubmitEditClient}
            >
              {client.firstName}
            </Editable>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`Last name`} />
            <Editable
              field={<LastNameField />}
              validationSchema={{
                lastName: string().required('Required'),
              }}
              initialValues={{ lastName: client.lastName || '' }}
              onSubmit={onSubmitEditClient}
            >
              {client.lastName}
            </Editable>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`Instagram`} />
            <Editable
              field={<InstagramField />}
              validationSchema={{
                instagram: string().required('Required'),
              }}
              initialValues={{ instagram: client.instagram || '' }}
              onSubmit={onSubmitEditClient}
            >
              {client.instagram}
            </Editable>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`Phone number`} />
            <Editable
              field={<PhoneNumberField />}
              validationSchema={{
                phoneNumber: string()
                  .required('Required')
                  .matches(/^\+380(50|95|66|99|39|67|68|96|97|98|63|93|73)\d{7}$/, 'Not valid phone number'),
              }}
              initialValues={{ phoneNumber: client.phoneNumber || '' }}
              onSubmit={onSubmitEditClient}
            >
              {client.phoneNumber}
            </Editable>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`Email`} />
            <Editable
              field={<EmailField />}
              validationSchema={{
                email: string().email('Not valid email').required('Required'),
              }}
              initialValues={{ email: client.email || '' }}
              onSubmit={onSubmitEditClient}
            >
              {client.email}
            </Editable>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`Birth date`} />
            <Editable
              field={<BirthDateField />}
              validationSchema={{
                birthDate: date().required('Required'),
              }}
              initialValues={{ birthDate: moment(client.birthDate) || '' }}
              onSubmit={onSubmitEditClient}
            >
              {moment(client.birthDate).format('YYYY-MM-DD')}
            </Editable>
          </Grid>
          <Grid item xs={12} md={6} className={classes.row}>
            <RowDivider text={t`Sex`} />
            <Editable
              field={<SexField />}
              validationSchema={{
                sex: string().required('Required'),
              }}
              initialValues={{ sex: client.sex || '' }}
              onSubmit={onSubmitEditClient}
            >
              {client.sex}
            </Editable>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <RowDivider text={t`Notes`} />
            {client.notes &&
              client.notes.map(({ id, note }) => (
                <Grid key={`c-${id}`} container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                    <AttachFileIcon fontSize="small" style={{ marginRight: 10 }} />
                    <Text>{note}</Text>
                  </Grid>
                  <Grid item xs={2}>
                    <ConfirmDialog
                      onConfirm={handleOnRemoveNoteClick(id)}
                      text={t`You are about to delete this note. Please confirm.`}
                    >
                      <IconButton color="secondary">
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </IconButton>
                    </ConfirmDialog>
                  </Grid>
                </Grid>
              ))}
            <Editable
              field={<NoteField />}
              validationSchema={{
                note: string().required('Required'),
              }}
              initialValues={{ note: '' }}
              onSubmit={onSubmitEditClient}
              disableDashing
            >
              <IconButton color="primary">
                <AddCircleOutlineIcon fontSize="small" />
              </IconButton>
            </Editable>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

ClientEditor.propTypes = {
  client: PropTypes.object,
  updateClient: PropTypes.func,
};

export default ClientEditor;
