import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    stickyContainer: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: 2,
    },
    searchInput: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    clientInfo: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    addButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    addButtonScrolled: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.background.default,
      transition: '.5s',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
      },
    },
    loadMoreButtonWrapper: {
      marginTop: theme.spacing(2),
    },
  };
});
