import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    pageWrapper: {
      height: '100vh',
      maxWidth: '100vw',
      padding: theme.spacing(2),
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paperWrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
    },
  };
});
