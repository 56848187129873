import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { MoneyIcon, Text } from '..';

import { useAppData } from '../../../../storage/providers';
import { useStyle } from './styles';

const StaticView = (props) => {
  const { services, showTotal, payments, totalSalary } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const { data } = useAppData();
  const { departments } = data;

  return (
    <Box className={classes.tableWrapper}>
      {services.map(({ id, name, price, serviceGroupId, salary, amount }) => {
        const department = departments.find((d) => d.serviceGroups.find((sg) => +sg.id === +serviceGroupId)) || {};

        return (
          <Box key={`service-${id}`} alignItems="center" className={classes.gridRow}>
            <Box className={classes.cell} style={{ borderLeft: `5px solid ${department.color}` }}>
              <Text>{name}</Text>
            </Box>
            <Box className={classes.cell}>
              <Text h5>x{amount}</Text>
            </Box>
            <Box className={classes.cell}>
              <Text bold pushRight>
                {+price * amount}
              </Text>
              {salary && (
                <Text h5 ghost italic bold>
                  {salary}
                </Text>
              )}
            </Box>
          </Box>
        );
      })}
      {showTotal && !payments.length && (
        <Text ghost h5>
          {t`No payments`}
        </Text>
      )}
      {!!(showTotal && payments.length) &&
        payments.map(({ paymentType, amount }, idx) => (
          <Box className={classes.gridTotalRow} key={`pay-${idx}`}>
            <Box className={classes.totalCell} />
            <Box className={classes.totalCell}>
              <MoneyIcon type={paymentType} className={classes.paymentType} />
            </Box>
            <Box className={classes.totalCell}>
              <Text bold pushRight>
                {+amount}
              </Text>
              {!!totalSalary && (
                <Text h5 ghost italic bold>
                  {totalSalary}
                </Text>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

StaticView.propTypes = {};

export default StaticView;
