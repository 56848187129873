import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
};

const ordersToCloseSlice = createSlice({
  name: 'ordersToClose',
  initialState,
  reducers: {
    addOrder(state, { payload: item }) {
      return {
        ...state,
        items: [...state.items, item],
      };
    },
    removeOrder(state, { payload: item }) {
      return {
        ...state,
        items: state.items.filter((_) => _.id !== item.id),
      };
    },
    resetOrdersToClose() {
      return { ...initialState };
    },
  },
});

export const { addOrder, removeOrder, resetOrdersToClose } = ordersToCloseSlice.actions;

export default ordersToCloseSlice.reducer;
