import React, { useMemo, Fragment, useCallback, useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { Filters, Text, Page } from '../../../../common';
import sushiItems from './sushiItems';
import rollsPopularItems from './rollsPopularItems';
import rollsForelItems from './rollsForelItems';
import rollsUnagiItems from './rollsUnagiItems';
import rollsShrimpItems from './rollsShrimpItems';
import rollsFriedItems from './rollsFriedItems';
import rollsTunaItems from './rollsTunaItems';
import makiItems from './makiItems';
import setItems from './setItems';

import inariLogo from '../../../../../assets/images/inarifood/logo.jpg';

import { useStyle } from './styles';

const filterItems = [
  { key: 'sushi', label: 'Суши' },
  { key: 'rolls-popular', label: 'Роллы: популярные' },
  { key: 'rolls-forel', label: 'Роллы: форель' },
  { key: 'rolls-unagi', label: 'Роллы: угорь' },
  { key: 'rolls-shrimp', label: 'Роллы: криветка' },
  { key: 'rolls-fried', label: 'Роллы: жареные' },
  { key: 'rolls-tuna', label: 'Роллы: тунец' },
  { key: 'rolls-maki', label: 'Роллы: маки' },
  { key: 'sets', label: 'Сеты' },
];

const Ingredients = (props) => {
  const { items } = props;

  return useMemo(
    () => (
      <div style={{ marginBottom: 10 }}>
        {items.map((item, idx) => (
          <Fragment key={`ingredient: ${idx}`}>
            <Text h3 white>
              {item}
            </Text>
            {idx + 1 < items.length && (
              <span style={{ width: 1, height: 10, borderLeft: '1px solid #505050', margin: '0 10px' }} />
            )}
          </Fragment>
        ))}
      </div>
    ),
    [items]
  );
};

const Inarifood = () => {
  const classes = useStyle();
  const [selectedItems, setSelectedItems] = useState([filterItems[0]]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 200);
  }, []);

  const activeItems = useMemo(() => {
    if (selectedItems.find((_) => _.key === 'sushi')) return sushiItems;
    if (selectedItems.find((_) => _.key === 'rolls-popular')) return rollsPopularItems;
    if (selectedItems.find((_) => _.key === 'rolls-forel')) return rollsForelItems;
    if (selectedItems.find((_) => _.key === 'rolls-unagi')) return rollsUnagiItems;
    if (selectedItems.find((_) => _.key === 'rolls-shrimp')) return rollsShrimpItems;
    if (selectedItems.find((_) => _.key === 'rolls-fried')) return rollsFriedItems;
    if (selectedItems.find((_) => _.key === 'rolls-tuna')) return rollsTunaItems;
    if (selectedItems.find((_) => _.key === 'rolls-maki')) return makiItems;
    if (selectedItems.find((_) => _.key === 'sets')) return setItems;

    return [];
  }, [selectedItems]);

  const handleOnFilterChange = useCallback((values) => {
    setSelectedItems(values);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page className={classes.page}>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
          <img src={inariLogo} alt="inari logo" style={{ width: 70, height: 70, borderRadius: 4 }} />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginBottom: 20, position: 'sticky', top: 0, padding: '10px 0', backgroundColor: 'black' }}
        >
          <Filters onChange={handleOnFilterChange} singleSelect initialItems={selectedItems} items={filterItems} />
        </Grid>
        <Grid container className={classes.itemsContainer}>
          <Grid container className={classes.itemsBlock}>
            {activeItems.map(({ title, image, ingredients, price }, idx) => (
              <Grid key={`item-${idx}`} item className={classes.item}>
                <LazyLoad once>
                  <img src={image} alt="product" className={classes.itemImg} />
                </LazyLoad>
                <Box style={{ marginBottom: 10 }}>
                  <Text h2 bold upper white>
                    {title}
                  </Text>
                </Box>
                <Ingredients items={ingredients} />
                <Text h2 white bold pushRight>
                  {Math.ceil(price * 0.8)}₴
                </Text>
                <Text h3 ghost style={{ color: '#9c9c9c', textDecoration: 'line-through' }}>
                  {price}₴
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

Inarifood.propTypes = {};

export default Inarifood;
