import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

export const ChipsWrapper = styled.div`
  display: flex;
  flexwrap: wrap;
`;

export const ChipWrapper = styled(Chip)`
  margin: 2px;
`;
