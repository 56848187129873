import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';

import { Text } from '../../../../common';
import { useStyle } from './styles';

const DepartmentsGrid = (props) => {
  const { departments, selectedDepartments, onDepartmentClick } = props;
  const classes = useStyle();

  return useMemo(() => {
    if (!departments) return null;

    return (
      <Box style={{ marginRight: 10 }}>
        {departments.map((department) => {
          const { id, name, color } = department;

          return (
            <Box
              key={`dep-${id}`}
              style={{ borderLeft: `2px solid ${color}` }}
              className={classes.selectableItem}
              onClick={() => onDepartmentClick(department)}
            >
              <Grid container alignItems="center">
                <Text pushRight>{name}</Text>
                {selectedDepartments.some((_) => _.id === id) && <DoneIcon color="primary" fontSize="small" />}
              </Grid>
            </Box>
          );
        })}
      </Box>
    );
  }, [departments, selectedDepartments, onDepartmentClick, classes.selectableItem]);
};

DepartmentsGrid.propTypes = {
  departments: PropTypes.array,
  selectedDepartments: PropTypes.array.isRequired,
  onDepartmentClick: PropTypes.func.isRequired,
};

export default DepartmentsGrid;
