import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Card from '@material-ui/core/Card';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import DoneIcon from '@material-ui/icons/Done';
import Box from '@material-ui/core/Box';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

import { Bill, removeMillisFromTime, Skeleton, Text } from '../../../../common';
import { useStyle } from './styles';
import { useAppData } from '../../../../storage/providers';
import { useClientOrders } from '../../../hooks';

const statusMapper = {
  1: { icon: <HourglassEmptyIcon fontSize="small" />, color: 'orange' },
  2: { icon: <DoneIcon fontSize="small" />, color: 'green' },
  3: { icon: <WarningIcon fontSize="small" />, color: 'gray' },
  4: { icon: <CloseIcon fontSize="small" />, color: 'red' },
};

const ClientOrders = (props) => {
  const { client } = props;
  const { t } = useTranslation();
  const classes = useStyle();
  const { data } = useAppData();
  const { departments = [] } = data;
  const { items: orders, load: loadOrders, isLoading: ordersLoading, reset } = useClientOrders();

  const users = departments.map((_) => _.users).flat();

  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isOrdersLoaded = orders && orders.length && orders[0].client.id === client.id;

    if (client && !isOrdersLoaded) {
      loadOrders({ clientId: client.id });
    }
    // eslint-disable-next-line
  }, [client]);

  return useMemo(() => {
    if (ordersLoading) return <Skeleton rows={15} />;

    if (!client) return <Text ghost>{t`Client is not selected`}</Text>;

    return (
      <Timeline align="left" className={classes.timeLine}>
        {orders &&
          [...orders]
            .sort((a, b) => {
              if (a.date < b.date) return 1;
              if (a.date > b.date) return -1;
              return 0;
            })
            .map((order) => {
              const { id, date, startTime, endTime, userId, status, note, services } = order;
              const user = users.find((_) => _.id === userId);

              const card = (
                <Card className={classes.card}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Text ghost># {id}</Text>
                    </Box>
                    <Box>
                      <Box>
                        <Text h5 bold>
                          {moment(date).format('DD.MM.YYYY')}
                        </Text>
                      </Box>
                      <Box>
                        <Text h5 ghost>
                          {removeMillisFromTime(startTime)} - {removeMillisFromTime(endTime)}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.row}>
                    <Text bold>
                      {user.firstName} {user.lastName}
                    </Text>
                    {user.departments.map((d) => (
                      <Chip
                        key={`dep-${d.id}`}
                        size="small"
                        label={d.name}
                        style={{
                          border: `2px solid ${d.color}`,
                          backgroundColor: 'transparent',
                          marginLeft: 5,
                        }}
                      />
                    ))}
                  </Box>
                  <Box className={classes.row}>
                    <Text ghost italic>
                      {note || ''}
                    </Text>
                  </Box>
                  <Bill services={services} />
                </Card>
              );

              return (
                <TimelineItem key={`order-${id}`}>
                  <TimelineOppositeContent style={{ display: 'none' }} />
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" style={{ borderColor: statusMapper[status].color }}>
                      {statusMapper[status].icon}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{card}</TimelineContent>
                </TimelineItem>
              );
            })}
      </Timeline>
    );
  }, [client, users, ordersLoading, orders, classes, t]);
};

ClientOrders.propTypes = {
  client: PropTypes.object,
};

export default ClientOrders;
