import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  data: {},
  state: STATE_INIT,
  offset: 0,
  limit: 50,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    loadMessagesStarted(state) {
      return { ...state, state: STATE_LOADING };
    },
    loadMessagesSuccess(state, { payload: data }) {
      return { ...state, data, state: STATE_SUCCESS };
    },
    loadMessagesFailed(state) {
      return { ...state, state: STATE_FAILED };
    },
    updateMessages(state, { payload: data }) {
      return { ...state, data };
    },
  },
});

export const { loadMessagesStarted, loadMessagesSuccess, loadMessagesFailed, updateMessages } = messagesSlice.actions;

export default messagesSlice.reducer;
