import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    messageContainer: {
      marginBottom: theme.spacing(2),
    },
    messageWrapper: {
      padding: theme.spacing(1),
      backgroundColor: '#e6efeb',
      width: '80%',
    },
    unread: {
      backgroundColor: '#dcebf1',
    },
    myMessage: {
      backgroundColor: '#f1f1f1',
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
    checkBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20%',
    },
  };
});
