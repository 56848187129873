import React from 'react';
import PropTypes from 'prop-types';

import { useWorkingPlaces } from '../hooks';
import { WorkingPlaceContext } from '.';

const WorkingPlacesProvider = (props) => {
  const { children } = props;
  const value = useWorkingPlaces();

  return <WorkingPlaceContext.Provider value={value}>{children}</WorkingPlaceContext.Provider>;
};

WorkingPlacesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WorkingPlacesProvider;
