import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editOrderUrl = `${REACT_APP_API_URL}/order`;

const useEditOrder = () => {
  const { patch } = useRequest();

  const editOrder = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    patch({ url: `${editOrderUrl}/${id}`, body: params })
      .then(onSuccess)
      .catch(onError);
  };

  return { editOrder };
};

export default useEditOrder;
