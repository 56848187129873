import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { Text } from '..';

const ConfirmDialog = (props) => {
  const { children, onConfirm, onCancel, text } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const wrapperId = `${new Date().getTime()}-wrapper`;
  const { t } = useTranslation();

  const handleOnWindowClick = (e) => {
    const { x, y } = e.target.getBoundingClientRect();
    if (x === 0 && y === 0) {
      e.stopPropagation();
      e.preventDefault();
      setIsOpenDialog(false);
    }
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpenDialog(true);
  };

  useEffect(() => {
    if (isOpenDialog) {
      window.addEventListener('click', handleOnWindowClick, { capture: true });

      return () => {
        window.removeEventListener('click', handleOnWindowClick, { capture: true });
      };
    }
  }, [isOpenDialog]);

  useEffect(() => {
    const wrapperEl = document.getElementById(wrapperId);
    wrapperEl.addEventListener('click', handleOnClick, { capture: true });

    return () => {
      wrapperEl.removeEventListener('click', handleOnClick, { capture: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onConfirm();
    setIsOpenDialog(false);
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onCancel && onCancel();
    setIsOpenDialog(false);
  };

  return (
    <Box id={wrapperId}>
      {children}
      <Dialog open={isOpenDialog}>
        {text && (
          <Box style={{ padding: 10 }}>
            <Text h3>{text}</Text>
          </Box>
        )}
        <DialogActions>
          <Button autoFocus onClick={handleOnConfirm} color="default">
            {t`Yes`}
          </Button>
          <Button autoFocus onClick={handleOnCancel} color="secondary">
            {t`No`}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ConfirmDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  text: PropTypes.string,
};

export default ConfirmDialog;
