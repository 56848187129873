import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const archiveServiceUrl = `${REACT_APP_API_URL}/service`;

const useArchiveService = () => {
  const { del } = useRequest();

  const editService = ({ id, onStart, onSuccess, onError }) => {
    onStart();
    del({ url: `${archiveServiceUrl}/${id}` })
      .then(onSuccess)
      .catch(onError);
  };

  return { editService };
};

export default useArchiveService;
