import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createOrderUrl = `${REACT_APP_API_URL}/order`;

const useCreateOrder = () => {
  const { post } = useRequest();

  const createOrder = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    post({ url: createOrderUrl, body: params }).then(onSuccess).catch(onError);
  };

  return { createOrder };
};

export default useCreateOrder;
