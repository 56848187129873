import { useRequest } from '../../network';
import { useCallback } from 'react';

const { REACT_APP_API_URL } = process.env;

const removeClientNoteUrl = `${REACT_APP_API_URL}/client/note`;

const useRemoveClientNote = () => {
  const { del } = useRequest();

  const removeClientNote = useCallback(
    ({ id, onStart, onSuccess, onError }) => {
      onStart();
      del({
        url: `${removeClientNoteUrl}/${id}`,
      })
        .then(onSuccess)
        .catch(onError);
    },
    [del]
  );

  return { removeClientNote };
};

export default useRemoveClientNote;
