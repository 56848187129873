import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import AddCalculationForm from '../AddCalculationForm';
import { Text } from '../../../../common';
import { useAppData } from '../../../../storage/providers';

const Calculations = (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { data } = useAppData();
  const { users } = data;
  const { calcs = [] } = service;

  return (
    <Grid container justify="center">
      {calcs.map(({ id, calc, userId }) => {
        const user = users.find((_) => +_.id === +userId);

        return (
          <Grid container key={`calc-${id}`}>
            <Grid item xs={4}>
              <Text bold>{calc}</Text>
            </Grid>
            <Grid item xs={8}>
              <Text ghost>{user ? `${user.firstName} ${user.lastName}` : t`Default`}</Text>
            </Grid>
          </Grid>
        );
      })}
      <AddCalculationForm service={service} />
    </Grid>
  );
};

Calculations.propTypes = {
  service: PropTypes.object.isRequired,
};

export default Calculations;
