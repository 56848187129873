import React from 'react';

import { ServiceGroups } from '../../widgets';
import { Page } from '../../../../common';
import { DepartmentsProvider } from '../../../../department';
import { ServiceGroupsProvider } from '../../../providers';

const EditService = () => {
  return (
    <Page>
      <ServiceGroupsProvider>
        <DepartmentsProvider>
          <ServiceGroups />
        </DepartmentsProvider>
      </ServiceGroupsProvider>
    </Page>
  );
};

EditService.propTypes = {};

export default EditService;
