import React from 'react';

import ProductsProvider from '../../providers/ProductsProvider';
import SuppliersProvider from '../../providers/SuppliersProvider';
import ProductsCartProvider from '../../providers/ProductsCartProvider';
import { Page } from '../../../common';
import { ProductsList } from '../../components';

const Products = () => {
  return (
    <SuppliersProvider>
      <ProductsProvider>
        <ProductsCartProvider>
          <Page>
            <ProductsList />
          </Page>
        </ProductsCartProvider>
      </ProductsProvider>
    </SuppliersProvider>
  );
};

Products.propTypes = {};

export default Products;
