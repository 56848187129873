import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    closeOrdersButton: {
      position: 'fixed',
      zIndex: 10,
      bottom: 20,
      right: 20,
    },
    row: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  };
});
