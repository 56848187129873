import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';

import { useStyle } from './styles';
import useCreateMessage from '../../hooks/useCreateMessage';

const CreateMessageForm = () => {
  const [text, setText] = useState('');
  const { t } = useTranslation();
  const classes = useStyle();
  const { load } = useCreateMessage();

  const handleOnChange = ({ target }) => {
    setText(target.value);
  };

  const handleOnSubmit = () => {
    load({ text });
    setText('');
  };

  const handleOnKeyPress = ({ key }) => {
    if (key === 'Enter' && text.length) {
      handleOnSubmit();
    }
  };

  return (
    <Box className={classes.row}>
      <Input
        label={t`Leave a message`}
        placeholder={t`Leave a message`}
        value={text}
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleOnSubmit} disabled={!text.length}>
              <SendIcon fontSize="small" color={!!text.length ? 'primary' : 'disabled'} />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};

CreateMessageForm.propTypes = {};

export default CreateMessageForm;
