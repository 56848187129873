import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import { removeMillisFromTime, Bill, Text } from '../../../../common';
import { useStyle } from './styles';

const UserOrders = (props) => {
  const { items, user } = props;
  const classes = useStyle();

  // console.log(
  //   items.reduce((total, { orders }) => {
  //     return orders.reduce((sum, { totalSalary }) => {
  //       return totalSalary ? totalSalary + sum : sum;
  //     }, total)
  //   }, 0)
  // );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          {items.map(({ date, orders }) => (
            <Card className={classes.dateCard} key={`${date}`}>
              <CardHeader title={<Text bold>{date}</Text>} className={classes.header} />
              <CardContent>
                {orders.map((order) => (
                  <Box key={`order-${order.id}`} className={classes.orderWrapper}>
                    <Box className={classes.row}>
                      <Text>
                        {removeMillisFromTime(order.startTime)} - {removeMillisFromTime(order.endTime)}
                      </Text>
                    </Box>
                    {order.note && (
                      <Box className={classes.row}>
                        <Text italic>{order.note}</Text>
                      </Box>
                    )}
                    <Bill
                      user={user}
                      services={order.services}
                      payments={order.payments}
                      totalSalary={order.totalSalary}
                      showTotal
                    />
                  </Box>
                ))}
                <Divider className={classes.row} />
                <Grid container>
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Text h3 bold>
                      {orders.reduce(
                        (acc, { payments }) => payments.reduce((acc1, { amount }) => acc1 + +amount, acc),
                        0
                      )}
                    </Text>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

UserOrders.propTypes = {
  user: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
};

export default UserOrders;
