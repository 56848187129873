import React, { useEffect, useState } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@material-ui/pickers';

const FormDatePicker = ({ field, form, className = '', ...props }) => {
  const [value, setValue] = useState(form.values[props.id]);
  const error = form.errors[props.id];

  useEffect(() => {
    if (value) {
      form.setFieldValue(props.id, value.format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line
  }, [value]);

  const handleOnChange = (date) => {
    date && setValue(date);
  };

  return (
    <>
      <FormControl fullWidth className={`${className}`}>
        <DatePicker
          {...props}
          {...field}
          format="YYYY-MM-DD"
          onChange={handleOnChange}
          initialFocusedDate={false}
          views={['year', 'month', 'date']}
          openTo="year"
          autoOk
        />
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default FormDatePicker;
