import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import { useProductsProvider } from '../../../providers/ProductsProvider';
import { useProductsCartProvider } from '../../../providers/ProductsCartProvider';
import EditProductForm from '../EditProductForm';
import QuantitySelector from '../../QuantitySelector';
import { ModalButton, Text } from '../../../../common';
import {
  AddToCartContainer,
  ProductCardContainer,
  ProductCardItem,
  ProductManagementContainer,
  QuantitySelectorContainer,
} from './atoms';

const ProductCard = (props) => {
  const { product } = props;
  const { id, name, cost, price, quantity, volume, unitOfMeasure } = product;
  const { isLoading, edit } = useProductsProvider();
  const { addItemToCart, cart } = useProductsCartProvider();
  const [changedQuantity, setChangedQuantity] = useState(quantity);
  const editModalRef = useRef();
  const { t } = useTranslation();

  const isQuantityChanged = changedQuantity !== quantity;

  const handleOnQuantityChange = useCallback((newQuantity) => {
    setChangedQuantity(newQuantity);
  }, []);

  const handleOnApplyQuantity = useCallback(() => {
    edit({
      id,
      body: { quantity: changedQuantity },
    });
  }, [changedQuantity, edit, id]);

  const handleOnSubmitEdit = useCallback(
    (values) => {
      edit({
        id,
        body: values,
        onSuccess: editModalRef.current && editModalRef.current.close(),
      });
    },
    [id, edit]
  );

  const handleOnAddToCart = useCallback(() => {
    addItemToCart({ id });
  }, [addItemToCart, id]);

  return useMemo(
    () => (
      <ProductCardContainer>
        <ProductCardItem>
          <Box>
            <Text h3 bold>
              {name}
            </Text>{' '}
            <Text ghost italic>
              {volume} {unitOfMeasure}
            </Text>
          </Box>
          <Text h3 upper newLine bold>
            {price} ₴
          </Text>
          <ProductManagementContainer>
            <QuantitySelectorContainer>
              <QuantitySelector initialQuantity={quantity} onChange={handleOnQuantityChange} />
              <Button
                color="primary"
                style={{
                  marginLeft: 10,
                  fontWeight: 'bold',
                  display: !isQuantityChanged ? 'none' : 'block',
                }}
                onClick={handleOnApplyQuantity}
              >
                {t`Apply`}
              </Button>
            </QuantitySelectorContainer>
            <ModalButton
              ref={editModalRef}
              buttonComponent={
                <Button
                  size="small"
                  color="primary"
                  aria-label="edit"
                  style={{
                    fontWeight: 'bold',
                    alignItems: 'center',
                  }}
                >
                  <EditIcon />
                </Button>
              }
              title={t`Edit supplier`}
            >
              <EditProductForm
                onSubmit={handleOnSubmitEdit}
                isLoading={isLoading}
                initialValues={{ name, cost, price, volume, unitOfMeasure }}
              />
            </ModalButton>
          </ProductManagementContainer>
          <AddToCartContainer>
            <Fab
              size="small"
              color="secondary"
              aria-label="add"
              onClick={handleOnAddToCart}
              disabled={quantity === cart[`${id}`] || quantity === 0}
            >
              <AddShoppingCartIcon /> {cart[`${id}`]}
            </Fab>
          </AddToCartContainer>
        </ProductCardItem>
      </ProductCardContainer>
    ),
    [
      cost,
      handleOnAddToCart,
      handleOnApplyQuantity,
      handleOnQuantityChange,
      handleOnSubmitEdit,
      id,
      isLoading,
      isQuantityChanged,
      name,
      price,
      quantity,
      t,
      unitOfMeasure,
      volume,
      cart,
    ]
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductCard;
