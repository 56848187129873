import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif;',
    ].join(','),
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.6rem',
    },
    h3: {
      fontSize: '1.2rem',
    },
    h4: {
      fontSize: '.9rem',
    },
    h5: {
      fontSize: '.7rem',
    },
    h6: {
      fontSize: '.4rem',
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: '#94d2ac',
      main: '#4a9967',
      dark: '#1f522e',
      contrastText: '#fff',
    },
    success: {
      lighter: '#b0ecc7',
      preLight: '#93c4a6',
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
    },
    error: {
      lighter: '#ffdada',
      preLight: '#d7b9b9',
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
    warning: {
      lighter: '#ffdaa8',
      preLight: '#d0b38b',
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
});

export default theme;
