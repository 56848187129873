import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import OrderServices from '../OrderServices';
import { Text } from '../../../../common';
import { useAppData } from '../../../../storage/providers';
import { useStyles } from './styles';

const ServicesSelector = (props) => {
  const { t } = useTranslation();
  const { data } = useAppData();
  const { departments } = data;
  const classes = useStyles();
  const { user, onSelect, selectedServices, onCancel, onRemove, onDecreaseAmount, onIncreaseAmount } = props;

  const serviceGroups = useMemo(() => {
    return departments
      .filter((_) => _.users.find(({ id }) => user.id === id))
      .map((_) => _.serviceGroups)
      .flat();
  }, [departments, user]);

  const handleServiceSelect = (service) => {
    onSelect(service);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={4} className={classes.item}>
        {serviceGroups.map(({ id, name, services, department }) => {
          return (
            <Paper key={`service-group-${id}`} className={classes.servicesCard}>
              <Table size="small">
                <TableHead style={{ backgroundColor: department.color }}>
                  <TableRow>
                    <TableCell>
                      <Text bold upper>
                        {name}
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services.map((service) => {
                    const isSelected = !!selectedServices.find((_) => +_.id === service.id);

                    return (
                      <TableRow
                        key={`client-${service.id}`}
                        className={clsx(classes.tableRow, isSelected && classes.tableRowSelected)}
                        onClick={() => handleServiceSelect(service)}
                      >
                        <TableCell>
                          <Text bold={isSelected}>{service.name}</Text>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          );
        })}
      </Grid>
      <Grid item xs={12} sm={8} className={classes.item}>
        <Box className={classes.row}>
          <OrderServices
            orderServices={selectedServices}
            onRemove={onRemove}
            onIncreaseAmount={onIncreaseAmount}
            onDecreaseAmount={onDecreaseAmount}
          />
        </Box>
        <Box className={classes.row}>
          <Button onClick={onCancel}>{t`Ok`}</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

ServicesSelector.propTypes = {
  user: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedServices: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
};

export default ServicesSelector;
