import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tablePaper: {
    width: 650,
    marginBottom: theme.spacing(3),
  },
  table: {
    margin: 0,
  },
  headerText: {
    textTransform: 'uppercase',
    fontFamily: 'FuturaBook',
  },
  editIcon: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  removeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  addButton: {
    width: 650,
  },
}));
