import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  data: {
    departments: null,
    clients: null,
    users: null,
    shifts: null,
  },
  state: STATE_INIT,
  states: {
    clientsLoading: false,
    servicesLoading: false,
  },
};

const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    resetAppData() {
      return { ...initialState };
    },
    loadAppData(state) {
      return { ...state, state: STATE_LOADING };
    },
    loadAppDataFailed(state) {
      return { ...state, state: STATE_FAILED };
    },
    loadAppDataSuccess(state, { payload: data }) {
      return { ...state, data, state: STATE_SUCCESS };
    },

    updateService(state, { payload: data }) {
      return {
        ...state,
        data: {
          ...state.data,
          departments: state.data.departments.map((department) => {
            const { serviceGroups } = department;

            return {
              ...department,
              serviceGroups: serviceGroups.map((serviceGroup) => {
                const { services } = serviceGroup;

                return {
                  ...serviceGroup,
                  services: services.map((service) => {
                    return data.id === service.id ? data : service;
                  }),
                };
              }),
            };
          }),
        },
      };
    },

    addService(state, { payload: data }) {
      const { service, serviceGroupId } = data;

      return {
        ...state,
        data: {
          ...state.data,
          departments: state.data.departments.map((department) => {
            const { serviceGroups } = department;

            return {
              ...department,
              serviceGroups: serviceGroups.map((serviceGroup) => {
                if (serviceGroup.id !== serviceGroupId) return serviceGroup;

                const { services } = serviceGroup;

                return {
                  ...serviceGroup,
                  services: [...services, service],
                };
              }),
            };
          }),
        },
      };
    },

    updateClients(state, { payload: clients }) {
      return {
        ...state,
        data: {
          ...state.data,
          clients,
        },
      };
    },
    setClientsLoading(state, { payload }) {
      return {
        ...state,
        states: {
          ...state.states,
          clientsLoading: payload,
        },
      };
    },
  },
});

export const {
  loadAppData,
  loadAppDataFailed,
  loadAppDataSuccess,
  resetAppData,
  updateService,
  addService,
  updateClients,
  setClientsLoading,
} = appDataSlice.actions;

export default appDataSlice.reducer;
