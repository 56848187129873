import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import MaterialMoneyIcon from '@material-ui/icons/Money';
import PaymentIcon from '@material-ui/icons/Payment';
import AtmIcon from '@material-ui/icons/Atm';

import { useStyle } from './styles';

export const MoneyType = {
  CASH: 'CASH',
  CARD: 'CARD',
  ATM: 'ATM',
};

const MoneyIcon = (props) => {
  const { style = {}, className = '', type } = props;
  const classes = useStyle();

  return useMemo(() => {
    switch (type) {
      case 'CASH':
        return <MaterialMoneyIcon className={`${classes.cashIcon} ${className}`} style={{ ...style }} />;
      case 'CARD':
        return <PaymentIcon className={`${classes.cardIcon} ${className}`} style={{ ...style }} />;
      case 'ATM':
        return <AtmIcon className={`${classes.atmIcon} ${className}`} style={{ ...style }} />;
      default:
        return null;
    }
  }, [type, style, className, classes]);
};

MoneyIcon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.oneOf([MoneyType.CASH, MoneyType.CARD, MoneyType.ATM]),
};

export default MoneyIcon;
