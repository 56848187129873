import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '..';
import { useStyle } from './styles';

const RowDivider = ({ text }) => {
  const classes = useStyle();

  return (
    <div className={classes.divider}>
      <Text h5 upper bold ghost className={classes.dividerText}>
        {text}
      </Text>
    </div>
  );
};

RowDivider.propTypes = {
  text: PropTypes.string.isRequired,
};

export default RowDivider;
