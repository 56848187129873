import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import {
  loadProductSellsSuccess,
  loadProductSellsFailed,
  loadProductSellsStarted,
  resetProductSells,
  STATE_LOADING,
} from '../reducers/sells';

const { REACT_APP_API_URL } = process.env;

const sellsUrl = `${REACT_APP_API_URL}/product/sell`;

const useSells = () => {
  const { get } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ sells }) => sells);
  const isLoading = state === STATE_LOADING;

  const start = useCallback(() => {
    dispatch(loadProductSellsStarted());
  }, [dispatch]);

  const set = useCallback(
    (sells) => {
      dispatch(loadProductSellsSuccess(sells));
    },
    [dispatch]
  );

  const handleError = useCallback(
    (error) => {
      console.log(error);
      dispatch(loadProductSellsFailed());
    },
    [dispatch]
  );

  const load = useCallback(
    ({ dateFrom, dateTo }) => {
      start();
      get({
        url: sellsUrl,
        queryParams: {
          dateFrom,
          dateTo,
        },
      })
        .then(set)
        .catch(handleError);
    },
    [get, handleError, set, start]
  );

  const reset = useCallback(() => {
    dispatch(resetProductSells());
  }, [dispatch]);

  return {
    items,
    isLoading,
    start,
    load,
    reset,
  };
};

export default useSells;
