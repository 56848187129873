import { useEffect } from 'react';

import { useUserStore } from '../../../../user';
import useAppData from '../../../providers/useAppData';
import { useUsers } from '../../../../user/hooks';

const Init = () => {
  const { userState } = useUserStore();
  const { load } = useAppData();
  const { loadAllUsers } = useUsers({});

  useEffect(() => {
    if (userState && userState.user) {
      load();
      loadAllUsers();
    }
    // eslint-disable-next-line
  }, [userState]);

  return null;
};

Init.propTypes = {};

export default Init;
