import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CreateIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import EditServiceGroupForm from '../EditServiceGroupForm';
import Services from '../Services';
import { ModalButton, Text } from '../../../../common';
import { useStyles } from './styles';

const ServiceGroupListItem = (props) => {
  const { handleCollapse, collapsed, serviceGroup } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ListItem style={{ backgroundColor: serviceGroup.department.color }}>
        <ListItemText
          primary={
            <Text upper bold h3>
              {serviceGroup.name}
            </Text>
          }
        />
        <ListItemIcon>
          <>
            <ModalButton
              buttonComponent={
                <Tooltip title={t`Edit`} placement="top">
                  <CreateIcon className={classes.editIcon} />
                </Tooltip>
              }
              title={t`Edit service group`}
              contentProps={{
                onSubmit: () => {},
              }}
            >
              <EditServiceGroupForm serviceGroupId={serviceGroup.id} />
            </ModalButton>
            <Tooltip title={t`Delete`} placement="top">
              <DeleteOutlineIcon className={classes.removeIcon} onClick={() => console.log('Delete')} />
            </Tooltip>
          </>
        </ListItemIcon>
      </ListItem>
      <ListItem button onClick={handleCollapse} className={classes.expandedButton}>
        {collapsed ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.servicesContainer}>
        <Services services={serviceGroup.services} serviceGroupId={serviceGroup.id} />
      </Collapse>
    </>
  );
};

ServiceGroupListItem.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  serviceGroup: PropTypes.object,
};

export default ServiceGroupListItem;
