import React from 'react';

import Box from '@material-ui/core/Box';

import { useStyle } from './styles';

const logoWhite = '/images/logo-white.svg';

const Landing = () => {
  const classes = useStyle();

  return (
    <Box className={classes.page}>
      <Box component="img" src={logoWhite} className={classes.logo} />
      <Box className={classes.layer} />
    </Box>
  );
};

Landing.propTypes = {};

export default Landing;
