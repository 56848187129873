import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';

import { useStyle } from './styles';
import { useEditOrder, useOrders } from '../../../../hooks';
import { ConfirmDialog } from '../../../../../common';

const IsClientNotified = (props) => {
  const { isClientNotified, orderId, style = {} } = props;
  const { editOrder } = useEditOrder();
  const { items: orders, set, start, fail, isLoading } = useOrders();
  const { t } = useTranslation();
  const classes = useStyle();

  const commonProps = {
    fontSize: 'small',
    className: clsx(classes.isNotifiedIcon, !isClientNotified && classes.notNotified),
  };

  const onStart = () => {
    start();
  };

  const onSuccess = (updatedOrder) => {
    set(orders.map((_) => (_.id === updatedOrder.id ? updatedOrder : _)));
  };

  const onError = (error) => {
    console.log(error);
    fail();
  };

  const handleOnConfirm = () => {
    if (!isClientNotified) {
      editOrder({
        id: orderId,
        params: { isClientNotified: true },
        onStart,
        onSuccess,
        onError,
      });
    }
  };

  if (isLoading) return <CircularProgress size={16} />;

  return isClientNotified ? (
    <NotificationsOffIcon {...commonProps} style={style} />
  ) : (
    <ConfirmDialog onConfirm={handleOnConfirm} text={t`Mark as notified?`} style={style}>
      <IconButton className={classes.buttonWrapper}>
        <NotificationsIcon {...commonProps} />
      </IconButton>
    </ConfirmDialog>
  );
};

IsClientNotified.propTypes = {
  IsClientNotified: PropTypes.bool,
  orderId: PropTypes.number,
  style: PropTypes.object,
};

export default IsClientNotified;
