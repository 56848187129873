import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import SideBar from '../SideBar';

const Drawer = () => {
  const { isOpen } = useSelector(({ sideBar }) => sideBar);

  return (
    <Box
      style={{
        width: 240,
        position: 'fixed',
        top: 64,
        bottom: 0,
        left: 0,
        transform: `translateX(${isOpen ? '0' : '-100'}%)`,
        display: 'relative',
        transition: 'transform .3s ease-in-out',
        zIndex: 3,
        backgroundColor: '#212121',
      }}
    >
      <SideBar />
    </Box>
  );
};

Drawer.propTypes = {};

export default Drawer;
