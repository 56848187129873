import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import Box from '@material-ui/core/Box';

const DepartmentsGraph = (props) => {
  const { totals } = props;

  return useMemo(() => {
    if (!totals) return null;

    return (
      <Box>
        <BarChart
          width={500}
          height={300}
          data={totals.map(({ firstName, lastName, ...total }) => ({
            name: `${firstName} ${lastName}`,
            ...total,
          }))}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis hide dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="percentage" fill="#1f522e" />
        </BarChart>
      </Box>
    );
  }, [totals]);
};

DepartmentsGraph.propTypes = {
  totals: PropTypes.array,
};

export default DepartmentsGraph;
