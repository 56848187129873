import moment from 'moment';

import { useEditOrder, useOrders } from '../../../../../hooks';
import { useCallback } from 'react';

const useOrderChangeHandler = (props) => {
  const { orderId, onComplete } = props;
  const { items: orders, set, start, fail, isLoading } = useOrders();
  const { editOrder } = useEditOrder();

  const onStart = useCallback(() => {
    start();
  }, [start]);

  const onError = useCallback(
    (error) => {
      console.log(error);
      fail();
    },
    [fail]
  );

  const onSuccess = useCallback(
    (updatedOrder) => {
      set(orders.map((_) => (_.id === updatedOrder.id ? updatedOrder : _)));
      onComplete && onComplete();
    },
    [orders, onComplete, set]
  );

  const handleOnOrderChange = useCallback(
    ({ userId, startTime, endTime, note }) => {
      editOrder({
        id: orderId,
        params: {
          userId,
          startTime: startTime && moment(startTime).format('H:mm'),
          endTime: endTime && moment(endTime).format('H:mm'),
          note,
        },
        onStart,
        onError,
        onSuccess,
      });
    },
    [onStart, onError, onSuccess, editOrder, orderId]
  );

  return { handleOnOrderChange, loading: isLoading };
};

export default useOrderChangeHandler;
