import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

import Header from './Header';
import Drawer from './Drawer';
import Content from './Content';
import { useUserStore } from '../../../../user';
import { setMenu } from '../../../reducers/sideBar';

const Layout = (props) => {
  const { children, menu } = props;
  const { userState } = useUserStore();
  const { user } = userState;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(
        setMenu(
          menu
            .filter((_) => !_.access || _.access.includes(user.role.name))
            .map((_) => ({
              ..._,
              subItems: _.subItems.filter((_) => !_.access || _.access.includes(user.role.name)),
            }))
        )
      );
    }
  }, [menu, user, dispatch]);

  return useMemo(() => {
    if (!/(\/admin).?/.test(pathname)) {
      return null;
    }

    return (
      <Box>
        <CssBaseline />
        {user && <Header />}
        <main style={{ display: 'flex' }}>
          {user && user.firstName !== 'Ghost' && <Drawer />}
          <Content>{children}</Content>
        </main>
      </Box>
    );
  }, [children, pathname, user]);
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.array.isRequired,
};

export default Layout;
