import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';

import PaymentsField from '../../../order/components/widgets/PaymentsField';
import { useProductsCartProvider } from '../../providers/ProductsCartProvider';
import { useProductsProvider } from '../../providers/ProductsProvider';
import { LoadingButton, Text } from '../../../common';
import { useUserStore } from '../../../user';
import {
  BuyerSelectorContainer,
  CartContainer,
  CartItem,
  CartItemNameContainer,
  CartItemPriceContainer,
  CartItemQuantityContainer,
  CartItemsContainer,
  SellerSelectorContainer,
} from './atoms';

const ProductsCart = () => {
  const { cart, emptyCart } = useProductsCartProvider();
  const { isLoading, products, sellProducts } = useProductsProvider();
  const { t } = useTranslation();
  const { userState } = useUserStore();
  const { user } = userState;
  const [seller, setSeller] = useState(user);
  const [buyer, setBuyer] = useState(null);
  const { items: users } = useSelector(({ users }) => users);
  const [{ payments, isPaymentsMatch }, setIsPaymentsMatch] = useState(true);

  const activeUsers = useMemo(() => users.filter((_) => _.status === 1 && !/ghost/i.test(_.lastName)), [users]);

  const [cartItems, total] = useMemo(() => {
    const cartItems = Object.keys(cart).map((productId) => ({
      product: products.find((_) => `${_.id}` === `${productId}`),
      quantity: cart[`${productId}`],
    }));

    const total = cartItems.reduce((acc, { product, quantity }) => {
      const price = buyer ? product.cost : product.price;
      return acc + quantity * price;
    }, 0);

    return [cartItems, total];
  }, [products, cart, buyer]);

  const handleOnSellProducts = useCallback(() => {
    if (seller && isPaymentsMatch) {
      sellProducts({
        body: {
          productIds: cartItems.map(({ product, quantity }) => ({ id: product.id, quantity })),
          sellerId: seller.id,
          buyerId: buyer?.id,
          payments,
        },
        onSuccess: emptyCart,
      });
    }
  }, [cartItems, emptyCart, sellProducts, seller, buyer, isPaymentsMatch, payments]);

  const handleOnSupplierChange = useCallback(
    (_, value) => {
      setSeller(value);
    },
    [setSeller]
  );

  const handleOnBuyerChange = useCallback(
    (_, value) => {
      setBuyer(value);
    },
    [setBuyer]
  );

  const handleOnPaymentsChange = useCallback(
    (payments, isPaymentsMatch) => {
      setIsPaymentsMatch({ payments, isPaymentsMatch });
    },
    [setIsPaymentsMatch]
  );

  return useMemo(
    () => (
      <CartContainer>
        <CartItemsContainer>
          {cartItems.map(({ product, quantity }) => {
            const { id, name, price } = product;

            return (
              <CartItem key={`cart-product-${id}`}>
                <CartItemNameContainer>
                  <Text>{name}</Text>
                </CartItemNameContainer>
                <CartItemQuantityContainer>
                  <Text>x{quantity}</Text>
                </CartItemQuantityContainer>
                <CartItemPriceContainer>
                  <Text>{price}</Text>
                </CartItemPriceContainer>
              </CartItem>
            );
          })}
        </CartItemsContainer>

        <SellerSelectorContainer>
          <Autocomplete
            options={activeUsers}
            getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
            renderOption={({ firstName, lastName }) => `${firstName} ${lastName}`}
            onChange={handleOnSupplierChange}
            defaultValue={user}
            renderInput={(params) => <TextField {...params} label={t`Who sold`} variant="outlined" />}
          />
        </SellerSelectorContainer>

        <BuyerSelectorContainer>
          <Autocomplete
            options={activeUsers}
            getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
            renderOption={({ firstName, lastName }) => `${firstName} ${lastName}`}
            onChange={handleOnBuyerChange}
            renderInput={(params) => <TextField {...params} label={t`Sell by cost`} variant="outlined" />}
          />
        </BuyerSelectorContainer>

        <PaymentsField key={`pf-${total}`} onChange={handleOnPaymentsChange} initialTotal={total} />

        <LoadingButton
          fullWidth
          color="primary"
          variant="contained"
          disabled={isLoading || !seller || !isPaymentsMatch}
          onClick={handleOnSellProducts}
          loading={isLoading}
          style={{ marginBottom: 10 }}
        >
          {t(`Pay {{total}} UAH and close`, { total })}
        </LoadingButton>
        <Button fullWidth onClick={emptyCart}>
          {t`Clear`}
        </Button>
      </CartContainer>
    ),
    [
      cartItems,
      t,
      total,
      handleOnSellProducts,
      isLoading,
      seller,
      user,
      activeUsers,
      handleOnSupplierChange,
      handleOnBuyerChange,
      emptyCart,
      handleOnPaymentsChange,
      isPaymentsMatch,
    ]
  );
};

ProductsCart.propTypes = {};

export default ProductsCart;
