import React from 'react';
import PropTypes from 'prop-types';

import { ServiceGroupContext } from '.';
import { useServiceGroups } from '../hooks';

const ServiceGroupsProvider = (props) => {
  const { children, departmentIds } = props;
  const { loading, setLoading, setServiceGroups, serviceGroups } = useServiceGroups(departmentIds);

  return (
    <ServiceGroupContext.Provider value={{ loading, setLoading, setServiceGroups, serviceGroups }}>
      {children}
    </ServiceGroupContext.Provider>
  );
};

ServiceGroupsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  departmentIds: PropTypes.array,
};

export default ServiceGroupsProvider;
