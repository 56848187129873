import React, { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import idx from 'idx';

import { useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { useStyle } from './styles';
import { ModalButton, Skeleton } from '../../../../common';
import CreateClientForm from '../CreateClientForm';
import { ClientInfo, ClientCard } from '..';

const numberOfItems = 30;
let timeoutId = undefined;

const Clients = (props) => {
  const {
    isScrolled,
    setSelectedClient,
    selectedClient,
    clients,
    load,
    updateClient,
    isLoading
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState(null);
  const [offset] = useState(0);

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (selectedClient) {
      setSelectedClient(clients.find((_) => _.id === selectedClient.id));
    }
    // eslint-disable-next-line
  }, [clients]);

  useEffect(() => {
    load({
      offset,
      limit: numberOfItems,
      searchText,
    });
    // eslint-disable-next-line
  }, [numberOfItems, offset, searchText]);

  const handleClientSelect = useCallback(
    (client) => () => {
      setSelectedClient(client);
    },
    [setSelectedClient]
  );

  const handleClientSearch = useCallback(({ target }) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setSearchText(target.value);
    }, 300);
  }, []);

  return useMemo(() => {
    if (!clients) return <Skeleton rows={10} />;

    return (
      <Grid container>
        <Grid item xs={12} className={classes.stickyContainer}>
          <ModalButton
            buttonComponent={
              <Button color="primary" className={clsx(classes.addButton, isScrolled && classes.addButtonScrolled)}>
                {t`Create client`}
              </Button>
            }
            title={t`Create client`}
            contentProps={{
              onSubmit: () => {},
            }}
          >
            <CreateClientForm />
          </ModalButton>
          <TextField
            id="search"
            label={t`Search`}
            variant="outlined"
            className={classes.searchInput}
            onChange={handleClientSearch}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          {clients.map((client) => {
            const isClientSelected = client.id === idx(selectedClient, (_) => _.id);

            return (
              <Fragment key={`client-${client.id}`}>
                <ClientCard client={client} isActive={isClientSelected} onClick={handleClientSelect(client)} />
                {isClientSelected && isMobile && <ClientInfo updateClient={updateClient} client={selectedClient} />}
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    );
  }, [
    clients,
    classes.addButton,
    classes.addButtonScrolled,
    classes.searchInput,
    classes.stickyContainer,
    handleClientSearch,
    handleClientSelect,
    isMobile,
    isScrolled,
    selectedClient,
    t,
    updateClient,
    isLoading
  ]);
};

Clients.propTypes = {
  isScrolled: PropTypes.bool.isRequired,
  setSelectedClient: PropTypes.func.isRequired,
  selectedClient: PropTypes.object,
  isLoading: PropTypes.bool
};

export default Clients;
