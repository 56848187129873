import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  loadingIcon: {
    marginRight: theme.spacing(1),
  },
}));
