import React from 'react';
import moment from 'moment';

import { DayView } from '@devexpress/dx-react-scheduler-material-ui';

import { Text } from '../../../../common';

const windows = ['09:00', '11:00', '13:00', '15:00', '17:00', '19:00', '21:00'];

const formatDate = (date) => {
  const time = moment(date).format('HH:mm');
  const isWindow = windows.includes(time);

  return (
    <Text h5={!isWindow} bold={isWindow}>
      {time}
    </Text>
  );
};

const TimeScaleLabel = (props) => {
  return <DayView.TimeScaleLabel {...props} formatDate={formatDate} />;
};

TimeScaleLabel.propTypes = {};

export default TimeScaleLabel;
