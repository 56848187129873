import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
    },
    listItemText: {
      margin: 0,
    },
    totalWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
    },
    paymentType: {
      marginLeft: theme.spacing(2),
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    changeMasterButton: {
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover.complete': {
        color: theme.palette.primary.light,
      },
    },
    actionButton: {
      marginLeft: theme.spacing(2),
      color: theme.palette.background.paper,

      '&.complete': {
        backgroundColor: theme.palette.success.main,
      },
      '&:hover.complete': {
        backgroundColor: theme.palette.success.light,
      },

      '&.edit': {
        backgroundColor: theme.palette.text.primary,
      },
      '&:hover.edit': {
        backgroundColor: theme.palette.text.disabled,
      },

      '&.decline': {
        backgroundColor: theme.palette.warning.main,
      },
      '&:hover.decline': {
        backgroundColor: theme.palette.warning.light,
      },

      '&.fail': {
        backgroundColor: theme.palette.error.main,
      },
      '&:hover.fail': {
        backgroundColor: theme.palette.error.light,
      },
    },

    // OrderStatusIndicator
    indicator: {
      width: 24,
      height: 24,
      borderRadius: '50%',
    },
    createdOrder: {
      backgroundColor: theme.palette.warning.light,
      '&:hover': {
        backgroundColor: theme.palette.warning.main,
      },
    },
    completedOrder: {
      backgroundColor: theme.palette.success.light,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    declinedOrder: {
      backgroundColor: theme.palette.action.disabled,
      '&:hover': {
        backgroundColor: theme.palette.action.disabled,
      },
    },
    failedOrder: {
      backgroundColor: theme.palette.error.light,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  };
});
