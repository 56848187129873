import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Departments, WorkingPlaces } from '../../widgets';
import { DepartmentsProvider, WorkingPlacesProvider } from '../../../providers';
import { Page } from '../../../../common';

const useDepartmentSelect = () => {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);

  return { selectedDepartmentId, setSelectedDepartmentId };
};

const EditDepartment = () => {
  const { selectedDepartmentId, setSelectedDepartmentId } = useDepartmentSelect();

  return (
    <DepartmentsProvider>
      <WorkingPlacesProvider>
        <Container disableGutters>
          <Page>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Departments
                  setSelectedDepartmentId={setSelectedDepartmentId}
                  selectedDepartmentId={selectedDepartmentId}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <WorkingPlaces selectedDepartmentId={selectedDepartmentId} />
              </Grid>
            </Grid>
          </Page>
        </Container>
      </WorkingPlacesProvider>
    </DepartmentsProvider>
  );
};

EditDepartment.propTypes = {};

export default EditDepartment;
