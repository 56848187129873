import philaTuna from '../../../../../assets/images/inarifood/rolls/phila_tuna.webp';
import segu from '../../../../../assets/images/inarifood/rolls/segu.webp';
import satori from '../../../../../assets/images/inarifood/rolls/satori.webp';
import tobirama from '../../../../../assets/images/inarifood/rolls/tobirama.webp';
import kasama from '../../../../../assets/images/inarifood/rolls/kasama.webp';
import kayo from '../../../../../assets/images/inarifood/rolls/kayo.webp';
import rainbowDragon from '../../../../../assets/images/inarifood/rolls/rainbow_dragon.webp';

export default [
  {
    title: 'Филадельфия тунец',
    ingredients: ['тунец', 'авокадо', 'сыр сливочный', 'огурец', 'рис', 'нори'],
    image: philaTuna,
    price: 225,
  },
  {
    title: 'Сёгу',
    ingredients: ['тунец', 'форель', 'авокадо', 'окунь', 'сыр сливочный', 'икра тобико зелёная', 'нори'],
    image: segu,
    price: 345,
  },
  {
    title: 'Сатори',
    ingredients: [
      'форель',
      'тунец',
      'авокадо',
      'икра красная',
      'сыр сливочный',
      'огурец',
      'красная икра тобико',
      'нори',
    ],
    image: satori,
    price: 345,
  },
  {
    title: 'Тобирама',
    ingredients: ['форель', 'тунец', 'креветка тигровая', 'авокадо', 'зелёная икра тобико', 'икра красная', 'нори'],
    image: tobirama,
    price: 278,
  },
  {
    title: 'Казама',
    ingredients: ['форель', 'креветка тигровая', 'тунец', 'сыр сливочный', 'красная икра тобико', 'авокадо', 'нори'],
    image: kasama,
    price: 330,
  },
  {
    title: 'Каё',
    ingredients: [
      'кляр - форель',
      'тунец',
      'креветка тигровая',
      'сыр сливочный',
      'икра тобико',
      'лист салата',
      'рис',
      'нори',
    ],
    image: kayo,
    price: 225,
  },
  {
    title: 'Радужный дракон',
    ingredients: [
      'форель',
      'угорь',
      'окунь',
      'тунец',
      'авокадо',
      'сыр сливочный',
      'огурец',
      'рис',
      'красная икра тобико',
      'нори',
    ],
    image: rainbowDragon,
    price: 278,
  },
];
