import React from 'react';

import PeopleIcon from '@material-ui/icons/People';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';

import i18n from '../../i18n';

const menuItems = [
  {
    name: i18n.t`User`,
    icon: <PeopleIcon />,
    subItems: [
      {
        name: i18n.t`Users list`,
        path: '/admin/users',
        icon: <BuildOutlinedIcon fontSize="small" />,
      },
      {
        name: i18n.t`Shifts`,
        path: '/admin/users/shift',
        icon: <ScheduleIcon fontSize="small" />,
      },
    ],
    access: ['superadmin', 'manager'],
  },
];

export default menuItems;
