import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import PaymentsField from '../PaymentsField';
import ClientWidget from '../ClientWidget';
import { Bill, Text } from '../../../../common';
import { useStyle } from './styles';

const OrderToClose = (props) => {
  const { id, services, payments, client, onChange } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const [note, setNote] = useState('');
  const [error, setError] = useState({});
  const [values, setValues] = useState(payments);

  const price = useMemo(() => services.reduce((acc, { price, amount }) => acc + +price * amount, 0), [services]);

  useEffect(() => {
    const sum = values.reduce((sum, { amount }) => sum + +amount, 0);

    const isPaymentsEquals = sum === price;
    const isTotalLessThenPayments = sum > price;
    const isNoteEmpty = !note.length;
    let isValid;

    if (isTotalLessThenPayments && !error.payments) {
      setError({ payments: t`Total cannot be lass then payments` });
      isValid = false;
    } else if (!isPaymentsEquals && isNoteEmpty && !error.note) {
      setError({ note: t`Services prices not matches total price, specify the reason` });
      isValid = false;
    } else if (isPaymentsEquals || (!isTotalLessThenPayments && !isNoteEmpty)) {
      isValid = true;
      setError({});
    }

    onChange({ id, values, note, isValid });
    // eslint-disable-next-line
  }, [values, note, id, price]);

  const handleOnPaymentChange = (values) => {
    setValues(values);
  };

  const handleOnNoteChange = ({ target }) => {
    setNote(target.value);
  };

  return (
    <Card style={{ padding: 10, marginBottom: 20, width: '100%' }}>
      <Grid container direction="column">
        <Grid container alignItems="center" style={{ marginBottom: 10 }}>
          <Text bold newLine pushRight>
            {`#${id}`}
          </Text>
          <ClientWidget client={client} />
        </Grid>
        <Bill services={services} />
        <Box className={classes.row}>
          <FormControl fullWidth>
            <TextField placeholder={t`Note`} onChange={handleOnNoteChange} error={!!error.note} />
            {!!error.note && <FormHelperText error>{error.note}</FormHelperText>}
          </FormControl>
        </Box>
        <Box className={classes.row}>
          <PaymentsField onChange={handleOnPaymentChange} initialTotal={price} />
          {!!error.payments && <FormHelperText error>{error.payments}</FormHelperText>}
        </Box>
      </Grid>
    </Card>
  );
};

OrderToClose.propTypes = {
  id: PropTypes.number.isRequired,
  services: PropTypes.array.isRequired,
  payments: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

OrderToClose.defaultProps = {
  payments: [],
};

export default OrderToClose;
