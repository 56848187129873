import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { object } from 'yup';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import { Text } from '../../../../common';
import { useStyle } from './styles';

const Editable = (props) => {
  const { t } = useTranslation();
  const isUnmounted = useRef(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyle();
  const { children, field, initialValues, validationSchema = {}, onSubmit, disableDashing } = props;

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const handleClick = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    e && e.preventDefault && e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSubmit = (values) => {
    onSubmit(values);
    if (!isUnmounted.current) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Text h4 onClick={handleClick} className={clsx(classes.text, disableDashing && classes.noDashing)}>
        {children || t`No data`}
      </Text>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.input}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={object().shape(validationSchema)}
          >
            {(props) => {
              const { dirty, isValid } = props;

              return (
                <Form>
                  {field}
                  <Grid container justify="flex-end" className={classes.submitBtn}>
                    <Button type="submit" variant="contained" disabled={!dirty || !isValid}>
                      {t`Submit`}
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Popover>
    </>
  );
};

Editable.propTypes = {
  children: PropTypes.node,
  field: PropTypes.node.isRequired,
  validationSchema: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  disableDashing: PropTypes.bool,
};

export default Editable;
