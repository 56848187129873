import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import { ChipsWrapper, ChipWrapper } from './atoms';

const FormSelect = ({ field, form, className = '', label, children, chip, ...props }) => {
  const { multiple } = props;

  const renderChip = (selected) => {
    return (
      <ChipsWrapper>
        {selected.map((value) => (
          <ChipWrapper key={value} label={chip.find(({ key }) => key === value).name} />
        ))}
      </ChipsWrapper>
    );
  };

  const error = form.errors[props.id];

  return (
    <FormControl fullWidth className={`${className}`}>
      <InputLabel htmlFor="age-label-placeholder">{label}</InputLabel>
      <Select {...field} {...props} renderValue={multiple && chip ? renderChip : null}>
        {children}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;
