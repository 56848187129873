import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, number } from 'yup';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

import { FormSelect, FormTimePicker } from '../../../../form';
import { useCreateShift, useShifts } from '../../../hooks';

const validationSchema = object().shape({
  startTime: object().required('Required'),
  endTime: object().required('Required'),
  workingPlaceId: number().required('Required'),
});

const useStyle = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  time: {
    display: 'flex',
  },
}));

const CreateShiftForm = (props) => {
  const { initialValues, onSubmit, userId, date, freeWorkingPlaces } = props;
  const { t } = useTranslation();
  const classes = useStyle();
  const { createShift } = useCreateShift();
  const { isLoading, items: shifts, start, set, fail } = useShifts();

  const onStart = () => {
    start();
  };

  const onSuccess = (newShift) => {
    set([...shifts, newShift]);
    onSubmit();
  };

  const onError = () => {
    fail();
  };

  const handleOnSubmit = ({ startTime, endTime, workingPlaceId }) => {
    const params = {
      date: date.format('YYYY-MM-DD'),
      startTime: startTime.format('HH:mm'),
      endTime: endTime.format('HH:mm'),
      workingPlaceId,
    };

    createShift({
      id: userId,
      params,
      onStart,
      onSuccess,
      onError,
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {(props) => {
        const { isValid } = props;

        return (
          <Form>
            <Box className={clsx(classes.row, classes.time)}>
              <Field
                id="startTime"
                name="startTime"
                label={t`Start time`}
                placeholder={t`Start time`}
                minutesStep={5}
                component={FormTimePicker}
              />
              <Field
                id="endTime"
                name="endTime"
                label={t`End time`}
                placeholder={t`End time`}
                minutesStep={5}
                component={FormTimePicker}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="workingPlaceId"
                name="workingPlaceId"
                label={t`Working place`}
                placeholder={t`Working place`}
                component={FormSelect}
              >
                {freeWorkingPlaces.map(({ id, name, department }) => (
                  <MenuItem key={`wp-${id}`} value={id}>
                    {name}
                    <Chip
                      key={`dep-${department.id}`}
                      size="small"
                      label={department.name}
                      style={{
                        border: `2px solid ${department.color}`,
                        backgroundColor: 'transparent',
                        marginLeft: 10,
                      }}
                    />
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <Box className={classes.row}>
              <Button type="submit" variant="contained" disabled={!isValid || isLoading} fullWidth>
                {isLoading && <CircularProgress size={16} className={classes.loadingIcon} />}
                {t`Submit`}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateShiftForm.propTypes = {
  initialValues: PropTypes.shape({
    startTime: PropTypes.any,
    endTime: PropTypes.any,
  }),
  onSubmit: PropTypes.func,
};

export default CreateShiftForm;
