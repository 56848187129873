import juraya from '../../../../../assets/images/inarifood/rolls/juraya.webp';
import nedj from '../../../../../assets/images/inarifood/rolls/nedj.webp';
import hokage from '../../../../../assets/images/inarifood/rolls/hokage.webp';
import makiForel from '../../../../../assets/images/inarifood/rolls/maki_forel.webp';
import makiShrimp from '../../../../../assets/images/inarifood/rolls/maki_shrimp.webp';
import makiCocumber from '../../../../../assets/images/inarifood/rolls/maki_cocumber.webp';
import makiAvocado from '../../../../../assets/images/inarifood/rolls/maki_avocado.webp';

export default [
  {
    title: 'Джирайа',
    ingredients: ['авокадо', 'лист салата', 'огурец', 'помидор', 'кунжут', 'рис', 'нори'],
    image: juraya,
    price: 69,
  },
  {
    title: 'Недж',
    ingredients: ['форель', 'снежный краб', 'спайси соус', 'авокадо', 'нори в панировке'],
    image: nedj,
    price: 295,
  },
  {
    title: 'Хокаге',
    ingredients: ['форель', 'тунец', 'окунь', 'авокадо', 'чёрная икра тобико', 'нори'],
    image: hokage,
    price: 245,
  },
  {
    title: 'Маки форель',
    ingredients: ['форель', 'рис', 'нори'],
    image: makiForel,
    price: 79,
  },
  {
    title: 'Маки креветка',
    ingredients: ['креветка тигровая', 'икра тобико', 'рис', 'нори'],
    image: makiShrimp,
    price: 78,
  },
  {
    title: 'Маки огурец',
    ingredients: ['огурец', 'рис', 'нори'],
    image: makiCocumber,
    price: 24,
  },
  {
    title: 'Маки авокадо',
    ingredients: ['авокадо', 'рис', 'нори'],
    image: makiAvocado,
    price: 49,
  },
];
