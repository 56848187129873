import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import {
  loadSuppliersSuccess,
  loadSuppliersFailed,
  loadSuppliersStarted,
  resetSuppliers,
  STATE_LOADING,
} from '../reducers/suppliers';

const { REACT_APP_API_URL } = process.env;

const suppliersUrl = `${REACT_APP_API_URL}/product/supplier`;

const useSuppliers = () => {
  const { get, patch, post } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ suppliers }) => suppliers);
  const isLoading = state === STATE_LOADING;

  const start = useCallback(() => {
    dispatch(loadSuppliersStarted());
  }, [dispatch]);

  const set = useCallback(
    (suppliers) => {
      dispatch(loadSuppliersSuccess(suppliers));
    },
    [dispatch]
  );

  const fail = useCallback(() => {
    dispatch(loadSuppliersFailed());
  }, [dispatch]);

  const handleError = useCallback(
    (error) => {
      console.log(error);
      dispatch(loadSuppliersFailed());
    },
    [dispatch]
  );

  const load = useCallback(() => {
    start();
    get({
      url: suppliersUrl,
    })
      .then(set)
      .catch(handleError);
  }, [get, handleError, set, start]);

  const create = useCallback(
    ({ body, onSuccess }) => {
      start();
      patch({
        url: suppliersUrl,
        body,
      })
        .then(([newItem]) => {
          set([...items, newItem]);
          onSuccess && onSuccess();
        })
        .catch(handleError);
    },
    [handleError, items, patch, set, start]
  );

  const edit = useCallback(
    ({ id, body, onSuccess }) => {
      start();
      post({
        url: `${suppliersUrl}/${id}`,
        body,
      })
        .then(([editedItem]) => {
          set(items.map((_) => (_.id === editedItem.id ? editedItem : _)));
          onSuccess && onSuccess();
        })
        .catch(handleError);
    },
    [handleError, items, post, set, start]
  );

  const reset = useCallback(() => {
    dispatch(resetSuppliers());
  }, [dispatch]);

  return {
    items,
    isLoading,
    start,
    load,
    set,
    fail,
    reset,
    create,
    edit,
  };
};

export default useSuppliers;
