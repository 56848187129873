import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useRequest } from '../../network';
import { loadUsersFailed, loadUsersStarted, loadUsersSuccess } from '../reducers/users';

const { REACT_APP_API_URL } = process.env;
const userUrl = `${REACT_APP_API_URL}/user`;

const useUsers = ({ loadOnInit, departmentId, date }) => {
  const { get } = useRequest();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSuccess = useCallback((users) => {
    setUsers(users);
    setLoading(false);
  }, []);

  const handleError = useCallback((error) => {
    console.log(error);
    setLoading(false);
  }, []);

  const loadUsers = useCallback(
    ({ departmentId, date }) => {
      setLoading(true);

      const departmentQueryParam = departmentId ? { departmentId } : {};

      const dateQueryParam = date ? { date } : {};

      const queryParams = {
        queryParams: {
          ...departmentQueryParam,
          ...dateQueryParam,
        },
      };

      get({
        url: userUrl,
        ...queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    },
    [get, handleError, handleSuccess]
  );

  const loadAllUsers = useCallback(() => {
    dispatch(loadUsersStarted());
    get({ url: userUrl })
      .then((result) => {
        dispatch(loadUsersSuccess(result));
      })
      .catch(() => {
        dispatch(loadUsersFailed());
      });
  }, [dispatch, get]);

  useEffect(() => {
    loadOnInit && loadUsers({ departmentId, date });
    // eslint-disable-next-line
  }, []);

  return {
    users,
    setUsers,
    loading,
    setLoading,
    loadUsers,
    loadAllUsers,
  };
};

export default useUsers;
