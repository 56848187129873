import React from 'react';
import PropTypes from 'prop-types';

import StaticView from './StaticView';
import DynamicView from './DynamicView';

const Bill = (props) => {
  const { services, dynamic } = props;

  if (!services || !services.length) return null;

  return dynamic ? <DynamicView {...props} /> : <StaticView {...props} />;
};

Bill.propTypes = {
  children: PropTypes.array,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      serviceGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ),
  payments: PropTypes.array,
  showTotal: PropTypes.bool,
  totalSalary: PropTypes.number,
  user: PropTypes.object,
  dynamic: PropTypes.bool,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  onRemove: PropTypes.func,
};

Bill.defaultProps = {
  payments: [],
  user: {},
};

export default Bill;
