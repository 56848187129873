import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSuppliers } from '../hooks';

export const SuppliersContext = createContext({});

const SuppliersProvider = (props) => {
  const { children } = props;
  const { isLoading, items: suppliers, load, create, edit } = useSuppliers();

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <SuppliersContext.Provider value={{ isLoading, suppliers, create, edit }}>{children}</SuppliersContext.Provider>
  );
};

SuppliersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SuppliersProvider;

export const useSuppliersProvider = () => {
  const { isLoading, suppliers, create, edit } = useContext(SuppliersContext);

  return { isLoading, suppliers, create, edit };
};
