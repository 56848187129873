import React from 'react';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

import i18n from '../../i18n';

const menuItems = [
  {
    name: i18n.t`Service`,
    icon: <ListAltOutlinedIcon />,
    subItems: [
      {
        name: i18n.t`Edit`,
        path: '/admin/service/edit',
        icon: <BuildOutlinedIcon fontSize="small" />,
      },
    ],
    access: ['superadmin', 'manager'],
  },
];

export default menuItems;
