import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editShiftUrl = `${REACT_APP_API_URL}/user/shift`;

const useEditShift = () => {
  const { patch } = useRequest();

  const editShift = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    patch({
      url: `${editShiftUrl}/${id}`,
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { editShift };
};

export default useEditShift;
