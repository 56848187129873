import React from 'react';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SortIcon from '@material-ui/icons/Sort';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

import i18n from '../../i18n';

const menuItems = [
  {
    name: i18n.t`Statistic`,
    icon: <EqualizerIcon />,
    subItems: [
      {
        name: i18n.t`Total by master`,
        path: '/admin/statistic/total-by-master',
        icon: <SortIcon fontSize="small" />,
      },
      {
        name: i18n.t`Month dynamic`,
        path: '/admin/statistic/month-dynamic',
        icon: <TrendingUpIcon fontSize="small" />,
      },
      {
        name: i18n.t`Clients dynamic`,
        path: '/admin/statistic/clients-dynamic',
        icon: <GroupAddIcon fontSize="small" />,
      },
      {
        name: i18n.t`Not returned clients`,
        path: '/admin/statistic/not-returned-clients',
        icon: <PersonAddDisabledIcon fontSize="small" />,
      },
    ],
    access: ['superadmin'],
  },
];

export default menuItems;
