import styled from 'styled-components';

export const CartContainer = styled.div`
  width: 100%;
`;

export const CartItemsContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-top: 1px solid #cecece;
  border-left: 1px solid #cecece;
`;

export const SellerSelectorContainer = styled.div`
  margin-bottom: 20px;
`;

export const BuyerSelectorContainer = styled.div`
  margin-bottom: 20px;
`;

export const CartItem = styled.div`
  width: 100%;
  display: flex;
`;

export const CartItemNameContainer = styled.div`
  width: 40%;
  padding: 10px;
  border-bottom: 1px solid #cecece;
  border-right: 1px solid #cecece;
`;

export const CartItemQuantityContainer = styled.div`
  width: 30%;
  padding: 10px;
  border-bottom: 1px solid #cecece;
  border-right: 1px solid #cecece;
`;

export const CartItemPriceContainer = styled.div`
  width: 30%;
  padding: 10px;
  border-bottom: 1px solid #cecece;
  border-right: 1px solid #cecece;
`;
