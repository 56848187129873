import futoMakForel from '../../../../../assets/images/inarifood/rolls/futo_mak_forel.webp';
import senBon from '../../../../../assets/images/inarifood/rolls/sen_bon.webp';
import californiaForel from '../../../../../assets/images/inarifood/rolls/california_forel.webp';
import redDragon from '../../../../../assets/images/inarifood/rolls/red_dragon.webp';
import chidori from '../../../../../assets/images/inarifood/rolls/chidori.webp';
import inYan from '../../../../../assets/images/inarifood/rolls/in_yan.jpeg';
import akita from '../../../../../assets/images/inarifood/rolls/akita.webp';
import spicyRoll from '../../../../../assets/images/inarifood/rolls/spicy_roll.webp';
import califormiaCheseForel from '../../../../../assets/images/inarifood/rolls/califormia_chese_forel.webp';

export default [
  {
    title: 'Футо мак с форелью',
    ingredients: ['форель', 'огурец', 'лист салата', 'сыр сливочный', 'икра тобико красная', 'рис', 'нори'],
    image: futoMakForel,
    price: 155,
  },
  {
    title: 'Сен Бон',
    ingredients: [
      'креветка тигровая',
      'угорь',
      'форель',
      'снежный краб',
      'сладкий перец',
      'японский майонез',
      'унаги соус',
      'кунжут рис',
      'нори',
    ],
    image: senBon,
    price: 225,
  },
  {
    title: 'Калифорния форель',
    ingredients: ['форель', 'икра тобика красная', 'сыр сливочный', 'огурец', 'рис', 'нори'],
    image: californiaForel,
    price: 295,
  },
  {
    title: 'Красный дракон',
    ingredients: ['форель', 'угорь', 'огурец', 'оранжевая икра тобико', 'сыр сливочный', 'рис', 'нори'],
    image: redDragon,
    price: 310,
  },
  {
    title: 'Чидори',
    ingredients: [
      'форель',
      'креветка тигровая темпура',
      'сыр чедер',
      'снежный краб',
      'спайси',
      'авокадо',
      'икра тобико оранжевая',
      'рис',
      'нори',
    ],
    image: chidori,
    price: 215,
  },
  {
    title: 'Инь Янь',
    ingredients: ['угорь', 'лосось', 'авокадо', 'огурец', 'икра тобико', 'рис', 'нори'],
    image: inYan,
    price: 184,
  },
  {
    title: 'Акита',
    ingredients: ['форель', 'креветка тигровая', 'манго', 'сыр сливочный', 'красная икра тобико', 'рис', 'нори'],
    image: akita,
    price: 265,
  },
  {
    title: 'Спайси ролл',
    ingredients: ['форель', 'красная икра тобико', 'спайси соус', 'огурец', 'лист салата', 'рис', 'нори'],
    image: spicyRoll,
    price: 150,
  },
  {
    title: 'Калифорния чиз форель',
    ingredients: ['форель', 'кунжут', 'авокадо', 'сыр сливочный', 'дикий рис', 'нори'],
    image: califormiaCheseForel,
    price: 169,
  },
];
