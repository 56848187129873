import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import { loadMessagesStarted, loadMessagesSuccess, loadMessagesFailed } from '../reducers/messages';

const { REACT_APP_API_URL } = process.env;

const ordersUrl = `${REACT_APP_API_URL}/message/:id`;

const useUpdateMessage = () => {
  const { patch } = useRequest();
  const { data } = useSelector(({ messages }) => messages);
  const dispatch = useDispatch();

  const start = () => {
    dispatch(loadMessagesStarted());
  };

  const onSuccess = (message) => {
    dispatch(
      loadMessagesSuccess({
        ...data,
        items: data.items.map((_) => (_.id === message.id ? message : _)),
      })
    );
  };

  const handleError = (error) => {
    console.log(error);
    dispatch(loadMessagesFailed());
  };

  const load = ({ id, text, status }) => {
    start();
    patch({
      url: ordersUrl.replace(':id', id),
      body: { text, status },
    })
      .then(onSuccess)
      .catch(handleError);
  };

  return { load };
};

export default useUpdateMessage;
