import { useCallback } from 'react';

import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createClientUrl = `${REACT_APP_API_URL}/client`;

const useCreateClient = () => {
  const { post } = useRequest();

  const createClient = useCallback(
    ({ params, onStart, onSuccess, onError }) => {
      onStart();
      post({
        url: createClientUrl,
        body: params,
      })
        .then(onSuccess)
        .catch(onError);
    },
    [post]
  );

  return { createClient };
};

export default useCreateClient;
