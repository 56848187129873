import { makeStyles } from '@material-ui/core/styles';

const siderWidth = 310;

export const useStyles = makeStyles((theme) => ({
  clientContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clientInfo: {
    flexGrow: 1,
  },
  submitButtonContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  servicesCard: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1),
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  time: {
    display: 'flex',
  },
  loadingIcon: {
    marginRight: theme.spacing(1),
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  card: {
    height: 200,
    padding: theme.spacing(2),
    overflow: 'hidden',
  },
  page: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
  },
  sider: {
    width: 310,
    height: '100%',
    overflow: 'auto',
  },
  editor: {
    width: `calc(100% - ${siderWidth}px)`,
    height: '100%',
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));
