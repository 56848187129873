import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import { useStyles } from './styles';
import Header from './Header';
import ResourceRow from './ResourceRow';
import DepartmentRow from './DepartmentRow';
import { Skeleton } from '../../../../common';
import { useAppData } from '../../../../storage/providers';
import { useShifts } from '../../../hooks';

const daysInRow = 14;

const generateDates = (page) => {
  const today = new Date();
  return Array.from(Array(daysInRow)).map((_, idx) => ({
    date: moment(today).add(idx + daysInRow * page, 'day'),
  }));
};

const Timeline = () => {
  const [page, setPage] = useState(0);
  const [dates, setDates] = useState(null);
  const classes = useStyles();
  const { data } = useAppData();
  const { departments } = data;
  const { load: loadShifts, isLoading, reset } = useShifts();

  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  const users = useMemo(() => {
    if (!departments) return null;

    return departments
      .map((d) => d.users)
      .flat()
      .reduce((acc, user) => {
        return acc.find((_) => _.id === user.id) ? acc : [...acc, user];
      }, []);
  }, [departments]);

  const masters = useMemo(() => {
    if (!users) return null;
    return users.filter((_) => /(master|top|artDirector)/.test(_.type) && _.status === 1);
  }, [users]);

  useEffect(() => {
    setDates(generateDates(page));
  }, [page]);

  useEffect(() => {
    if (dates) {
      loadShifts({
        from: dates[0].date.format('YYYY-MM-DD'),
        to: dates[dates.length - 1].date.format('YYYY-MM-DD'),
      });
    }
    // eslint-disable-next-line
  }, [dates]);

  const handleOnNextDates = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const handleOnPrevDates = useCallback(() => {
    page > 0 && setPage(page - 1);
  }, [page, setPage]);

  return useMemo(() => {
    if (!dates || !departments || !masters || isLoading) return <Skeleton rows={10} />;

    return (
      <Box className={classes.mainContainer}>
        <Header data={dates} onNextDates={handleOnNextDates} onPrevDates={handleOnPrevDates} disablePrev={page === 0} />
        {departments.map((_) => (
          <DepartmentRow key={`dep-${_.id}`} {..._} dates={dates} />
        ))}
        <Box style={{ height: 20 }} />
        {masters.map((_) => (
          <ResourceRow key={`resource-${_.id}`} {..._} dates={dates} />
        ))}
      </Box>
    );
  }, [
    classes.mainContainer,
    dates,
    departments,
    handleOnNextDates,
    handleOnPrevDates,
    isLoading,
    masters,
    page
  ]);
};

Timeline.propTypes = {};

export default Timeline;
