import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const roleUrl = `${REACT_APP_API_URL}/user/role`;

const useServices = () => {
  const { get } = useRequest();
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (roles) => {
    setRoles(roles);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    get({ url: roleUrl }).then(handleSuccess).catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { roles, setRoles, loading, setLoading };
};

export default useServices;
