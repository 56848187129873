import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    card: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  };
});
