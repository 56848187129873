import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    timeCell: {
      boxSizing: 'border-box',
      border: '1px solid rgb(214, 214, 214)!important',
      [theme.breakpoints.down('sm')]: {
        width: 150,
      },
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
  };
});
