import { useCallback, useState } from 'react';

import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const clientUrl = `${REACT_APP_API_URL}/client`;

const useClient = () => {
  const { get } = useRequest();
  const [client, setClient] = useState();
  const [clientLoading, setClientLoading] = useState(false);

  const start = useCallback(() => {
    setClientLoading(true);
  }, [setClientLoading]);

  const handleError = useCallback(
    (error) => {
      console.log(error);
      setClientLoading(false);
    },
    [setClientLoading]
  );

  const load = useCallback(
    (clientId) => {
      start();
      get({
        url: `${clientUrl}/${clientId}`,
      })
        .then((c) => {
          setClient(c);
          setClientLoading(false);
        })
        .catch(handleError);
    },
    [get, handleError, setClient, start]
  );

  return { clientLoading, load, client };
};

export default useClient;
