import styled from 'styled-components';

import Fab from '@material-ui/core/Fab';

import { media } from '../../../common/styles';

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  ${media.xs`
    width: 100%;
  `}

  ${media.sm`
    width: 600px;
  `}
`;

export const ContentManagement = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CartIcon = styled(Fab)`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
