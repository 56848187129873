import { useCallback } from 'react';

import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editOrderPaymentsUrl = (id) => `${REACT_APP_API_URL}/order/${id}/payments`;

const useEditOrderPayments = () => {
  const { patch } = useRequest();

  const editOrderPayments = useCallback(
    ({ id, params, onStart, onSuccess, onError }) => {
      onStart();
      patch({ url: editOrderPaymentsUrl(id), body: params })
        .then(onSuccess)
        .catch(onError);
    },
    [patch]
  );

  return { editOrderPayments };
};

export default useEditOrderPayments;
