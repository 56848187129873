import React from 'react';

import { WorkingPlacesProvider } from '../../../../department/providers';
import { Page } from '../../../../common';
import Timeline from '../../widgets/Timeline';

const EditShifts = () => {
  return (
    <WorkingPlacesProvider>
      <Page>
        <Timeline />
      </Page>
    </WorkingPlacesProvider>
  );
};

EditShifts.propTypes = {};

export default EditShifts;
