import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    appointment: {
      color: theme.palette.text.primary,
      borderRadius: 0,
      backgroundColor: '#fff7e6',
      '&:hover': {
        backgroundColor: '#fff7e6',
      },
    },
    selected: {
      borderBottom: `5px solid ${theme.palette.success.main}`,
      boxSizing: 'border-box',
    },
    appointmentDataContainer: {
      position: 'relative',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.7),
    },
    content: {
      padding: theme.spacing(0.7),
    },
    row: {
      marginBottom: theme.spacing(1),
    },
    createdOrderHeader: {
      backgroundColor: theme.palette.warning.light,
      transition: 'all .2s ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.warning.preLight,
        transition: 'all .2s ease-in-out',
      },
    },
    createdOrder: {
      backgroundColor: '#fff2de',
      '&:hover': {
        backgroundColor: '#fff2de',
      },
    },
    completedOrderHeader: {
      backgroundColor: theme.palette.success.light,
      transition: 'all .2s ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.success.preLight,
        transition: 'all .2s ease-in-out',
      },
    },
    completedOrder: {
      backgroundColor: '#f4ffe6',
      '&:hover': {
        backgroundColor: '#f4ffe6',
      },
    },
    declinedOrderHeader: {
      backgroundColor: theme.palette.action.disabled,
      transition: 'all .2s ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.action.disabled,
        transition: 'all .2s ease-in-out',
      },
    },
    declinedOrder: {
      backgroundColor: '#e9e9e9',
      '&:hover': {
        backgroundColor: '#e9e9e9',
      },
    },
    failedOrderHeader: {
      backgroundColor: theme.palette.error.light,
      transition: 'all .2s ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.error.preLight,
        transition: 'all .2s ease-in-out',
      },
    },
    failedOrder: {
      backgroundColor: '#ffe7e7',
      '&:hover': {
        backgroundColor: '#ffe7e7',
      },
    },

    // Appointment tooltip
    tooltipRow: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});
