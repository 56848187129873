import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      backgroundColor: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    layer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      animation: `$fadeIn 5s ${theme.transitions.easing.easeIn}`,
      backgroundColor: 'black',
      opacity: 0,
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    logo: {
      [theme.breakpoints.down('md')]: {
        height: '20vh',
      },
      [theme.breakpoints.up('md')]: {
        height: '40vh',
      },
    },
  };
});
