import PropTypes from 'prop-types';

import { useUserStore } from '../../../../user';

const Access = (props) => {
  const { children, roles, orCondition, andCondition, elseComponent } = props;
  const { userState } = useUserStore();
  const { user } = userState;

  if (!user) return null;

  const isUserMatches = !roles.length || roles.includes(user.role.name);

  if (!isUserMatches && !orCondition) return elseComponent;
  if (andCondition === false && !orCondition) return elseComponent;

  return children;
};

Access.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.node.isRequired,
  orCondition: PropTypes.bool,
  andCondition: PropTypes.bool,
  elseComponent: PropTypes.node,
};

Access.defaultProps = {
  elseComponent: null,
  roles: [],
};

export default Access;
