import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import {
  loadAppData,
  resetAppData,
  loadAppDataFailed,
  loadAppDataSuccess,
  updateService as updateServiceReducer,
  addService as addServiceReducer,
  STATE_LOADING,
  STATE_INIT,
} from '../reducers/appData';

const { REACT_APP_API_URL } = process.env;
const appDataUrl = `${REACT_APP_API_URL}/app-data`;

const useAppData = () => {
  const dispatch = useDispatch();
  const state = useSelector(({ appData }) => appData);
  const loading = state.state === STATE_LOADING;
  const initial = state.state === STATE_INIT;
  const { get } = useRequest();

  const handleOnSuccess = useCallback(
    (result) => {
      const { departments } = result;
      const users = departments
        .map(({ users }) => users)
        .flat()
        .reduce((acc, u) => {
          if (acc.find((_) => _.id === u.id)) return acc;

          return [...acc, u];
        }, []);

      dispatch(loadAppDataSuccess({ ...result, users }));
    },
    [dispatch]
  );

  const handleOnError = useCallback(
    (error) => {
      dispatch(loadAppDataFailed());
    },
    [dispatch]
  );

  const load = useCallback(() => {
    dispatch(loadAppData());
    get({ url: appDataUrl }).then(handleOnSuccess).catch(handleOnError);
  }, [dispatch, get, handleOnError, handleOnSuccess]);

  const reset = useCallback(() => {
    dispatch(resetAppData());
  }, [dispatch]);

  const updateService = useCallback(
    (service) => {
      dispatch(updateServiceReducer(service));
    },
    [dispatch]
  );

  const addService = useCallback(
    (service, serviceGroupId) => {
      dispatch(addServiceReducer({ service, serviceGroupId }));
    },
    [dispatch]
  );

  return {
    load,
    reset,
    loading,
    initial,
    updateService,
    addService,
    ...state,
  };
};

export default useAppData;
