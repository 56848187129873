import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import { useAppData } from '../../../../storage/providers';
import { FormInput, FormSelect } from '../../../../form/adapters';
import { LoadingButton } from '../../../../common';
import { useCreateCalculation } from '../../../hooks';

const validationSchema = object().shape({
  calc: string().required('Required'),
  userId: string(),
});

const AddCalculationForm = (props) => {
  const { service } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { data, updateService } = useAppData();
  const { users, departments } = data;
  const { t } = useTranslation();
  const { addCalculation } = useCreateCalculation();

  const departmentUsers = useMemo(() => {
    const departmentIds = departments
      .filter(({ serviceGroups }) => serviceGroups.some((_) => +_.id === +service.serviceGroupId))
      .map((_) => _.id);

    return users.filter(({ departments, status }) =>
      departments.some((_) => departmentIds.includes(_.id) && status === 1)
    );
    // eslint-disable-next-line
  }, []);

  const triggerIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOnSubmit = ({ calc, userId }, { setSubmitting }) => {
    addCalculation({
      id: service.id,
      params: { calc, userId: userId || undefined },
      onSuccess: (result) => {
        setSubmitting(false);
        updateService({
          ...service,
          calcs: [...service.calcs, result],
        });
        setIsOpen(false);
      },
    });
  };

  return (
    <Grid container style={{ marginTop: 10 }}>
      {isOpen && (
        <Formik initialValues={{ calc: '', userId: '' }} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
          {(props) => {
            const { dirty, isValid, isSubmitting } = props;

            return (
              <Form style={{ width: '100%' }}>
                <Grid container>
                  <Grid item xs={6} style={{ paddingRight: 5 }}>
                    <Field
                      id="calc"
                      name="calc"
                      component={FormInput}
                      label={t`Calculation`}
                      placeholder={t`Calculation`}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 5 }}>
                    <Field id="userId" name="userId" component={FormSelect} label={t`User`} placeholder={t`User`}>
                      {departmentUsers.map(({ id, firstName, lastName }) => (
                        <MenuItem key={`user-${id}`} value={id}>
                          {`${firstName} ${lastName}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <LoadingButton loading={isSubmitting} disabled={!dirty || !isValid || isSubmitting} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      {!isOpen && (
        <IconButton color="primary" onClick={triggerIsOpen}>
          <AddIcon fontSize="small" />
        </IconButton>
      )}
    </Grid>
  );
};

AddCalculationForm.propTypes = {
  service: PropTypes.object.isRequired,
};

export default AddCalculationForm;
