import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import UserGraphItem from './UserGraphItem';

const UserGraph = (props) => {
  const { totals } = props;

  return useMemo(() => {
    if (!totals) return null;

    return (
      <Box>
        {totals.map(({ clientId, state }, idx) => (
          <UserGraphItem key={`not-returned-${idx}-${clientId}`} clientId={clientId} state={state} seqNumber={idx} />
        ))}
      </Box>
    );
  }, [totals]);
};

UserGraph.propTypes = {
  totals: PropTypes.array,
};

export default UserGraph;
