import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    divider: {
      marginBottom: theme.spacing(1),
    },
    dividerText: {
      marginTop: theme.spacing(1),
    },
  };
});
