import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const LoadingButton = (props) => {
  const { loading, disabled, children, ...restProps } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button type="submit" variant="contained" disabled={disabled} fullWidth {...restProps}>
      {loading && <CircularProgress size={16} className={classes.loadingIcon} />}
      {children || t`Submit`}
    </Button>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default LoadingButton;
