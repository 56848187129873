import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const FormColorPicker = ({ field, form, className = '', ...props }) => {
  const error = form.errors[props.id];
  const initialColor = form.values[props.id];
  const [color, setColor] = useState(initialColor ? { hex: initialColor } : undefined);

  const handleOnChange = (color) => {
    setColor(color);
  };

  const handleOnChangeComplete = ({ hex }) => {
    form.setFieldValue(props.id, hex);
  };

  return (
    <>
      <FormControl fullWidth className={`${className}`}>
        <SketchPicker {...props} onChange={handleOnChange} onChangeComplete={handleOnChangeComplete} color={color} />
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default FormColorPicker;
