import ebiGunkanImg from '../../../../../assets/images/inarifood/sushi/ebi_gunkan.jpeg';
import gunkan from '../../../../../assets/images/inarifood/sushi/gunkan.jpeg';
import sakeVakame from '../../../../../assets/images/inarifood/sushi/sake.jpeg';
import unagiVakame from '../../../../../assets/images/inarifood/sushi/unagi_vakame.jpeg';
import susiUnagi from '../../../../../assets/images/inarifood/sushi/susi_unagi.jpeg';
import susiTuna from '../../../../../assets/images/inarifood/sushi/susi_tuna.jpeg';
import susiForel from '../../../../../assets/images/inarifood/sushi/susi_forel.jpeg';
import susiShrimp from '../../../../../assets/images/inarifood/sushi/susi_shrimp.jpeg';
import gunkanTobiko from '../../../../../assets/images/inarifood/sushi/gunkan_tobiko.jpeg';
import sakeLuks from '../../../../../assets/images/inarifood/sushi/sake_luks.jpeg';
import gunkanUnagiSpicy from '../../../../../assets/images/inarifood/sushi/gunkan_unagi_spicy.jpeg';
import gunganSakeSpycy from '../../../../../assets/images/inarifood/sushi/gungan_sake_spycy.jpeg';

export default [
  {
    title: 'Эби Гункан',
    ingredients: ['хияши вакаме', 'рис', 'нори'],
    image: ebiGunkanImg,
    price: 18,
  },
  {
    title: 'Гункан Хияши',
    ingredients: ['креветка тигровая', 'хияши вакаме', 'японский майонез', 'рис', 'нори'],
    image: gunkan,
    price: 48,
  },
  {
    title: 'Сяке Вакаме',
    ingredients: ['форель', 'хияши вакаме', 'японский майонез', 'рис', 'нори'],
    image: sakeVakame,
    price: 38,
  },
  {
    title: 'Унаги Вакаме',
    ingredients: ['креветка тигровая', 'хияши вакаме', 'японский майонез', 'рис', 'нори'],
    image: unagiVakame,
    price: 46,
  },
  {
    title: 'Суши с угрем',
    ingredients: ['угорь', 'рис', 'унаги соус', 'нори'],
    image: susiUnagi,
    price: 49,
  },
  {
    title: 'Суши с тунцом',
    ingredients: ['тунец', 'рис', 'оранжевая икра тобико', 'нори'],
    image: susiTuna,
    price: 46,
  },
  {
    title: 'Суши с форелью',
    ingredients: ['форель', 'рис', 'красная икра тобико', 'нори'],
    image: susiForel,
    price: 38,
  },
  {
    title: 'Суши с креветкой',
    ingredients: ['креветка тигровая', 'рис', 'черная икра тобико', 'нори'],
    image: susiShrimp,
    price: 39,
  },
  {
    title: 'Гункан тобико',
    ingredients: ['икра красная тобико', 'рис', 'нори'],
    image: gunkanTobiko,
    price: 58,
  },
  {
    title: 'Сяке люкс',
    ingredients: ['форель', 'сыр сливочный', 'икра красная', 'рис', 'нори'],
    image: sakeLuks,
    price: 88,
  },
  {
    title: 'Гункан унаги спайс',
    ingredients: ['угорь', 'спайси соус', 'чёрная икра тобико', 'рис', 'нори'],
    image: gunkanUnagiSpicy,
    price: 55,
  },
  {
    title: 'Гункан Сяке спайс',
    ingredients: ['форель', 'спайси соус', 'чёрная икра тобико', 'рис', 'нори'],
    image: gunganSakeSpycy,
    price: 39,
  },
];
