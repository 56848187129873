import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { useStyles } from './styles';
import { ModalButton, Text } from '../../../../common';
import CreateDepartmentForm from '../CreateDepartmentForm';
import { DepartmentContext } from '../../../providers';
import EditDepartmentForm from '../EditDepartmentForm';

const Departments = (props) => {
  const { selectedDepartmentId, setSelectedDepartmentId } = props;
  const { t } = useTranslation();
  const { departments } = useContext(DepartmentContext);
  const classes = useStyles();

  if (!departments) return null;

  const handleOnSelect = (id) => () => {
    setSelectedDepartmentId(id);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.gridItem}>
        <Paper className={classes.tablePaper}>
          <Table className={classes.table} aria-label="departments table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text bold upper>{t`Name`}</Text>
                </TableCell>
                <TableCell style={{ width: '10em' }}>
                  <Text bold upper>{t`Actions`}</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments.map((department) => (
                <TableRow
                  key={department.id}
                  onClick={handleOnSelect(department.id)}
                  hover
                  className={clsx(classes.tableRow, department.id === selectedDepartmentId && classes.tableRowSelected)}
                >
                  <TableCell>
                    <Text>{department.name}</Text>
                  </TableCell>
                  <TableCell>
                    <ModalButton
                      buttonComponent={
                        <Tooltip title={t`Edit`} placement="top">
                          <CreateIcon className={classes.editIcon} />
                        </Tooltip>
                      }
                      title={t`Edit department`}
                      contentProps={{
                        onSubmit: () => {},
                      }}
                    >
                      <EditDepartmentForm departmentId={department.id} />
                    </ModalButton>
                    <Tooltip title={t`Delete`} placement="top">
                      <DeleteOutlineIcon className={classes.removeIcon} onClick={() => console.log('Delete')} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <ModalButton
          buttonComponent={
            <Button color="primary" className={classes.addButton}>
              {t`Create department`}
            </Button>
          }
          title={t`Create department`}
          contentProps={{
            onSubmit: () => {},
          }}
        >
          <CreateDepartmentForm />
        </ModalButton>
      </Grid>
    </Grid>
  );
};

Departments.propTypes = {
  selectedDepartmentId: PropTypes.number,
  setSelectedDepartmentId: PropTypes.func.isRequired,
};

export default Departments;
