import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import {
  loadOrdersSuccess,
  loadOrdersFailed,
  loadOrdersStarted,
  resetOrders,
  STATE_LOADING,
} from '../reducers/clientOrders';

const { REACT_APP_API_URL } = process.env;

const ordersUrl = `${REACT_APP_API_URL}/order`;

const useClientOrders = () => {
  const { get } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ clientOrders }) => clientOrders);
  const isLoading = state === STATE_LOADING;

  const start = () => {
    dispatch(loadOrdersStarted());
  };

  const set = (orders) => {
    dispatch(loadOrdersSuccess(orders));
  };

  const fail = () => {
    dispatch(loadOrdersFailed());
  };

  const handleError = (error) => {
    console.log(error);
    dispatch(loadOrdersFailed());
  };

  const load = ({ dateFrom, dateTo, userId, clientId }) => {
    start();
    get({
      url: ordersUrl,
      queryParams: {
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
        ...(userId ? { userId } : {}),
        ...(clientId ? { clientId } : {}),
      },
    })
      .then(set)
      .catch(handleError);
  };

  const reset = () => {
    dispatch(resetOrders());
  };

  return {
    items,
    isLoading,
    start,
    load,
    set,
    fail,
    reset,
  };
};

export default useClientOrders;
