import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    dateCard: {
      marginBottom: theme.spacing(2),
    },
    header: {
      backgroundColor: theme.palette.primary.light,
    },
    row: {
      marginBottom: theme.spacing(1),
    },
    orderWrapper: {
      margin: `${theme.spacing(2)}px 0`,
    },
    dateWrapper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    totalWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});
