import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import { Page, Skeleton } from '../../../common';
import { useMessages } from '../../hooks';
import Message from '../../components/Message';
import CreateMessageForm from '../../components/CreateMessageForm';

const Messages = () => {
  const { load, data, isLoading } = useMessages();
  const { items } = data;

  useEffect(() => {
    load({ offset: 0, limit: 50 });
    // eslint-disable-next-line
  }, []);

  if (!items || isLoading)
    return (
      <Page>
        <Skeleton />
      </Page>
    );

  return (
    <Page>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <CreateMessageForm />
            </Grid>
            <Grid item xs={12}>
              {items.map((item) => (
                <Message key={`msg-${item.id}`} {...item} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

Messages.propTypes = {};

export default Messages;
