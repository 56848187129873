import React, { useCallback, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string, number } from 'yup';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { useCreateServiceGroup } from '../../../hooks';
import { FormInput, FormSelect } from '../../../../form/adapters';
import { RowWrapper, CircularProgressWrapper } from './atoms';
import { ServiceGroupContext } from '../../../providers';
import { DepartmentContext } from '../../../../department';

const initialValues = {
  enName: '',
  ruName: '',
  ukName: '',
  departmentId: '',
};

const validationSchema = object().shape({
  enName: string().required('Required'),
  ruName: string().required('Required'),
  ukName: string().required('Required'),
  departmentId: number().required('Required'),
});

const CreateServiceGroupForm = () => {
  const { t } = useTranslation();
  const { createServiceGroup } = useCreateServiceGroup();
  const { loading, setLoading } = useContext(ServiceGroupContext);
  const { departments = [] } = useContext(DepartmentContext);

  const onStart = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const onSuccess = useCallback(() => {
    window.location.reload();
  }, []);

  const onError = useCallback(
    (error) => {
      console.log(error);
      setLoading(false);
    },
    [setLoading]
  );

  const handleSubmit = useCallback(
    (values) => {
      const params = {
        departmentId: values.departmentId,
        names: [
          { locale: 'ru', name: values.ruName },
          { locale: 'uk', name: values.ukName },
          { locale: 'en', name: values.enName },
        ],
      };
      createServiceGroup({ params, onStart, onSuccess, onError });
    },
    [createServiceGroup, onError, onStart, onSuccess]
  );

  console.log(departments);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <RowWrapper>
              <Field
                id="enName"
                name="enName"
                label={t`En`}
                placeholder={t`En`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="ruName"
                name="ruName"
                label={t`Ru`}
                placeholder={t`Ru`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="ukName"
                name="ukName"
                label={t`Uk`}
                placeholder={t`Uk`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="departmentId"
                name="departmentId"
                label={t`Department`}
                placeholder={t`Department`}
                component={FormSelect}
                disabled={loading}
              >
                {departments.map(({ id, name }) => (
                  <MenuItem key={`dep-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgressWrapper size={16} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </RowWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateServiceGroupForm.propTypes = {};

export default CreateServiceGroupForm;
