import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyle } from './styles';
import { FormInput } from '../../../../../../form';

const validationSchema = object().shape({
  note: string().required('Required'),
});

const DeclineForm = (props) => {
  const { loading, onSubmit } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ note: '' }}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form>
            <Box className={classes.row}>
              <Field
                id="note"
                name="note"
                component={FormInput}
                multiline
                rowsMax={4}
                label={t`Note`}
                placeholder={t`Note`}
              />
            </Box>
            <Box className={classes.row}>
              <Button type="submit" variant="contained" disabled={!isValid || loading} fullWidth>
                {loading && <CircularProgress size={16} />}
                {t`Submit`}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

DeclineForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default DeclineForm;
