import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { object, string, number } from 'yup';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { useServiceGroup, useEditServiceGroup } from '../../../hooks';
import { FormInput, FormSelect } from '../../../../form/adapters';
import { RowWrapper, CircularProgressWrapper } from './atoms';
import { ServiceGroupContext } from '../../../providers';
import { DepartmentContext } from '../../../../department';
import { Skeleton } from '../../../../common';

const validationSchema = object().shape({
  enName: string().required('Required'),
  ruName: string().required('Required'),
  ukName: string().required('Required'),
  departmentId: number().required('Required'),
});

const EditServiceGroupForm = (props) => {
  const { onSubmit, serviceGroupId } = props;
  const { t, i18n } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    enName: '',
    ruName: '',
    ukName: '',
    departmentId: '',
  });
  const { editServiceGroup } = useEditServiceGroup();
  const { serviceGroup, loading: serviceGroupLoading } = useServiceGroup(serviceGroupId, true);
  const { loading, setLoading, setServiceGroups, serviceGroups } = useContext(ServiceGroupContext);
  const { departments = [] } = useContext(DepartmentContext);

  useEffect(() => {
    if (serviceGroup) {
      const { names, department } = serviceGroup;
      const newInitialValue = names.reduce(
        (acc, { locale, name }) => {
          const key = `${locale}Name`;
          return {
            ...acc,
            [key]: name,
          };
        },
        { departmentId: department.id }
      );
      setInitialValues(newInitialValue);
    }
  }, [serviceGroup]);

  const onStart = () => {
    setLoading(true);
  };

  const onSuccess = ({ id, names, department }) => {
    const { name } = names.find((_) => _.locale === i18n.language);
    setServiceGroups(
      serviceGroups.map((_) => {
        return +_.id !== +id ? _ : { id, name, department };
      })
    );
    setLoading(false);
    onSubmit();
  };

  const onError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleSubmit = (values) => {
    const params = {
      departmentId: values.departmentId,
      names: [
        { locale: 'ru', name: values.ruName },
        { locale: 'uk', name: values.ukName },
        { locale: 'en', name: values.enName },
      ],
    };
    editServiceGroup({ id: serviceGroupId, params, onStart, onSuccess, onError });
  };

  if (serviceGroupLoading) {
    return <Skeleton rows={8} />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <RowWrapper>
              <Field
                id="enName"
                name="enName"
                label={t`En`}
                placeholder={t`En`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="ruName"
                name="ruName"
                label={t`Ru`}
                placeholder={t`Ru`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="ukName"
                name="ukName"
                label={t`Uk`}
                placeholder={t`Uk`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="departmentId"
                name="departmentId"
                label={t`Department`}
                placeholder={t`Department`}
                component={FormSelect}
                disabled={loading}
              >
                {departments.map(({ id, name }) => (
                  <MenuItem key={`dep-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgressWrapper size={16} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </RowWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

EditServiceGroupForm.propTypes = {
  serviceGroupId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
};

export default EditServiceGroupForm;
