import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    dateWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    graphWrapper: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '100vw',
    },
  };
});
