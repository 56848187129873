import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import ListAltIcon from '@material-ui/icons/ListAlt';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import ViewListIcon from '@material-ui/icons/ViewList';

import { resetOrdersToClose } from '../../../../../reducers/ordersToClose';
import CompleteForm from './CompleteForm';
import { Text } from '../../../../../../common';
import { useChangeOrderStatus, useOrders } from '../../../../../hooks';
import { OrderStatus } from '../../../../../constants';

const Complete = (props) => {
  const { id, services, onComplete } = props;
  const { items: orders, isLoading, set, start, fail } = useOrders();
  const { changeOrderStatus } = useChangeOrderStatus();
  const dispatch = useDispatch();

  const handleOnSubmit = (values, { setSubmitting }) => {
    const params = {
      ...values,
      status: OrderStatus.COMPLETED,
    };

    const onStart = () => {
      start();
    };

    const onSuccess = (result) => {
      set(
        orders.map((_) => {
          const updatedOrder = result[_.id];

          if (!updatedOrder) return _;

          const { status, payments, note } = updatedOrder;

          return {
            ..._,
            status,
            payments,
            notes: note ? [..._.notes, note] : _.notes,
          };
        })
      );
      dispatch(resetOrdersToClose());
      onComplete();
    };

    const onError = (error) => {
      console.log(error);
      fail();
      setSubmitting(false);
    };

    changeOrderStatus({
      id,
      params,
      onStart,
      onSuccess,
      onError,
    });
  };

  const price = services.reduce((acc, { price, amount }) => acc + +price * amount, 0);

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText>
          {services.map((service) => (
            <Grid container key={`service-${service.id}`}>
              <Grid item sm={8}>
                <Text bold>{service.name}</Text>
              </Grid>
              <Grid item sm={4}>
                <Text>
                  {service.price} {service.amount > 1 && `[${service.amount}]`}
                </Text>
              </Grid>
            </Grid>
          ))}
        </ListItemText>
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemIcon>
          <ViewListIcon />
        </ListItemIcon>
        <ListItemText>
          <CompleteForm price={price} onSubmit={handleOnSubmit} loading={isLoading} />
        </ListItemText>
      </ListItem>
    </List>
  );
};

Complete.propTypes = {
  id: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default Complete;
