import React from 'react';

import { RolesProvider, UsersProvider } from '../../../providers';
import { Users } from '../../widgets';
import { DepartmentsProvider } from '../../../../department';
import { Page } from '../../../../common';

const EditUsers = () => {
  return (
    <RolesProvider>
      <DepartmentsProvider>
        <UsersProvider loadOnInit>
          <Page>
            <Users />
          </Page>
        </UsersProvider>
      </DepartmentsProvider>
    </RolesProvider>
  );
};

EditUsers.propTypes = {};

export default EditUsers;
