import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import Link from '../Link';
import Text from '../Text';

const useStyles = makeStyles((theme) => ({
  subItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  activeSubItem: {
    backgroundColor: '#4a4a4a85',
    '&:active,&:hover': {
      backgroundColor: '#4a4a4a85',
    },
  },
}));

const SubItems = (props) => {
  const { items } = props;
  const { pathname } = useLocation();
  const classes = useStyles();

  const activeItem = useMemo(() => {
    return items.find(({ path }) => path === pathname);
  }, [pathname, items]);

  return useMemo(() => {
    return (
      <Collapse in timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ name: subItemName, path, icon: subIcon }) => {
            const isActive = activeItem && activeItem.path === path;

            return (
              <Link key={subItemName} to={path}>
                <ListItem button className={clsx(isActive ? classes.activeSubItem : null)}>
                  <ListItemIcon style={{ color: 'white', minWidth: 30 }}>{subIcon}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Text h5 upper white>
                        {subItemName}
                      </Text>
                    }
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
    );
  }, [items, activeItem, classes]);
};

SubItems.propTypes = {
  items: PropTypes.array.isRequired,
};

export default SubItems;
