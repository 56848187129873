import React from 'react';
import { number, object, string } from 'yup';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormInput } from '../../../../form';
import { useStyles } from './styles';

const validationSchema = object().shape({
  amount: number().required('Required'),
  note: string().required('Required'),
});

const CreateOperationForm = (props) => {
  const { onSubmit, loading } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Formik onSubmit={onSubmit} initialValues={{ amount: '', note: '' }} validationSchema={validationSchema}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <Box className={classes.row}>
              <Field
                id="amount"
                name="amount"
                label={t`Amount`}
                placeholder={t`Amount`}
                component={FormInput}
                disabled={loading}
                type="number"
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="note"
                name="note"
                label={t`Note`}
                placeholder={t`Note`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <div className={classes.row}>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgress size={16} className={classes.loadingIcon} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateOperationForm.propTypes = {};

export default CreateOperationForm;
