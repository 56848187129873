import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import CreateServiceGroupForm from '../CreateServiceGroupForm';
import ServiceGroupListItem from '../ServiceGroupListItem';
import { ModalButton } from '../../../../common';
import { DepartmentContext } from '../../../../department';
import { useStyles } from './styles';

const ServiceGroups = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(null);
  const { departments = [] } = useContext(DepartmentContext);

  const serviceGroups = useMemo(() => {
    if (!departments) return [];

    return departments.reduce((acc, _) => [...acc, ..._.serviceGroups], []);
  }, [departments]);

  const handleCollapse = useCallback(
    (id) => () => {
      setCollapsed(collapsed === id ? null : id);
    },
    [collapsed, setCollapsed]
  );

  if (!serviceGroups) return null;

  return (
    <Grid container>
      {serviceGroups.map((serviceGroup) => {
        return (
          <Grid item key={`sg-${serviceGroup.id}`} xs={12} className={classes.gridItem}>
            <Paper className={classes.tablePaper}>
              <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                <ServiceGroupListItem
                  serviceGroup={serviceGroup}
                  handleCollapse={handleCollapse(serviceGroup.id)}
                  collapsed={collapsed === serviceGroup.id}
                />
              </List>
            </Paper>
          </Grid>
        );
      })}
      <Grid item xs={12} className={classes.gridItem}>
        <ModalButton
          buttonComponent={
            <Button color="primary" className={classes.addButton}>
              {t`Create service group`}
            </Button>
          }
          title={t`Create service group`}
          contentProps={{
            onSubmit: () => {},
          }}
        >
          <CreateServiceGroupForm />
        </ModalButton>
      </Grid>
    </Grid>
  );
};

ServiceGroups.propTypes = {};

export default ServiceGroups;
