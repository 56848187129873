import kanzas from '../../../../../assets/images/inarifood/sets/kanzas.webp';
import fujiyama from '../../../../../assets/images/inarifood/sets/fujiyama.webp';
import philaGrill from '../../../../../assets/images/inarifood/sets/phila_grill.webp';
import inoShika from '../../../../../assets/images/inarifood/sets/ino_shika.webp';
import okinava from '../../../../../assets/images/inarifood/sets/okinava.webp';
import kawasaki from '../../../../../assets/images/inarifood/sets/kawasaki.webp';
import matsumoto from '../../../../../assets/images/inarifood/sets/matsumoto.webp';
import inYan from '../../../../../assets/images/inarifood/sets/in_yan.webp';
import milton from '../../../../../assets/images/inarifood/sets/milton.jpeg';
import kyoto from '../../../../../assets/images/inarifood/sets/kyoto.jpeg';
import threeDragons from '../../../../../assets/images/inarifood/sets/three_gragons.jpeg';
import california from '../../../../../assets/images/inarifood/sets/california.jpeg';
import redCarpet from '../../../../../assets/images/inarifood/sets/red_carpet.jpeg';
import philaSet from '../../../../../assets/images/inarifood/sets/phila_set.jpeg';
import chicago from '../../../../../assets/images/inarifood/sets/chicago.webp';

export default [
  {
    title: 'Канзас',
    ingredients: ['филадельфия классик', 'калифорния угорь классик', 'изуми хот классик'],
    image: kanzas,
    price: 286,
  },
  {
    title: 'Фудзияма',
    ingredients: ['филадельфия классик', 'калифорния гриль форель', 'футомак тунец'],
    image: fujiyama,
    price: 332,
  },
  {
    title: 'Филадельфия гриль',
    ingredients: ['футо мак гриль форель', 'филадельфия гриль форель', '3)калифорния сякэ гриль форель'],
    image: philaGrill,
    price: 349,
  },
  {
    title: 'Ино шика',
    ingredients: ['филадельфия', 'чеджи', 'сяке вакаме'],
    image: inoShika,
    price: 504,
  },
  {
    title: 'Окинава',
    ingredients: ['филадельфия', 'чёрный дракон'],
    image: okinava,
    price: 535,
  },
  {
    title: 'Кавасаки',
    ingredients: ['филадельфия', 'калифорния сякэ', 'футо мак форель'],
    image: kawasaki,
    price: 563,
  },
  {
    title: 'Мацумото',
    ingredients: ['филадельфия классик', 'калифорния тунец классик ', 'калифорния сякэ классик', 'ямагато классик'],
    image: matsumoto,
    price: 549,
  },
  {
    title: 'Инь Янь',
    ingredients: ['маки огурец', 'маки авокадо', 'маки форель', 'маки креветка', 'маки с угрем и манго', 'инь янь'],
    image: inYan,
    price: 537,
  },
  {
    title: 'Милтон',
    ingredients: ['филадельфия', 'калифорния креветка', 'футо мак форель'],
    image: milton,
    price: 645,
  },
  {
    title: 'Киото',
    ingredients: ['черный дракон', 'калифорния форель', 'филадельфия креветка'],
    image: kyoto,
    price: 870,
  },
  {
    title: 'Три дракона',
    ingredients: ['красный дракон', 'зеленый дракон', 'черный дракон'],
    image: threeDragons,
    price: 879,
  },
  {
    title: 'Калифорния сет',
    ingredients: ['калифорния икра креветка', 'калифорния икра лосось', 'калифорния икра унаги'],
    image: california,
    price: 910,
  },
  {
    title: 'Красный бархат',
    ingredients: ['филадельфия', 'калифорния форель', 'футо мак форель'],
    image: redCarpet,
    price: 910,
  },
  {
    title: 'Филадельфия сет',
    ingredients: ['филадельфия кюри', 'филадельфия грин', 'филадельфия лайт', 'филадельфия микс', 'филадельфия'],
    image: philaSet,
    price: 1109,
  },
  {
    title: 'Чикаго',
    ingredients: ['калифорния чиз форель', 'филадельфия тунец', 'филадельфия грин', 'фиджи'],
    image: chicago,
    price: 1183,
  },
];
