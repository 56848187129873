import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import idx from 'idx';

import Box from '@material-ui/core/Box';

import ResourceCell from './ResourceCell';
import Cell from './Cell';

import { Text } from '../../../../common';
import { WorkingPlaceContext } from '../../../../department/providers';
import { useShifts } from '../../../hooks';

const DepartmentRow = (props) => {
  const { id, name, dates, color } = props;
  const { workingPlaces } = useContext(WorkingPlaceContext);
  const { items: allShifts } = useShifts();

  return (
    <Box style={{ display: 'flex' }}>
      <Box>
        <ResourceCell
          name={
            <Text h5 bold upper>
              {name}
            </Text>
          }
        />
      </Box>
      <Box style={{ display: 'flex', backgroundColor: `${color}` }}>
        {dates.map(({ date }, index) => {
          const bookedWorkingPlaces =
            idx(allShifts, (_) =>
              _.filter(({ date: d }) => moment(d).isSame(date, 'date')).map((_) => _.workingPlace)
            ) || [];

          const freeWorkingPlaces =
            workingPlaces &&
            workingPlaces
              .filter((_) => _.department.id === id)
              .filter((_) => !bookedWorkingPlaces.find((wp) => wp.id === _.id)).length;

          return <Cell key={`resource-${index}`} text={<Text bold>{freeWorkingPlaces || ''}</Text>} />;
        })}
      </Box>
    </Box>
  );
};

DepartmentRow.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  dates: PropTypes.array.isRequired,
};

export default DepartmentRow;
