import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const changeOrderStatusUrl = (id) => `${REACT_APP_API_URL}/order/${id}/status`;

const useChangeOrderStatus = () => {
  const { patch } = useRequest();

  const changeOrderStatus = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    patch({ url: changeOrderStatusUrl(id), body: params })
      .then(onSuccess)
      .catch(onError);
  };

  return { changeOrderStatus };
};

export default useChangeOrderStatus;
