import { useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const serviceGroupsUrl = `${REACT_APP_API_URL}/service-group`;

const useServiceGroups = (departmentIds) => {
  const { get } = useRequest();
  const [serviceGroups, setServiceGroups] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (serviceGroups) => {
    setServiceGroups(serviceGroups);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    get({
      url: serviceGroupsUrl,
      queryParams: departmentIds ? { departmentIds } : undefined,
    })
      .then(handleSuccess)
      .catch(handleError);
    // eslint-disable-next-line
  }, []);

  return { serviceGroups, setServiceGroups, loading, setLoading };
};

export default useServiceGroups;
