import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ClientEditor, ClientOrders } from '..';
import { useStyle } from './styles';

const tabs = [
  { id: 0, name: 'Info', status: 1 },
  { id: 1, name: 'History', status: 2 },
];

const ClientInfo = (props) => {
  const { client, updateClient } = props;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const classes = useStyle();
  const { t } = useTranslation();

  const handleTabChange = useCallback(
    (_, id) => {
      setActiveTab(tabs.find((_) => _.id === id));
    },
    [setActiveTab]
  );

  const tabContent = useMemo(() => {
    return (
      <>
        <Box className={clsx(classes.tabContent, activeTab.id !== 0 && classes.hidden)}>
          <ClientEditor updateClient={updateClient} client={client} />
        </Box>
        <Box className={clsx(classes.tabContent, activeTab.id !== 1 && classes.hidden)}>
          <ClientOrders client={client} />
        </Box>
      </>
    );
  }, [activeTab, client, classes, updateClient]);

  return (
    <Box>
      <Box style={{ marginBottom: 10 }}>
        <Tabs centered value={activeTab.id} onChange={handleTabChange} indicatorColor="primary">
          {tabs.map(({ id, name }) => (
            <Tab key={`tab-${id}`} label={t(name)} value={id} />
          ))}
        </Tabs>
      </Box>
      {tabContent}
    </Box>
  );
};

ClientInfo.propTypes = {
  client: PropTypes.object.isRequired,
  updateClient: PropTypes.func.isRequired,
};

export default ClientInfo;
