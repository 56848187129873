import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import { Divider, MoneyIcon, Text } from '../../../../common';
import { SellCardContainer, SellCardItem, SellCardItemDownLine, SellCardItemUpLine } from './atoms';

const SellCard = (props) => {
  const { sellId, products, paymentTypes, sellerId, buyerId, createdAt } = props;
  const { items } = useSelector(({ users }) => users);
  const { t } = useTranslation();

  const seller = useMemo(() => {
    return items.find((_) => _.id === +sellerId);
  }, [items, sellerId]);

  const buyer = useMemo(() => {
    return items.find((_) => _.id === +buyerId);
  }, [items, buyerId]);

  return useMemo(
    () => (
      <SellCardContainer>
        <SellCardItem>
          {products.map(({ id, name, price, quantity, supplier }, idx) => (
            <div key={`sell-product-${sellId}-${id}`} style={{ width: '100%' }}>
              <SellCardItemUpLine>
                <Box>
                  <Text h3>{name}</Text>{' '}
                  <Text h4 italic pushRight>
                    x{quantity}
                  </Text>
                  {buyer && (
                    <Text ghost h5 newLine>
                      {t('Bought by {{name}}', { name: `${buyer.firstName} ${buyer.lastName}` })}
                    </Text>
                  )}
                </Box>
                <div style={{ textAlign: 'right' }}>
                  <Text h5 newLine>
                    {seller.firstName} {seller.lastName}
                  </Text>
                  <Text h4 bold ghost pushRight>
                    {moment(createdAt).format('DD.MM')}
                  </Text>
                  <Text h5 ghost>
                    {moment(createdAt).format('HH:mm')}
                  </Text>
                </div>
              </SellCardItemUpLine>
              <SellCardItemDownLine>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text h3 upper newLine pushRight>
                    {price * quantity} ₴
                  </Text>
                  {paymentTypes.map((type) => (
                    <MoneyIcon type={type} key={`pay-${sellId}-${id}-${type}`} />
                  ))}
                </div>
                <Text h5 ghost italic>
                  {supplier?.name}
                </Text>
              </SellCardItemDownLine>
              {idx < products.length - 1 && <Divider color="#d7d7d7" />}
            </div>
          ))}
        </SellCardItem>
      </SellCardContainer>
    ),
    [sellId, products, seller, buyer, createdAt, t, paymentTypes]
  );
};

SellCard.propTypes = {
  sellId: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  sellerId: PropTypes.any.isRequired,
  buyerId: PropTypes.any,
};

export default SellCard;
