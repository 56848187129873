import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createDepartmentUrl = `${REACT_APP_API_URL}/department`;

const useCreateDepartment = () => {
  const { patch } = useRequest();

  const editDepartment = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    patch({ url: `${createDepartmentUrl}/${id}`, body: params })
      .then(onSuccess)
      .catch(onError);
  };

  return { editDepartment };
};

export default useCreateDepartment;
