import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

import Text from '../Text';
import SubItems from './SubItems';

const useStyles = makeStyles((theme) => ({
  module: {
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.action.hover}`,
  },
}));

const SideBar = () => {
  const { menu } = useSelector(({ sideBar }) => sideBar);
  const classes = useStyles();

  return useMemo(
    () => {
      if (!menu) return null;

      return (
        <Box style={{ width: '100%', overflow: 'auto', height: '100%' }}>
          <List>
            {menu.map(({ name, icon, subItems }) => (
              <div key={name} className={classes.module}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Text upper bold style={{ color: '#66bb6a' }}>
                        {name}
                      </Text>
                    }
                  />
                </ListItem>
                <SubItems items={subItems} />
              </div>
            ))}
          </List>
        </Box>
      );
    },
    // eslint-disable-next-line
    [menu, classes]
  );
};

SideBar.propTypes = {};

export default SideBar;
