import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import { useTheme } from '@material-ui/core';

import IsClientNotified from '../Appointment/IsClientNotified';
import { ModalButton, Text } from '../../../../common';
import { Access, Roles } from '../../../../auth';
import { ClientInfo } from '../../../../client/components/widgets';

const ClientWidget = (props) => {
  const { client, orderId, isClientNotified, color } = props;
  const theme = useTheme();

  const icon = useMemo(() => {
    if (!orderId || isClientNotified) return null;

    return (
      <IsClientNotified
        orderId={orderId}
        isClientNotified={isClientNotified}
        style={{ marginRight: theme.spacing(2) }}
      />
    );
  }, [orderId, isClientNotified, theme]);

  return (
    <Chip
      variant="outlined"
      size="medium"
      icon={icon}
      label={
        <>
          <Access
            roles={[Roles.superAdmin, Roles.manager]}
            elseComponent={
              <Text h5 upper pushRight style={{ color }}>
                {client.firstName}
              </Text>
            }
          >
            <ModalButton
              buttonComponent={
                <Text h5 upper pushRight style={{ cursor: 'pointer', color }}>
                  {client.firstName}:
                </Text>
              }
              title={'Client'}
            >
              <ClientInfo updateClient={() => {}} client={client} />
            </ModalButton>
          </Access>
          <Access roles={[Roles.superAdmin, Roles.manager]}>
            <Text bold pushRight>
              {client.phoneNumber}
            </Text>
          </Access>
        </>
      }
    />
  );
};

ClientWidget.propTypes = {
  client: PropTypes.object.isRequired,
  orderId: PropTypes.number,
  isClientNotified: PropTypes.bool,
  color: PropTypes.string,
};

ClientWidget.defaultProps = {
  color: '#000000',
};

export default ClientWidget;
