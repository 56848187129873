import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserStore } from '../../../../user';
import { useRequest } from '../../../../network';
import { useLogout } from '../../../providers';

const AuthRoute = ({ path, redirect, reverse, component, ...props }) => {
  const { get } = useRequest();
  const { userState, setUserLoading, setUser } = useUserStore();
  const { user, userLoading } = userState;
  const { logout } = useLogout();

  useEffect(() => {
    if (user === undefined && !userLoading) {
      const { REACT_APP_API_URL } = process.env;
      setUserLoading(true);

      get({ url: `${REACT_APP_API_URL}/auth/identify` })
        .then((payload) => {
          setUser(payload);
        })
        .catch(() => {
          logout();
        });
    }
    // eslint-disable-next-line
  }, []);

  if (user === undefined) return null;

  const Component = (user && !reverse) || (!user && reverse) ? component : () => <Redirect to={redirect} />;

  return <Route {...props} path={path} component={Component} />;
};

export default AuthRoute;
