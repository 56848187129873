import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const changeOrderStatusBulkUrl = `${REACT_APP_API_URL}/order/status/bulk`;

const useChangeOrderStatusBulk = () => {
  const { patch } = useRequest();

  const changeOrderStatusBulk = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    patch({ url: changeOrderStatusBulkUrl, body: params }).then(onSuccess).catch(onError);
  };

  return { changeOrderStatusBulk };
};

export default useChangeOrderStatusBulk;
