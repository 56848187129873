import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Scheduler from '../../widgets/Scheduler';
import BulkCloseOrders from '../../widgets/BulkCloseOrders';
import { Page } from '../../../../common';
import { useStyle } from './styles';

const today = moment(new Date());

const ManageOrders = () => {
  const classes = useStyle();
  const [date, setDate] = useState(today);

  const handleDateChange = useCallback((newDate) => {
    setDate(moment(newDate.toDate()));
  }, []);

  const handleMonthChange = useCallback((futureMonth) => {
    console.log(futureMonth);
  }, []);

  const datePickerComponent = useMemo(
    () => (
      <DatePicker
        disableToolbar
        views={['date']}
        minDate="2020-01-01"
        maxDate="2030-01-01"
        autoOk
        variant="static"
        openTo="year"
        value={date}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
      />
    ),
    [date, handleDateChange, handleMonthChange]
  );

  return (
    <Container disableGutters maxWidth={false}>
      <Page className={classes.page}>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.calendar}>
            <Box>
              {datePickerComponent}
              <BulkCloseOrders />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} className={classes.manager}>
            <Scheduler date={date} />
          </Grid>
        </Grid>
      </Page>
    </Container>
  );
};

ManageOrders.propTypes = {};

export default ManageOrders;
