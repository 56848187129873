import React from 'react';
import PropTypes from 'prop-types';

import MaterialSkeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './styles';

const Skeleton = (props) => {
  const classes = useStyles();
  const { rows } = props;

  return (
    <div className={classes.root}>
      {Array.from(Array(rows)).map((_, idx) => (
        <MaterialSkeleton key={`skeleton-${idx}`} animation="wave" />
      ))}
    </div>
  );
};

Skeleton.propTypes = {
  rows: PropTypes.number,
};

Skeleton.defaultProps = {
  rows: 5,
};

export default Skeleton;
