import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';

export const ProductCardContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const ProductCardItem = styled(Paper)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 20px;
`;

export const ProductManagementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const QuantitySelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AddToCartContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;
