import { useCallback, useEffect, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const departmentsUrl = `${REACT_APP_API_URL}/department`;

const useDepartments = () => {
  const { get } = useRequest();
  const [departments, setDepartments] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = useCallback(
    (departments) => {
      setDepartments(departments);
      setLoading(false);
    },
    [setDepartments, setLoading]
  );

  const handleError = useCallback(
    (error) => {
      console.log(error);
      setLoading(false);
    },
    [setLoading]
  );

  useEffect(() => {
    setLoading(true);
    get({ url: departmentsUrl }).then(handleSuccess).catch(handleError);
    // eslint-disable-next-line
  }, [handleError, handleSuccess]);

  return { departments, loading, setDepartments, setLoading };
};

export default useDepartments;
