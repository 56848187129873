import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { FormInput, FormSelect } from '../../../../form';
import { useSuppliersProvider } from '../../../providers/SuppliersProvider';
import { CircularProgressWrapper } from '../../../../auth/components/widgets/SignInForm/atoms';

const unitsOfMeasure = ['ml', 'gr', 'no'];

const initialValues = {
  name: '',
  volume: '',
  unitOfMeasure: '',
  quantity: 0,
  productSupplierId: '',
  cost: 0,
  price: 0,
};

const validationSchema = object().shape({
  name: string().required('Required'),
  volume: number().required('Required'),
  unitOfMeasure: string().required('Required'),
  quantity: number().required('Required'),
  productSupplierId: number().required('Required'),
  cost: number(),
  price: number(),
});

const CreateProductForm = (props) => {
  const { isLoading, onSubmit } = props;
  const { t } = useTranslation();
  const { suppliers } = useSuppliersProvider();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <Box style={{ marginBottom: 20 }}>
              <Field id="name" name="name" label={t`Name`} placeholder={t`Name`} component={FormInput} />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field
                id="volume"
                name="volume"
                label={t`Volume`}
                placeholder={t`Volume`}
                component={FormInput}
                type="number"
              />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field
                id="unitOfMeasure"
                name="unitOfMeasure"
                label={t`Unit of measure`}
                placeholder={t`Unit of measure`}
                component={FormSelect}
              >
                {unitsOfMeasure.map((name) => (
                  <MenuItem key={`uom-${name}`} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field
                id="productSupplierId"
                name="productSupplierId"
                label={t`Supplier`}
                placeholder={t`Supplier`}
                component={FormSelect}
              >
                {(suppliers || []).map(({ id, name }) => (
                  <MenuItem key={`supplier-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field
                id="quantity"
                name="quantity"
                label={t`Quantity`}
                placeholder={t`Quantity`}
                component={FormInput}
                type="number"
              />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field id="cost" name="cost" label={t`Cost`} placeholder={t`Cost`} component={FormInput} type="number" />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Field
                id="price"
                name="price"
                label={t`Price`}
                placeholder={t`Price`}
                component={FormInput}
                type="number"
              />
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <Button type="submit" variant="contained" disabled={!dirty || !isValid || isLoading} fullWidth>
                {isLoading && <CircularProgressWrapper size={16} />}
                {t`Submit`}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateProductForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateProductForm;
