import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  data: {
    width: 310,
  },
  buttonRow: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  row: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));
