import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import idx from 'idx';

import Box from '@material-ui/core/Box';

import ResourceCell from './ResourceCell';
import ShiftCell from './ShiftCell';
import EmptyCell from './EmptyCell';
import { Text } from '../../../../common';
import { useShifts } from '../../../hooks';

const ResourceRow = (props) => {
  const { id, firstName, lastName, dates, departments } = props;
  const { items: allShifts } = useShifts();

  return useMemo(
    () => (
      <Box style={{ display: 'flex' }}>
        <Box>
          <ResourceCell name={<Text>{`${firstName} ${lastName}`}</Text>} />
        </Box>
        <Box style={{ display: 'flex' }}>
          {dates.map(({ date }, index) => {
            const dayShifts = allShifts && allShifts.filter(({ date: d }) => moment(d).isSame(date, 'date'));

            const userShift = idx(dayShifts, (_) => _.find(({ user }) => user.id === id));

            if (userShift)
              return (
                <ShiftCell
                  key={`resource-${index}`}
                  shift={userShift}
                  color={`${userShift.workingPlace.department.color}`}
                />
              );

            return <EmptyCell key={`resource-${index}`} date={date} userId={id} departments={departments} />;
          })}
        </Box>
      </Box>
    ),
    [allShifts, id, firstName, lastName, dates, departments]
  );
};

ResourceRow.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  dates: PropTypes.array.isRequired,
};

export default ResourceRow;
