import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { Clients, ClientInfo } from '../../widgets';
import { Page } from '../../../../common';
import { useStyle } from './styles';
import { useClients } from '../../../hooks';

const useClientSelect = () => {
  const [selectedClient, setSelectedClient] = useState(null);

  return { selectedClient, setSelectedClient };
};

const ClientsList = (props) => {
  const { onClientSelected } = props;
  const tableRef = useRef();
  const classes = useStyle();
  const [isScrolled, setIsScrolled] = useState(false);
  const { selectedClient, setSelectedClient } = useClientSelect();
  const { items: clients, load, updateClient, isLoading: isClientsLoading } = useClients();

  useEffect(() => {
    const _tableRef = tableRef.current;

    _tableRef.addEventListener('scroll', (e) => {
      setIsScrolled(e.target.scrollTop > 5);
    });

    return () => {
      _tableRef.removeEventListener('scroll', () => {});
    };
  }, [tableRef]);

  const handleClientSelect = useCallback(
    (client) => {
      onClientSelected && onClientSelected(client);
      setSelectedClient(client);
    },
    [onClientSelected, setSelectedClient]
  );

  return (
    <Page className={classes.page}>
      <Box className={classes.sider} ref={tableRef}>
        <Clients
          clients={clients}
          load={load}
          updateClient={updateClient}
          isScrolled={isScrolled}
          setSelectedClient={handleClientSelect}
          selectedClient={selectedClient}
          isLoading={isClientsLoading}
        />
      </Box>
      <Box className={classes.mainData}>
        {selectedClient && <ClientInfo updateClient={updateClient} client={selectedClient} />}
      </Box>
    </Page>
  );
};

ClientsList.propTypes = {
  onClientSelected: PropTypes.func,
};

export default ClientsList;
