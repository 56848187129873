import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const FormInput = ({ field, form, className = '', ...props }) => {
  const error = form.errors[props.id];

  return (
    <>
      <FormControl fullWidth className={`${className}`}>
        <TextField {...props} {...field} error={!!error} />
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};
export default FormInput;
