import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    row: {
      marginBottom: theme.spacing(2),
    },
    amounts: {
      display: 'flex',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
    },
    moneyIcon: {
      marginRight: theme.spacing(2),
    },
    select: {
      width: 60,
      '& > *': {
        padding: 0,
      },
      '&:before:hover': {
        borderBottom: 'none',
      },
    },
    text: {
      width: 'calc(100% - 60px)',
    },
  };
});
