import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import SignInForm from '../../widgets/SignInForm';
import { useStyle } from './styles';

const SignIn = () => {
  const classes = useStyle();

  return (
    <Box className={classes.pageWrapper}>
      <Paper className={classes.paperWrapper}>
        <SignInForm />
      </Paper>
    </Box>
  );
};

SignIn.propTypes = {};

export default SignIn;
