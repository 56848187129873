const timeMap = ['hour', 'minute', 'millisecond'];

export const parseTime = (timeString) => {
  return timeString.split(':').reduce((acc, value, index) => {
    return { ...acc, [timeMap[index]]: value };
  }, {});
};

export const removeMillisFromTime = (timeString) => {
  const regex = /^(\d{2}:\d{2}).+$/.exec(timeString);
  return regex ? regex[1] : timeString;
};

export const getHours = (timeString) => {
  return timeString.split(':')[0];
};
