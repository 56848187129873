import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    cardWrapper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
      },
    },
    cardSelected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
  };
});
