import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CreateOrderForm from '../CreateOrderForm';
import { Modal, parseTime } from '../../../../common';
import { useStyle } from './styles';
import { useShifts } from '../../../../user/hooks';

const today = moment(new Date());

const TimeCell = (props) => {
  const { startDate, endDate, groupingInfo } = props;
  const [{ id }] = groupingInfo;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { t } = useTranslation();
  const { items: shifts } = useShifts();
  const classes = useStyle();

  const isToday = today.isSame(moment(endDate), 'day');
  const isBefore = today.isAfter(moment(endDate), 'day');

  const shift = useMemo(() => shifts.find((_) => _.user.id === groupingInfo[0].id), [shifts, groupingInfo]);

  const isInShift = useMemo(() => {
    const isStartTimeMatch = moment(startDate).isSameOrAfter(moment(shift.date).set(parseTime(shift.startTime)));

    const date = isToday ? moment(moment(endDate).format('HH:mm'), 'HH:mm') : moment(endDate);

    const isEndTimeMatch = date.isSameOrBefore(moment(shift.date).set(parseTime(shift.endTime)));

    return isStartTimeMatch && isEndTimeMatch && !isBefore;
  }, [shift, startDate, endDate, isToday, isBefore]);

  const style = isInShift ? {} : { backgroundColor: '#f3f3f3', cursor: 'not-allowed' };

  const handleDoubleClick = () => {
    isInShift && setIsFormOpen(true);
  };

  const handleOnComplete = () => {
    setIsFormOpen(false);
  };

  return (
    <>
      <DayView.TimeTableCell {...props} onDoubleClick={handleDoubleClick} className={classes.timeCell} style={style} />
      <Modal
        isOpen={isFormOpen}
        onCloseIconClick={() => setIsFormOpen(false)}
        onClose={() => setIsFormOpen(false)}
        title={t`Create appointment`}
      >
        <CreateOrderForm
          userId={id}
          startTime={startDate}
          endTime={moment(startDate).add(2, 'h')}
          onComplete={handleOnComplete}
        />
      </Modal>
    </>
  );
};

TimeCell.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  groupingInfo: PropTypes.array.isRequired,
};

export default TimeCell;
