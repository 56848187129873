import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createWorkingPlaceUrl = `${REACT_APP_API_URL}/working-place`;

const useCreateWorkingPlace = () => {
  const { post } = useRequest();

  const createWorkingPlace = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    post({ url: createWorkingPlaceUrl, body: params }).then(onSuccess).catch(onError);
  };

  return { createWorkingPlace };
};

export default useCreateWorkingPlace;
