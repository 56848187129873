import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
  state: STATE_INIT,
};

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    loadShiftsStarted(state) {
      return {
        ...state,
        state: STATE_LOADING,
      };
    },
    loadShiftsSuccess(state, { payload: items }) {
      return {
        ...state,
        items,
        state: STATE_SUCCESS,
      };
    },
    loadShiftsFailed(state) {
      return {
        ...state,
        state: STATE_FAILED,
      };
    },
    resetShifts() {
      return { ...initialState };
    },
  },
});

export const { loadShiftsStarted, loadShiftsSuccess, loadShiftsFailed, resetShifts } = shiftsSlice.actions;

export default shiftsSlice.reducer;
