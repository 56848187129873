import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import {
  loadProductsSuccess,
  loadProductsFailed,
  loadProductsStarted,
  resetProducts,
  STATE_LOADING,
} from '../reducers/products';

const { REACT_APP_API_URL } = process.env;

const productsUrl = `${REACT_APP_API_URL}/product`;

const useProducts = () => {
  const { get, patch, post } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ products }) => products);
  const isLoading = state === STATE_LOADING;

  const start = useCallback(() => {
    dispatch(loadProductsStarted());
  }, [dispatch]);

  const set = useCallback(
    (products) => {
      dispatch(loadProductsSuccess(products));
    },
    [dispatch]
  );

  const fail = useCallback(() => {
    dispatch(loadProductsFailed());
  }, [dispatch]);

  const handleError = useCallback(
    (error) => {
      console.log(error);
      dispatch(loadProductsFailed());
    },
    [dispatch]
  );

  const load = useCallback(() => {
    start();
    get({
      url: productsUrl,
    })
      .then(set)
      .catch(handleError);
  }, [get, handleError, set, start]);

  const create = useCallback(
    ({ body, onSuccess }) => {
      start();
      patch({
        url: productsUrl,
        body,
      })
        .then(([newItem]) => {
          set([...items, newItem]);
          onSuccess && onSuccess();
        })
        .catch(handleError);
    },
    [handleError, items, patch, set, start]
  );

  const edit = useCallback(
    ({ id, body, onSuccess }) => {
      start();
      post({
        url: `${productsUrl}/${id}`,
        body,
      })
        .then(([editedItem]) => {
          set(items.map((_) => (_.id === editedItem.id ? editedItem : _)));
          onSuccess && onSuccess();
        })
        .catch(handleError);
    },
    [handleError, items, post, set, start]
  );

  const sellProducts = useCallback(
    ({ body, onSuccess }) => {
      start();
      patch({
        url: `${productsUrl}/sell`,
        body,
      })
        .then((soldProducts) => {
          set(
            items.map((_) => {
              const soldProduct = soldProducts.find((sp) => sp.id === _.id);
              return soldProduct ? soldProduct : _;
            })
          );
          onSuccess && onSuccess();
        })
        .catch(handleError);
    },
    [patch, start, items, handleError, set]
  );

  const reset = useCallback(() => {
    dispatch(resetProducts());
  }, [dispatch]);

  return {
    items,
    isLoading,
    start,
    load,
    set,
    fail,
    reset,
    create,
    sellProducts,
    edit,
  };
};

export default useProducts;
