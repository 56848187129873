import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

import { FormTimePicker } from '../../../../form';
import { useEditShift, useRemoveShift, useShifts } from '../../../hooks';

const validationSchema = object().shape({
  startTime: object().required('Required'),
  endTime: object().required('Required'),
});

const useStyle = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2),
  },
  deleteContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: theme.palette.error.dark,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.error.light,
    },
  },
  time: {
    display: 'flex',
  },
}));

const EditShiftForm = (props) => {
  const { initialValues, onSubmit, id } = props;
  const { t } = useTranslation();
  const classes = useStyle();
  const { isLoading, set, start, fail, items: shifts } = useShifts();
  const { editShift } = useEditShift();
  const { removeShift } = useRemoveShift();
  const { enqueueSnackbar } = useSnackbar();

  const onStart = () => {
    start();
  };

  const onEditSuccess = (editedShift) => {
    set(shifts.map((_) => (_.id === editedShift.id ? editedShift : _)));
    onSubmit();
  };

  const onError = (error) => {
    if (error.message) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    fail();
  };

  const handleOnSubmit = ({ startTime, endTime }) => {
    const params = {
      startTime: startTime.format('HH:mm'),
      endTime: endTime.format('HH:mm'),
    };

    editShift({
      id,
      params,
      onStart,
      onSuccess: onEditSuccess,
      onError,
    });
  };

  const onRemoveSuccess = () => {
    set(shifts.filter((_) => _.id !== id));
    onSubmit();
  };

  const handleRemoveShift = () => {
    removeShift({
      id,
      onStart,
      onError,
      onSuccess: onRemoveSuccess,
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {(props) => {
        const { isValid } = props;

        return (
          <>
            <Form>
              <Box className={clsx(classes.row, classes.time)}>
                <Field
                  id="startTime"
                  name="startTime"
                  label={t`Start time`}
                  placeholder={t`Start time`}
                  minutesStep={5}
                  component={FormTimePicker}
                />
                <Field
                  id="endTime"
                  name="endTime"
                  label={t`End time`}
                  placeholder={t`End time`}
                  minutesStep={5}
                  component={FormTimePicker}
                />
              </Box>
              <Box className={classes.row}>
                <Button type="submit" variant="contained" disabled={!isValid || isLoading} fullWidth>
                  {isLoading && <CircularProgress size={16} className={classes.loadingIcon} />}
                  {t`Submit`}
                </Button>
              </Box>
            </Form>
            <Box className={clsx(classes.row, classes.deleteContainer)}>
              <DeleteIcon className={classes.deleteButton} onClick={handleRemoveShift} />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

EditShiftForm.propTypes = {
  initialValues: PropTypes.shape({
    startTime: PropTypes.any,
    endTime: PropTypes.any,
  }),
  onSubmit: PropTypes.func,
};

export default EditShiftForm;
