export default {
  News: 'Новости',
  Logout: 'Выйти',
  Submit: 'Подтвердить',
  Id: 'Id',
  Name: 'Имя',
  Password: 'Пароль',
  'Confirm password': 'Подтвердить пароль',
  'Sign In': 'Вход',
  'Phone number': 'Номер телефона',
  Actions: 'Действия',
  Department: 'Департамент',
  'Create department': 'Создать департамент',
  'Edit department': 'Редактировать департамент',
  Edit: 'Править',
  Delete: 'Удалить',
  En: 'En',
  Ru: 'Ru',
  Uk: 'Uk',
  'En Name': 'En Название',
  'Ru Name': 'Ru Название',
  'Uk Name': 'Uk Название',
  'En Description': 'En Описание',
  'Ru Description': 'Ru Описание',
  'Uk Description': 'Uk Описание',
  'Create service group': 'Создать сервис группу',
  'Edit service group': 'Редактировать сервис группу',
  'Create service': 'Создать сервис',
  'Edit service': 'Редактировать сервис',
  Price: 'Цена',
  'Price TOP': 'Цена TOP',
  'Price ART': 'Цена ART',
  'Approximate time (min)': 'Оценочное время (мин)',
  'First name': 'Имя',
  'Last name': 'Фамилия',
  Role: 'Роль',
  'Create user': 'Создать пользователя',
  'Edit user': 'Редактировать пользователя',
  'No data': 'Нет данных',
  'Create client': 'Создать клиента',
  Email: 'Электронный адрес',
  'Birth date': 'Дата рождения',
  Sex: 'Пол',
  Male: 'Мужчина',
  Female: 'Женщина',
  'Start time': 'Время начала',
  'End time': 'Время окончания',
  'Working place': 'Рабочее место',
  'Select department': 'Выберите департамент',
  'Create working place': 'Создать рабочее место',
  Departments: 'Департаменты',
  min: 'мин',
  Procedure: 'Процедура',
  Subtotal: 'Подитог',
  'Approximate time': 'Приблизительное время',
  'Add services': 'Добавить сервисы',
  'Edit services': 'Изменить сервисы',
  Remove: 'Удалить',
  Note: 'Заметка',
  Notes: 'Заметки',
  'Total price': 'Общая стоимость',
  'Payment type': 'Тип платежа',
  Cash: 'Наличные',
  Card: 'Перевод на карту',
  Terminal: 'Терминал',
  'Services prices not matches total price, specify the reason':
    'Цены сервисов не совпадают с итоговой стоимостью, укажите причину',
  Complete: 'Завершить',
  Decline: 'Отменить',
  Fail: 'Отказ',
  Status: 'Статус',
  Active: 'Активный',
  Completed: 'Завершен',
  Declined: 'Отклонен',
  Failed: 'Отказной',
  Instagram: 'Instagram',
  Search: 'Поиск',
  Color: 'Цвет',
  Ok: 'Ok',
  Change: 'Изменить',
  'No client chosen': 'Клиент не выбран',
  'Create appointment': 'Создать запись',
  Master: 'Мастер',
  'Top master': 'Топ мастер',
  'Art director': 'Art-директор',
  Type: 'Тип',
  Percent: 'Процент',
  Client: 'Клиент',
  List: 'Список',
  User: 'Пользователь',
  'Users list': 'Список пользователей',
  Shifts: 'Смены',
  Service: 'Сервисы',
  Order: 'Заказ',
  'Manage orders': 'Управление заказами',
  History: 'История',
  Statistic: 'Статистика',
  'Total by master': 'Сумма по мастерам',
  Load: 'Загрузить',
  From: 'С',
  To: 'По',
  'Cash amount': 'Сумма наличными',
  'Card amount': 'Сумма на карту',
  'ATM amount': 'Сумма по терминалу',
  'Payments should match total price': 'Платежи должны совпадать с общей суммой',
  Cashbox: 'Касса',
  Balance: 'Баланс',
  Income: 'Приход',
  Outcome: 'Расход',
  Amount: 'Сумма',
  'Show more': 'Показать больше',
  Services: 'Сервисы',
  Time: 'Время',
  Inactive: 'Неактивный',
  Info: 'Инфо',
  'Client is not selected': 'Клиент не выбран',
  Month: 'Месяц',
  'Month dynamic': 'Динамика месяца',
  'No payments': 'Нет платежей',
  Yes: 'Да',
  No: 'Нет',
  'You are about to delete this note. Please confirm.': 'Вы собтраетесь удалить эту заметку. Пожалуйста подтвердите.',
  Calculation: 'Расчет',
  'Change total': 'Изменить итог',
  'Mark as notified?': 'Пометить как уведомлен?',
  Default: 'По умолчанию',
  'Total cannot be lass then payments': 'Сумма не может быть выше',
  'Close orders': 'Закрыть заказы',
  'Add to completes': 'Добавить к закрытию',
  'Pay {{total}} UAH and close': 'Оплатить {{total}} UAH и закрыть',
  'Clients dynamic': 'Динамика клиентов',
  'New clients': 'Новые клиенты',
  'Old clients': 'Старые клиенты',
  'Leave a message': 'Оставить сообщение',
  'You sent': 'Вы отправили',
  'Not returned clients': 'Не вернувшиеся клиенты',
  Date: 'Дата',
  Statements: 'Выписки',
  'Too many requests': 'Слишком много запросов',
  'Statement update will be available in {{secondsToRetry}} seconds':
    'Обновление выписки будет доступно через {{secondsToRetry}} секунд',
  Volume: 'Объем',
  Product: 'Продукт',
  'Manage suppliers': 'Управлять поставщиками',
  'Manage products': 'Управлять продуктами',
  'Create product': 'Создать продукт',
  'Start typing product name': 'Начать печатать название продукта',
  Apply: 'Применить',
  'Edit supplier': 'Правити постачальника',
  'Sell products': 'Продать продукты',
  'Unit of measure': 'Еденица измерения',
  Supplier: 'Поставщик',
  Quantity: 'Количество',
  Cost: 'Себестоимость',
  Clear: 'Очистить',
  'Who sold': 'Кто продал',
  'Sell by cost': 'Продажа по себестоимости',
  Sells: 'Продажи',
  'Get sells': 'Загрузить продажи',
  'Bought by {{name}}': 'Куплено {{name}}',
  'Create supplier': 'Создать поставщика',
  Percentage: 'Доля (%)',
};
