import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  tableWrapper: {
    borderTop: '1px solid #d4d4d4',
    width: '100%',
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '70% 10% 20%',
    gridTemplateRows: 'auto',
  },
  gridTotalRow: {
    display: 'grid',
    gridTemplateColumns: '60% 20% 20%',
    gridTemplateRows: 'auto',
  },
  cell: {
    borderRight: '1px solid #d4d4d4',
    borderBottom: '1px solid #d4d4d4',
    padding: theme.spacing(0.6),
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  totalCell: {
    padding: theme.spacing(0.6),
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  amountWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  removeAmountIcon: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
  },
  addAmountIcon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  disabledIcon: {
    cursor: 'not-allowed',
    color: theme.palette.text.disabled,
  },
  deleteButton: {
    cursor: 'pointer',
    color: theme.palette.error.dark,
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
}));
