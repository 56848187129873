import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';
import { loadMessagesStarted, loadMessagesSuccess, loadMessagesFailed, STATE_LOADING } from '../reducers/messages';

const { REACT_APP_API_URL } = process.env;

const ordersUrl = `${REACT_APP_API_URL}/message`;

const useMessages = () => {
  const { get } = useRequest();
  const dispatch = useDispatch();
  const { data, state } = useSelector(({ messages }) => messages);
  const isLoading = state === STATE_LOADING;

  const start = () => {
    dispatch(loadMessagesStarted());
  };

  const set = (messages) => {
    dispatch(loadMessagesSuccess(messages));
  };

  const fail = () => {
    dispatch(loadMessagesFailed());
  };

  const handleError = (error) => {
    console.log(error);
    dispatch(loadMessagesFailed());
  };

  const load = ({ offset, limit }) => {
    start();
    get({
      url: ordersUrl,
      queryParams: {
        ...(offset !== undefined ? { offset } : {}),
        ...(limit ? { limit } : {}),
      },
    })
      .then(set)
      .catch(handleError);
  };

  return {
    data,
    isLoading,
    start,
    load,
    set,
    fail,
  };
};

export default useMessages;
