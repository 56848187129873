import { makeStyles } from '@material-ui/core/styles';

const cellHeight = 30;

const cellSize = {
  width: 70,
  height: cellHeight,
};

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'grid',
  },
  headerContainer: {
    display: 'flex',
  },
  datesSwitcher: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowLeftButton: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
  arrowRightButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  resourceCell: {
    width: 200,
    height: cellHeight,
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  dataCell: {
    ...cellSize,
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  shiftCell: {
    ...cellSize,
    cursor: 'pointer',
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 4,
  },
  hiddenButton: {
    visibility: 'hidden',
    padding: 0,
    minWidth: 0,
    width: 0,
  },
}));
