import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { QuantityNumber, QuantitySelectorButton, QuantitySelectorContainer } from './atoms';

const QuantitySelector = (props) => {
  const { onChange, initialQuantity, vertical } = props;
  const [counter, setCounter] = useState(initialQuantity);

  useEffect(() => {
    onChange(counter);
    // eslint-disable-next-line
  }, [counter]);

  const handleOnMinusClick = useCallback(() => {
    counter > initialQuantity && setCounter(counter - 1);
  }, [counter, initialQuantity]);

  const handleOnPlusClick = useCallback(() => setCounter(counter + 1), [counter]);

  return (
    <QuantitySelectorContainer vertical={vertical}>
      <QuantitySelectorButton isBlack={counter > initialQuantity} onClick={handleOnMinusClick} />
      <QuantityNumber>{counter}</QuantityNumber>
      <QuantitySelectorButton isBlack plus onClick={handleOnPlusClick} />
    </QuantitySelectorContainer>
  );
};

QuantitySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialQuantity: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
};

QuantitySelector.defaultProps = {
  initialQuantity: 0,
};

export default QuantitySelector;
