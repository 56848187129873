import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const getVariant = ({ h1, h2, h3, h4, h5, h6 }) => {
  if (h1) return 'h1';
  if (h2) return 'h2';
  if (h3) return 'h3';
  if (h4) return 'h4';
  if (h5) return 'h5';
  if (h6) return 'h6';
  return 'h4';
};

const Text = (props) => {
  const {
    children,
    bold,
    italic,
    upper,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ghost,
    error,
    success,
    white,
    className,
    newLine,
    pushRight,
    ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <Typography
      {...otherProps}
      variant={getVariant({ h1, h2, h3, h4, h5, h6 })}
      className={clsx(
        classes.text,
        bold && classes.bold,
        italic && classes.italic,
        upper && classes.upper,
        ghost && classes.ghost,
        error && classes.error,
        success && classes.success,
        white && classes.white,
        newLine && classes.newLine,
        pushRight && classes.pushRight,
        className
      )}
    >
      {children}
    </Typography>
  );
};

Text.propTypes = {
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  h5: PropTypes.bool,
  h6: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  upper: PropTypes.bool,
  ghost: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  newLine: PropTypes.bool,
  children: PropTypes.any.isRequired,
  pushRight: PropTypes.bool,
};

export default Text;
