import { useCallback, useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const notReturnedClientsUrl = `${REACT_APP_API_URL}/statistic/not-returned-clients`;

const useNotReturnedClients = () => {
  const { get } = useRequest();
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = useCallback(
    (totals) => {
      setTotals(totals);
      setLoading(false);
    },
    [setTotals, setLoading]
  );

  const handleError = useCallback(
    (error) => {
      console.log(error);
      setLoading(false);
    },
    [setLoading]
  );

  const loadNotReturnedClients = useCallback(
    ({ departmentIds, userId, date }) => {
      setTotals(null);
      setLoading(true);
      get({
        url: notReturnedClientsUrl,
        queryParams: {
          departmentIds,
          date,
          ...(userId ? { userId } : {}),
        },
      })
        .then(handleSuccess)
        .catch(handleError);
    },
    [get, setTotals, setLoading, handleError, handleSuccess]
  );

  return { totals, loading, loadNotReturnedClients };
};

export default useNotReturnedClients;
