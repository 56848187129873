import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { object, string, array } from 'yup';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import ServicesField from '../ServicesField';
import { useStyles } from './styles';
import ClientField from './ClientField';
import TimeFields from './TimeFields';
import { RowDivider } from '../../../../common';
import { useCreateOrder, useOrders } from '../../../hooks';
import { FormInput } from '../../../../form/adapters';
import { useShifts } from '../../../../user/hooks';

const validationSchema = object().shape({
  client: object().required('Required'),
  services: array().min(1).required('Required'),
  startTime: string().required('Required'),
  endTime: string().required('Required'),
  note: string(),
});

const CreateOrderForm = (props) => {
  const { userId, startTime, endTime, onComplete } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { createOrder } = useCreateOrder();
  const { items: orders, set, start, fail, isLoading } = useOrders();
  const { items: shifts } = useShifts();

  const user = useMemo(() => shifts.map((_) => _.user).find((_) => _.id === userId), [userId, shifts]);

  const handleOnSubmit = (values, { setSubmitting }) => {
    const { client, services, startTime, endTime, note } = values;
    const params = {
      clientId: client.id,
      startTime: moment(startTime).format('H:mm'),
      endTime: moment(endTime).format('H:mm'),
      date: moment(startTime).format('YYYY-MM-DD'),
      userId,
      orderServices: services.map(({ id, amount, ..._ }) => ({ id, amount })),
      note: note || undefined,
    };

    const onSuccess = (order) => {
      set([...orders, order]);
      onComplete && onComplete();
    };

    const onStart = () => {
      start();
    };

    const onError = (error) => {
      console.log(error);
      fail();
      setSubmitting(false);
    };

    createOrder({
      params,
      onStart,
      onSuccess,
      onError,
    });
  };

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={{ startTime, endTime, client: '', services: [], note: '' }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, isSubmitting }) => {
        return (
          <Form>
            <Box className={classes.row}>
              <Field id="client" name="client" component={ClientField} />
            </Box>
            <Box className={classes.row}>
              <Field id="services" name="services" component={ServicesField} user={user} />
            </Box>
            <Box className={classes.row}>
              <Field id="note" name="note" placeholder={t`Note`} component={FormInput} />
            </Box>
            <RowDivider text={t`Time`} />
            <Box className={clsx(classes.row, classes.time)}>
              <TimeFields />
            </Box>
            <Box className={classes.row}>
              <Grid container justify="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!dirty || !isValid || isLoading || isSubmitting}
                  fullWidth
                >
                  {isLoading && <CircularProgress size={16} className={classes.loadingIcon} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateOrderForm.propTypes = {};

export default CreateOrderForm;
