import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createServiceUrl = `${REACT_APP_API_URL}/service/:id/calculation`;

const useCreateCalculation = () => {
  const { post } = useRequest();

  const addCalculation = ({ id, params, onSuccess, onError }) => {
    post({
      url: createServiceUrl.replace(':id', id),
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { addCalculation };
};

export default useCreateCalculation;
