import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';

import CreateSupplierForm from './CreateSupplierForm';
import EditSupplierForm from './EditSupplierForm';
import { useSuppliersProvider } from '../../providers/SuppliersProvider';
import { ModalButton, Text } from '../../../common';
import { useStyle } from './styles';

const SuppliersList = () => {
  const { isLoading, suppliers, create, edit } = useSuppliersProvider();
  const { t } = useTranslation();
  const createModalRef = useRef();
  const editModalRef = useRef();
  const classes = useStyle();

  const handleOnSubmitCreate = useCallback(
    (values) => {
      create({
        body: values,
        onSuccess: () => createModalRef.current && createModalRef.current.close(),
      });
    },
    [create]
  );

  const handleOnSubmitEdit = useCallback(
    (id) => (values) => {
      edit({
        id,
        body: values,
        onSuccess: editModalRef.current && editModalRef.current.close(),
      });
    },
    [edit]
  );

  return useMemo(
    () => (
      <Box className={classes.pageContainer}>
        <Box className={classes.contentContainer}>
          <Box className={classes.contentManagement}>
            <ModalButton
              ref={createModalRef}
              buttonComponent={
                <Button color="primary">
                  <AddIcon />
                </Button>
              }
              title={t`Create supplier`}
            >
              <CreateSupplierForm onSubmit={handleOnSubmitCreate} isLoading={isLoading} />
            </ModalButton>
          </Box>
          {suppliers.map(({ id, name, percentage }) => (
            <Box key={`supplier-${id}`} className={classes.contentItem}>
              <Paper className={classes.item}>
                <Text upper>{name}</Text>
                <Text upper>{percentage}%</Text>
              </Paper>
              <ModalButton
                ref={editModalRef}
                buttonComponent={
                  <Fab size="small" variant="round" color="primary" aria-label="edit">
                    <EditIcon />
                  </Fab>
                }
                title={t`Edit supplier`}
              >
                <EditSupplierForm
                  onSubmit={handleOnSubmitEdit(id)}
                  isLoading={isLoading}
                  initialValues={{ name, percentage }}
                />
              </ModalButton>
            </Box>
          ))}
        </Box>
      </Box>
    ),
    [
      classes.contentContainer,
      classes.contentItem,
      classes.contentManagement,
      classes.item,
      classes.pageContainer,
      handleOnSubmitCreate,
      handleOnSubmitEdit,
      isLoading,
      suppliers,
      t,
    ]
  );
};

SuppliersList.propTypes = {};

export default SuppliersList;
