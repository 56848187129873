import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    timeLine: {
      maxWidth: 800,
      padding: 0,
    },
    card: {
      padding: theme.spacing(2),
    },
    row: {
      marginBottom: theme.spacing(1),
    },
    operationGeneralInfoRight: {
      textAlign: 'right',
    },
    operationGeneralInfoLeft: {
      textAlign: 'left',
    },
    serviceWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    totalWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    serviceIcon: {
      width: 10,
      height: 10,
      marginRight: theme.spacing(1),
    },
  };
});
