import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  text: {
    display: 'inline',
    lineHeight: 'inherit',
  },
  bold: {
    fontWeight: 500,
  },
  italic: {
    fontStyle: 'italic',
  },
  upper: {
    textTransform: 'uppercase',
  },
  ghost: {
    color: theme.palette.action.disabled,
  },
  error: {
    color: theme.palette.error.dark,
  },
  success: {
    color: theme.palette.success.dark,
  },
  white: {
    color: theme.palette.background.paper,
  },
  newLine: {
    display: 'block',
  },
  pushRight: {
    marginRight: theme.spacing(1),
  },
}));
