import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Legend, Cell } from 'recharts';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import useClientsDynamic from '../../../hooks/useClientsDynamic';
import { LoadingButton, Page, RangePicker } from '../../../../common';
import { useStyle } from './styles';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const ClientsDynamic = () => {
  const classes = useStyle();
  const [{ dateFrom, dateTo }, setDates] = useState({});
  const { clientsDynamic, loadClientsDynamic, loading } = useClientsDynamic();
  const [data, setData] = useState(null);
  const isValid = dateFrom && dateTo;
  const { t } = useTranslation();

  useEffect(() => {
    if (clientsDynamic) {
      setData([
        { name: t`New clients`, value: clientsDynamic.newClient },
        { name: t`Old clients`, value: clientsDynamic.oldClient },
      ]);
    }
  }, [clientsDynamic, t]);

  const onRangeSelected = (dateFrom, dateTo) => {
    setDates({ dateFrom, dateTo });
  };

  const handleOnLoad = () => {
    loadClientsDynamic({
      dateFrom: dateFrom.format('YYYY-MM-DD'),
      dateTo: dateTo.format('YYYY-MM-DD'),
    });
  };

  const renderCustomizedLabel = (params) => {
    const { cx, cy, percent, innerRadius, outerRadius, midAngle, value } = params;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${value} (${(percent * 100).toFixed(0)})%`}
      </text>
    );
  };

  return (
    <Container disableGutters maxWidth={false}>
      <Page className={classes.page}>
        <Grid container>
          <Grid item xs={12} className={classes.datesWrapper}>
            <RangePicker onRangeSelected={onRangeSelected} />
            <LoadingButton
              variant="outlined"
              color="primary"
              disabled={!isValid || loading}
              onClick={handleOnLoad}
              className={classes.loadButton}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12}>
            {data && (
              <PieChart width={400} height={400}>
                <Pie
                  data={data}
                  cx={200}
                  cy={200}
                  labelLine={false}
                  outerRadius={80}
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            )}
          </Grid>
        </Grid>
      </Page>
    </Container>
  );
};

ClientsDynamic.propTypes = {};

export default ClientsDynamic;
