import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

const Link = (props) => {
  const classes = useStyles();
  const { className, ...restProps } = props;

  return <RouterLink {...restProps} className={clsx(classes.link, className)} />;
};

Link.propTypes = {};

export default Link;
