import { useDispatch, useSelector } from 'react-redux';

import { useRequest } from '../../network';

import {
  loadShiftsStarted,
  loadShiftsSuccess,
  loadShiftsFailed,
  resetShifts,
  STATE_LOADING,
} from '../../order/reducers/shifts';

const { REACT_APP_API_URL } = process.env;
const shiftUrl = `${REACT_APP_API_URL}/user/shift`;

const useShifts = () => {
  const { get } = useRequest();
  const dispatch = useDispatch();
  const { items, state } = useSelector(({ shifts }) => shifts);
  const isLoading = state === STATE_LOADING;

  const start = () => {
    dispatch(loadShiftsStarted());
  };

  const set = (shifts) => {
    dispatch(loadShiftsSuccess(shifts));
  };

  const fail = () => {
    dispatch(loadShiftsFailed());
  };

  const handleError = (error) => {
    console.log(error);
    dispatch(loadShiftsFailed());
  };

  const load = ({ from, to }) => {
    dispatch(loadShiftsStarted());

    get({ url: shiftUrl, queryParams: { from, to } }).then(set).catch(handleError);
  };

  const reset = () => {
    dispatch(resetShifts());
  };

  return { items, isLoading, load, reset, start, set, fail };
};

export default useShifts;
