import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import CreateShiftForm from './CreateShiftForm';
import { useStyles } from './styles';
import { ModalButton } from '../../../../common';
import { WorkingPlaceContext } from '../../../../department/providers';
import { useShifts } from '../../../hooks';

const EmptyCell = (props) => {
  const { date, userId, departments } = props;
  const classes = useStyles();
  const hiddenRef = useRef();
  const { items: allShifts } = useShifts();
  const { workingPlaces } = useContext(WorkingPlaceContext);
  const [freeWorkingPlaces, setFreeWorkingPlaces] = useState([]);

  const handleOnDoubleClick = () => {
    const dayShifts = allShifts && allShifts.filter(({ date: d }) => moment(d).isSame(date, 'date'));

    const bookedWorkingPlaces = idx(dayShifts, (_) => _.map(({ workingPlace }) => workingPlace.id)) || [];

    setFreeWorkingPlaces(
      workingPlaces.filter(
        ({ id: wpId, department }) =>
          !bookedWorkingPlaces.find((_) => _ === wpId) && departments.find((_) => _.id === department.id)
      )
    );

    hiddenRef.current.click();
  };

  return (
    <Box onDoubleClick={handleOnDoubleClick} className={classes.dataCell}>
      <ModalButton
        buttonComponent={
          <Button ref={hiddenRef} className={classes.hiddenButton}>
            _
          </Button>
        }
        contentProps={{
          onSubmit: () => {},
          initialValues: {
            startTime: moment(date).set({ hour: 9, minutes: 0 }),
            endTime: moment(date).set({ hour: 21, minutes: 0 }),
            workingPlaceId: idx(freeWorkingPlaces, (_) => _[0].id) || '',
          },
          userId,
          date,
          freeWorkingPlaces,
        }}
        title={idx(date, (_) => _.format('YYYY-MM-DD')) || ''}
      >
        <CreateShiftForm />
      </ModalButton>
    </Box>
  );
};

EmptyCell.propTypes = {
  date: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  departments: PropTypes.array.isRequired,
};

export default EmptyCell;
