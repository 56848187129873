import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { Bill } from '../../../../common';

const OrderServices = (props) => {
  const { orderServices, onRemove, onDecreaseAmount, onIncreaseAmount } = props;

  if (!orderServices || !orderServices.length) return null;

  const handleOnRemoveService = (service) => {
    onRemove(service);
  };

  const handleDecreaseAmount = (service) => {
    if (service.amount > 1) {
      onDecreaseAmount(service);
    }
  };

  const handleIncreaseAmount = (service) => {
    onIncreaseAmount(service);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Bill
          services={orderServices}
          dynamic
          onIncrease={handleIncreaseAmount}
          onDecrease={handleDecreaseAmount}
          onRemove={handleOnRemoveService}
        />
      </Grid>
    </Grid>
  );
};

OrderServices.propTypes = {
  orderServices: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  onDecreaseAmount: PropTypes.func.isRequired,
  onIncreaseAmount: PropTypes.func.isRequired,
};

export default OrderServices;
