import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { object, string, number } from 'yup';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAppData } from '../../../../storage/providers';
import { useCreateService } from '../../../hooks';
import { FormInput } from '../../../../form/adapters';
import { useStyles } from './styles';

const initialValues = {
  enName: '',
  ruName: '',
  ukName: '',
  enDescription: '',
  ruDescription: '',
  ukDescription: '',
  price: '',
  priceTop: '',
  priceArt: '',
  approximateTimeMin: '',
};

const validationSchema = object().shape({
  enName: string().required('Required'),
  ruName: string().required('Required'),
  ukName: string().required('Required'),
  enDescription: string(),
  ruDescription: string(),
  ukDescription: string(),
  price: number().required('Required'),
  priceTop: number(),
  priceArt: number(),
  approximateTimeMin: number().required('Required'),
});

const CreateServiceForm = (props) => {
  const { onSubmit, serviceGroupId } = props;
  const { t, i18n } = useTranslation();
  const { createService } = useCreateService();
  const [loading, setLoading] = useState(false);
  const { addService } = useAppData();
  const classes = useStyles();

  const onStart = () => {
    setLoading(true);
  };

  const onSuccess = ({ names, descriptions, ...newService }) => {
    const { name } = names.find((_) => _.locale === i18n.language);

    const { description } = descriptions ? descriptions.find((_) => _.locale === i18n.language) : {};

    addService({ name, description, ...newService }, serviceGroupId);
    setLoading(false);
    onSubmit();
  };

  const onError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleSubmit = (values) => {
    const {
      ruName,
      ukName,
      enName,
      ruDescription,
      ukDescription,
      enDescription,
      price,
      priceTop,
      priceArt,
      approximateTimeMin,
    } = values;

    const descriptions =
      ruDescription || ukDescription || enDescription
        ? [
            { locale: 'ru', description: ruDescription },
            { locale: 'uk', description: ukDescription },
            { locale: 'en', description: enDescription },
          ]
        : undefined;

    const params = {
      names: [
        { locale: 'ru', name: ruName },
        { locale: 'uk', name: ukName },
        { locale: 'en', name: enName },
      ],
      descriptions,
      price,
      ...(priceTop ? { priceTop } : {}),
      ...(priceArt ? { priceArt } : {}),
      approximateTimeMin,
      serviceGroupId,
    };
    createService({ params, onStart, onSuccess, onError });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <Box className={classes.row}>
              <Field
                id="enName"
                name="enName"
                label={t`En Name`}
                placeholder={t`En Name`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="ruName"
                name="ruName"
                label={t`Ru Name`}
                placeholder={t`Ru Name`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="ukName"
                name="ukName"
                label={t`Uk Name`}
                placeholder={t`Uk Name`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="enDescription"
                name="enDescription"
                label={t`En Description`}
                placeholder={t`En Description`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="ruDescription"
                name="ruDescription"
                label={t`Ru Description`}
                placeholder={t`Ru Description`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="ukDescription"
                name="ukDescription"
                label={t`Uk Description`}
                placeholder={t`Uk Description`}
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="price"
                name="price"
                label={t`Price`}
                placeholder={t`Price`}
                type="number"
                component={FormInput}
                disabled={loading}
                className={classes.price}
              />
              <Field
                id="priceTop"
                name="priceTop"
                label={t`Price TOP`}
                placeholder={t`Price TOP`}
                type="number"
                component={FormInput}
                disabled={loading}
                className={classes.price}
              />
              <Field
                id="priceArt"
                name="priceArt"
                label={t`Price ART`}
                placeholder={t`Price ART`}
                type="number"
                component={FormInput}
                disabled={loading}
                className={classes.price}
              />
            </Box>
            <Box className={classes.row}>
              <Field
                id="approximateTimeMin"
                name="approximateTimeMin"
                label={t`Approximate time (min)`}
                placeholder={t`Approximate time (min)`}
                type="number"
                component={FormInput}
                disabled={loading}
              />
            </Box>
            <Box className={classes.row}>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgress size={16} className={classes.spinner} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateServiceForm.propTypes = {
  onSubmit: PropTypes.func,
  serviceGroupId: PropTypes.number.isRequired,
};

export default CreateServiceForm;
