import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { number, object, string, ref, mixed, array } from 'yup';
import idx from 'idx';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { CircularProgressWrapper, RowWrapper } from './atoms';
import { FormInput, FormSelect } from '../../../../form';
import { RoleContext, UserContext } from '../../../providers';
import { useEditUser } from '../../../hooks';
import { DepartmentContext } from '../../../../department';
import { skipEmptyValues } from '../../../../common/utils';

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  password: '',
  rePassword: '',
  roleId: '',
  departmentIds: [],
};

const validationSchema = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .matches(/^\+380(50|95|66|99|39|67|68|96|97|98|63|93|73)\d{7}$/, 'Not valid phone number'),
  password: string(),
  rePassword: mixed().oneOf([ref('password'), null], 'Should match password'),
  roleId: number().required('Required'),
  departmentIds: array().of(number()),
});

const EditUserForm = (props) => {
  const { onSubmit, user } = props;
  const { t } = useTranslation();
  const { editUser } = useEditUser();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const { loading, setLoading, setUsers, users } = useContext(UserContext);
  const { departments = [] } = useContext(DepartmentContext);
  const { roles = [] } = useContext(RoleContext);

  useEffect(() => {
    if (user) {
      const { firstName, lastName, phoneNumber } = user;
      setInitialValues({
        ...defaultInitialValues,
        firstName,
        lastName,
        phoneNumber,
        roleId: `${idx(user, (_) => _.role.id) || ''}`,
        departmentIds: idx(user, (_) => _.departments.map(({ id }) => id)) || [],
      });
    }
  }, [user]);

  const onStart = () => {
    setLoading(true);
  };

  const onSuccess = (updatedUser) => {
    setUsers(
      users.map((user) => {
        return user.id === updatedUser.id ? updatedUser : user;
      })
    );
    setLoading(false);
    onSubmit();
  };

  const onError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleSubmit = (values) => {
    const params = {
      ...skipEmptyValues(values),
      password: idx(values, (_) => _.password) || undefined,
      rePassword: idx(values, (_) => _.rePassword) || undefined,
    };
    editUser({ id: user.id, params, onStart, onSuccess, onError });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <RowWrapper>
              <Field
                id="firstName"
                name="firstName"
                label={t`First name`}
                placeholder={t`First name`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="lastName"
                name="lastName"
                label={t`Last name`}
                placeholder={t`Last name`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="phoneNumber"
                name="phoneNumber"
                label={t`Phone number`}
                placeholder={t`Phone number`}
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="password"
                name="password"
                label={t`Password`}
                placeholder={t`Password`}
                type="password"
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="rePassword"
                name="rePassword"
                label={t`Confirm password`}
                placeholder={t`Confirm password`}
                type="password"
                component={FormInput}
                disabled={loading}
              />
            </RowWrapper>
            <RowWrapper>
              <Field
                id="departmentIds"
                name="departmentIds"
                label={t`Departments`}
                placeholder={t`Departments`}
                component={FormSelect}
                disabled={loading}
                multiple
              >
                {departments.map(({ id, name }) => (
                  <MenuItem key={`dep-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Field
                id="roleId"
                name="roleId"
                label={t`Role`}
                placeholder={t`Role`}
                component={FormSelect}
                disabled={loading}
              >
                {roles.map(({ id, name }) => (
                  <MenuItem key={`role-${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </RowWrapper>
            <RowWrapper>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgressWrapper size={16} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </RowWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

EditUserForm.propTypes = {
  onSubmit: PropTypes.func,
  user: PropTypes.object,
};

export default EditUserForm;
