import { useCallback } from 'react';

import jwt from '../../jwt';
import { useUserStore } from '../../user';

const useLogout = () => {
  const { setUser } = useUserStore();

  const logout = useCallback(() => {
    setUser(null);
    jwt.clear();
  }, [setUser]);

  return { logout };
};

export default useLogout;
