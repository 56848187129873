import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DoneIcon from '@material-ui/icons/Done';

import { useStyle } from './styles';
import { Text } from '../../../common';
import { useUpdateMessage } from '../../hooks';

const Message = (props) => {
  const { id, text, createdBy, status, createdAt } = props;
  const { user } = useSelector(({ user }) => user);
  const { load } = useUpdateMessage();
  const classes = useStyle();
  const { t } = useTranslation();

  const isMyMessage = createdBy.id === user.id;
  const isUnread = !isMyMessage && status === 1;
  const isRead = status === 2;

  const handleOnCheckedClick = () => {
    load({ id, status: 2 });
  };

  return (
    <Grid container justify={isMyMessage ? 'flex-end' : 'flex-start'} className={classes.messageContainer}>
      <Box
        className={clsx(
          classes.messageWrapper,
          isMyMessage && classes.myMessage,
          !isMyMessage && isUnread && classes.unread
        )}
      >
        <Grid
          container
          justify={isRead ? 'space-between' : isMyMessage ? 'flex-end' : 'flex-start'}
          alignItems="center"
        >
          {isRead && isMyMessage && <DoneIcon fontSize="small" color="primary" />}
          <Box>
            <Text h5 ghost newLine>
              {isMyMessage ? t`You sent` : `${createdBy.firstName} ${createdBy.lastName}`}
            </Text>
            <Box>
              <Text h5 ghost style={{ marginRight: 5 }}>
                {moment(createdAt).format('DD.MM')}
              </Text>
              <Text h5 bold ghost>
                {moment(createdAt).format('HH:mm')}
              </Text>
            </Box>
          </Box>
          {isRead && !isMyMessage && <DoneIcon fontSize="small" color="primary" />}
        </Grid>
        <Divider className={classes.divider} />
        <Box>
          <Text ghost={isRead && isMyMessage} bold={!isMyMessage}>
            {text}
          </Text>
        </Box>
      </Box>
      {!isMyMessage && isUnread && (
        <Box className={classes.checkBtnWrapper}>
          <IconButton onClick={handleOnCheckedClick}>
            <CheckCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Grid>
  );
};

Message.propTypes = {};

export default Message;
