import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createServiceUrl = `${REACT_APP_API_URL}/service`;

const useCreateService = () => {
  const { post } = useRequest();

  const createService = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    post({
      url: createServiceUrl,
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { createService };
};

export default useCreateService;
