export default {
  News: 'Новини',
  Logout: 'Вийти',
  Submit: 'Підтвердити',
  Id: 'Id',
  Name: "`Ім'я",
  Password: 'Пароль',
  'Confirm password': 'Підтвердити пароль',
  'Sign In': 'Вхід',
  'Phone number': 'Номер телефону',
  Actions: 'Дії',
  Department: 'Департамент',
  'Create department': 'Створити департамент',
  'Edit department': 'Редагувати департамент',
  Edit: 'Правити',
  Delete: 'Виталити',
  En: 'En',
  Ru: 'Ru',
  Uk: 'Uk',
  'En Name': 'En Назва',
  'Ru Name': 'Ru Назва',
  'Uk Name': 'Uk Назва',
  'En Description': 'En Опис',
  'Ru Description': 'Ru Опис',
  'Uk Description': 'Uk Опис',
  'Create service group': 'Створити сервіс групу',
  'Edit service group': 'Редагувати сервіс групу',
  'Create service': 'Створити сервіс',
  'Edit service': 'Редагувати сервіс',
  Price: 'Ціна',
  'Price TOP': 'Ціна TOP',
  'Price ART': 'Ціна ART',
  'Approximate time (min)': 'Оцінний час (хв)',
  'First name': "Им'я",
  'Last name': 'Прізвище',
  Role: 'Роль',
  'Create user': 'Створити користувача',
  'Edit user': 'Редагувати користувача',
  'No data': 'Немає даних',
  'Create client': 'Створити клієнта',
  Email: 'Електрона адреса',
  'Birth date': 'Дата народження',
  Sex: 'Пол',
  Male: 'Чоловік',
  Female: 'Жінка',
  'Start time': 'Час початку',
  'End time': 'Час окінчення',
  'Working place': 'Робоче місце',
  'Select department': 'Обрати департамент',
  'Create working place': 'Створити робоче місце',
  Departments: 'Департаменти',
  min: 'хв',
  Procedure: 'Процедура',
  Subtotal: 'Попередня сума',
  'Approximate time': 'Приблизний час',
  'Add services': 'Додади сервіси',
  'Edit services': 'Змінити сервіси',
  Remove: 'Видалити',
  Note: 'Замітка',
  Notes: 'Замітки',
  'Total price': 'Загальна вартість',
  'Payment type': 'Тип платежу',
  Cash: 'Готівка',
  Card: 'Переказ на карту',
  Terminal: 'Термінал',
  'Services prices not matches total price, specify the reason':
    'Ціни сервісів не співпадають із загальною вартістю, напишіть причину',
  Complete: 'Завершити',
  Decline: 'Скасувати',
  Fail: 'Відмова',
  Status: 'Статус',
  Active: 'Активний',
  Completed: 'Завершений',
  Declined: 'Відхилений',
  Failed: 'Відмова',
  Instagram: 'Instagram',
  Search: 'Пошук',
  Color: 'Колір',
  Ok: 'Ok',
  Change: 'Змінити',
  'No client chosen': 'Клієнта не обрано',
  'Create appointment': 'Створити запись',
  Master: 'Мастер',
  'Top master': 'Топ мастер',
  'Art director': 'Art-директор',
  Type: 'Тип',
  Percent: 'Відсоток',
  Client: 'Клієнт',
  List: 'Лист',
  User: 'Користувач',
  'Users list': 'Лист користувачів',
  Shifts: 'Зміни',
  Service: 'Сервіси',
  Order: 'Замовлення',
  'Manage orders': 'Керування замовленнями',
  History: 'Історія',
  Statistic: 'Статистика',
  'Total by master': 'Сума по майстрам',
  Load: ' Завантажити',
  From: 'З',
  To: 'По',
  'Cash amount': 'Сума готівкою',
  'Card amount': 'Сума на карту',
  'ATM amount': 'Сума по терміналу',
  'Payments should match total price': 'Платежі повинні співпадати з загальною сумою',
  Cashbox: 'Каса',
  Balance: 'Баланс',
  Income: 'Прихід',
  Outcome: 'Розхід',
  Amount: 'Сума',
  'Show more': 'Показаты більше',
  Services: 'Сервіси',
  Time: 'Час',
  Inactive: 'Неактивний',
  Info: 'Інфо',
  'Client is not selected': 'Клієнта не обрано',
  Month: 'Місяць',
  'Month dynamic': 'Динаміка місяця',
  'No payments': 'Немає платежів',
  Yes: 'Так',
  No: 'Ні',
  'You are about to delete this note. Please confirm.': 'Ви збираєтесь видалити цю замітку. Будьласка підтвердіть.',
  Calculation: 'Розрахунок',
  'Change total': 'Змінити суму',
  'Mark as notified?': 'Помітити як повідомлений?',
  Default: 'За дефолтом',
  'Total cannot be lass then payments': 'Сума не може бути вище',
  'Close orders': 'Закрити закази',
  'Add to completes': 'Додати до закриття',
  'Pay {{total}} UAH and close': 'Оплатити {{total}} UAH та закрити',
  'Clients dynamic': 'Динаміка клієнтів',
  'New clients': 'Нові клієнти',
  'Old clients': 'Старі клієнти',
  'Leave a message': 'Залишити повідомлення',
  'You sent': 'Ви відправили',
  'Not returned clients': 'Клієнти, що не повернулися',
  Date: 'Дата',
  Statements: 'Виписки',
  'Too many requests': 'Забагато запросів',
  'Statement update will be available in {{secondsToRetry}} seconds':
    'Оновлення випіски буде доступно через {{secondsToRetry}} секунд',
  Volume: "Об'ем",
  Product: 'Продукт',
  'Manage suppliers': 'Керувати постачальниками',
  'Manage products': 'Керувати продуктами',
  'Create product': 'Створити продукт',
  'Start typing': 'Почати друкувати назву продукту',
  Apply: 'Застосувати',
  'Edit supplier': 'Почати друкувати назву продукту',
  'Sell products': 'Продати продукти',
  'Unit of measure': 'Одиниця виміру',
  Supplier: 'Постачальник',
  Quantity: 'Кількість',
  Cost: 'Собівартість',
  Clear: 'Очистити',
  'Who sold': 'Хто продав',
  'Sell by cost': 'Продаж по собівартості',
  Sells: 'Продажі',
  'Get sells': 'Завантажити продажі',
  'Bought by {{name}}': 'Куплено {{name}}',
  'Create supplier': 'Створити постачальника',
  Percentage: 'Доля (%)',
};
