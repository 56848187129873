import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import { useStyle } from './styles';

const Filters = (props) => {
  const classes = useStyle();
  const { items, onChange, singleSelect, initialItems } = props;
  const [checkedItems, setCheckedItems] = useState(initialItems);

  const [activeItems, inactiveItems] = useMemo(() => {
    return [
      items.filter(({ key }) => checkedItems.find((_) => _.key === key)),
      items.filter(({ key }) => !checkedItems.find((_) => _.key === key)),
    ];
  }, [items, checkedItems]);

  const handleOnFilterClick = useCallback(
    ({ key, value }) => {
      const checkedItem = checkedItems.find((_) => _.key === key);

      let newCheckedItems;

      if (singleSelect) {
        if (idx(checkedItem, (_) => _.key === key)) return;
        newCheckedItems = [{ key, value }];
      } else {
        newCheckedItems = checkedItem ? checkedItems.filter((_) => _.key !== key) : [...checkedItems, { key, value }];
      }

      setCheckedItems(newCheckedItems);
      onChange(newCheckedItems);
    },
    [checkedItems, setCheckedItems, onChange, singleSelect]
  );

  return (
    <Box className={classes.filterContainer}>
      <Box className={classes.filterWrapper}>
        {activeItems.map(({ key, label, value }) => (
          <Chip
            key={key}
            label={label}
            className="filter-item"
            color="primary"
            onDelete={singleSelect ? undefined : () => handleOnFilterClick({ key, value })}
          />
        ))}
        {inactiveItems.map(({ key, label, value }) => (
          <Chip key={key} label={label} className="filter-item" onClick={() => handleOnFilterClick({ key, value })} />
        ))}
      </Box>
    </Box>
  );
};

Filters.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialItems: PropTypes.array,
};

Filters.defaultProps = {
  items: [],
  onChange: () => {},
  initialItems: [],
};

export default Filters;
