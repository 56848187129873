import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createOperationUrl = `${REACT_APP_API_URL}/cashbox`;

const useCreateOperation = () => {
  const { post } = useRequest();

  const createOperation = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    post({
      url: createOperationUrl,
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { createOperation };
};

export default useCreateOperation;
