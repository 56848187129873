import React from 'react';
import PropTypes from 'prop-types';

const Divider = (props) => {
  const { color, top, bottom, thickness } = props;

  return (
    <div
      style={{
        height: thickness || 1,
        width: '100%',
        backgroundColor: color || '#202020',
        marginTop: top || 20,
        marginBottom: bottom || 20,
      }}
    />
  );
};

Divider.propTypes = {
  color: PropTypes.string,
  top: PropTypes.number,
  bottom: PropTypes.number,
  thickness: PropTypes.number,
};

export default Divider;
