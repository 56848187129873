import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import UserOrders from '../UserOrders';
import { useOrdersHistory } from '../../../../order/hooks';
import { LoadingButton, Page, RangePicker, Skeleton, Text } from '../../../../common';
import { useTotalByMaster } from '../../../hooks';
import { useAppData } from '../../../../storage/providers';

import { useStyle } from './styles';

const TotalByMaster = () => {
  const classes = useStyle();
  const { loadTotal, totals, loading } = useTotalByMaster();
  const { data } = useAppData();
  const { departments } = data;
  const [{ dateFrom, dateTo }, setDates] = useState({});
  const isValid = dateFrom && dateTo;
  const [isHovering, setIsHovering] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const { loadOrders, orders, setOrders, loading: ordersLoading } = useOrdersHistory();

  useEffect(() => {
    setOrders(null);
    if (activeUserId) {
      loadOrders({
        dateFrom: dateFrom.format('YYYY-MM-DD'),
        dateTo: dateTo.format('YYYY-MM-DD'),
        userId: activeUserId,
      });
    }
    // eslint-disable-next-line
  }, [activeUserId]);

  const getDepartment = useCallback(
    (userId) => {
      return departments ? departments.find((d) => d.users.some((u) => u.id === userId)) : {};
    },
    [departments]
  );

  const onRangeSelected = (dateFrom, dateTo) => {
    setDates({ dateFrom, dateTo });
  };

  const handleOnLoad = () => {
    setActiveUserId(null);
    loadTotal({
      dateFrom: dateFrom.format('YYYY-MM-DD'),
      dateTo: dateTo.format('YYYY-MM-DD'),
    });
  };

  return (
    <Container disableGutters maxWidth={false}>
      <Page className={classes.page}>
        <Grid container>
          <Grid item xs={12} className={classes.datesWrapper}>
            <RangePicker onRangeSelected={onRangeSelected} />
            <LoadingButton
              variant="outlined"
              color="primary"
              disabled={!isValid || loading}
              onClick={handleOnLoad}
              className={classes.loadButton}
              loading={loading}
              fullWidth={false}
            />
          </Grid>
          <Grid item xs={12} className={classes.cardsWrapper}>
            <Grid container>
              <Grid item xs={12} sm={4} className={classes.cardWrapper}>
                {totals &&
                  totals.map(({ user, total, salary }, idx) => {
                    const activated = isHovering === idx || activeUserId === user.id;

                    return (
                      <Card
                        key={`user-total-${idx}`}
                        className={clsx(classes.card, activated && classes.cardActive)}
                        onMouseEnter={() => setIsHovering(idx)}
                        onMouseLeave={() => setIsHovering(null)}
                        onClick={() => setActiveUserId(user.id)}
                      >
                        <CardHeader
                          title={
                            <Text h3 upper>
                              {user.firstName} {user.lastName}
                            </Text>
                          }
                          style={activated ? { backgroundColor: getDepartment(user.id).color } : {}}
                          className={classes.cardHeader}
                        />
                        <CardContent>
                          <Box>
                            <Text h1 upper bold>
                              {total} UAH
                            </Text>
                          </Box>
                          <Box>
                            <Text h3 ghost>
                              {' '}
                              {salary} UAH
                            </Text>
                          </Box>
                        </CardContent>
                      </Card>
                    );
                  })}
                {totals && (
                  <Box>
                    <Text h1 upper bold>
                      {totals.reduce((acc, { total }) => acc + +total, 0)} UAH
                    </Text>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={8} className={classes.ordersWrapper}>
                {ordersLoading && <Skeleton rows={20} />}
                {orders &&
                  !ordersLoading &&
                  orders.map((order) => <UserOrders {...order} key={`user-${order.user.id}`} />)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    </Container>
  );
};

TotalByMaster.propTypes = {};

export default TotalByMaster;
