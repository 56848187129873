import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { useStyles } from './styles';

const ResourceCell = (props) => {
  const { name } = props;
  const classes = useStyles();

  return <Box className={classes.resourceCell}>{name}</Box>;
};

ResourceCell.propTypes = {
  name: PropTypes.any,
};

export default ResourceCell;
