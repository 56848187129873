import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createShiftUrl = `${REACT_APP_API_URL}/user`;

const useCreateShift = () => {
  const { post } = useRequest();

  const createShift = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    post({
      url: `${createShiftUrl}/${id}/shift`,
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { createShift };
};

export default useCreateShift;
