import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  item: {
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  paper: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  servicesCard: {
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  deleteButton: {
    cursor: 'pointer',
    color: theme.palette.error.dark,
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
  amountWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  removeAmountIcon: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  addAmountIcon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
}));
