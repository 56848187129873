import store from 'store';

const accessTokenItem = 'amaze-access-token';
const refreshTokenItem = 'amaze-refresh-token';

const jwt = {
  store: ({ accessToken, refreshToken }) => {
    accessToken && store.set(accessTokenItem, accessToken);
    refreshToken && store.set(refreshTokenItem, refreshToken);
  },
  clear: () => {
    store.remove(accessTokenItem);
    store.remove(refreshTokenItem);
  },
  getAccessJWT: () => store.get(accessTokenItem),
  getRefreshJWT: () => store.get(refreshTokenItem),
};

export default jwt;
