import { useState } from 'react';
import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;
const monthDynamicUrl = `${REACT_APP_API_URL}/statistic/new-clients-proportion`;

const useClientsDynamic = () => {
  const { get } = useRequest();
  const [clientsDynamic, setClientsDynamic] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (clientsDynamic) => {
    setClientsDynamic(clientsDynamic);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const loadClientsDynamic = ({ dateFrom, dateTo }) => {
    setLoading(true);
    get({
      url: monthDynamicUrl,
      queryParams: { dateFrom, dateTo },
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  return { clientsDynamic, loading, loadClientsDynamic };
};

export default useClientsDynamic;
