import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const createUserUrl = `${REACT_APP_API_URL}/user`;

const useCreateUser = () => {
  const { post } = useRequest();

  const createUser = ({ params, onStart, onSuccess, onError }) => {
    onStart();
    post({
      url: createUserUrl,
      body: params,
    })
      .then(onSuccess)
      .catch(onError);
  };

  return { createUser };
};

export default useCreateUser;
