import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    page: {
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    itemsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    itemsBlock: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: 700,
        display: 'flex',
        justifyContent: 'center',

        '& > div:nth-child(odd)': {
          marginRight: 20,
        },
      },
      [theme.breakpoints.up('lg')]: {
        width: 1060,
        '& > div': {
          marginRight: 20,
        },
        '& > div:nth-child(3n)': {
          marginRight: 0,
        },
      },
    },
    item: {
      marginBottom: 30,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: 340,
      },
    },
    itemImg: {
      width: '100%',
      height: 500,
      objectFit: 'cover',
      objectPosition: 'center',
      marginBottom: theme.spacing(2),
    },
  };
});
