import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    overflow: 'auto',
    maxHeight: '80vh',

    [theme.breakpoints.up('xs')]: {
      width: '95vw',
    },
    [theme.breakpoints.up('sm')]: {
      width: 450,
    },

    '&:focus': {
      outline: 'none',
    },
  },
  fullscreen: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    maxHeight: '100vh',
    width: '100vw',
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.action.selected}`,
  },
  content: {
    padding: theme.spacing(2),
  },
  headerTitle: {
    flexGrow: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));
