import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 560,
    },
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  headerText: {
    textTransform: 'uppercase',
    fontFamily: 'FuturaBook',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editIcon: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  removeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  addButton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 560,
    },
  },
}));
