import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    contentContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    contentManagement: {
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    contentItem: {
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
    },
    item: {
      padding: '10px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
      marginRight: 20,
    },
  };
});
