import styled from 'styled-components';

import { media } from '../../../common/styles';

export const SellsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RangePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  ${media.xs`
    width: 100%;    
  `}

  ${media.sm`
    width: 600px;
  `}
`;

export const CalculationContainer = styled.div`
  margin-bottom: 40px;

  ${media.xs`
    width: 100%;
  `}

  ${media.sm`
    width: 300px;
  `}
`;

export const SellsItemsContainer = styled.div`
  ${media.xs`
    width: 100%;
  `}

  ${media.sm`
    width: 600px;
  `}
`;
