import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { useStyles } from './styles';

const Cell = (props) => {
  const { text } = props;
  const classes = useStyles();

  return <Box className={classes.dataCell}>{text}</Box>;
};

Cell.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Cell;
