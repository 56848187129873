import React, { useCallback, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { object, array } from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { resetOrdersToClose } from '../../../reducers/ordersToClose';
import { useStyles } from './styles';
import ServicesField from '../ServicesField';
import { useEditOrder, useOrders } from '../../../hooks';
import { LoadingButton } from '../../../../common';
import { useShifts } from '../../../../user/hooks';

const validationSchema = object().shape({
  client: object().required('Required'),
  services: array().min(1).required('Required'),
});

const EditOrderForm = (props) => {
  const { orderId, initialValues, userId, onComplete } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { editOrder } = useEditOrder();
  const { items: orders, set, start, fail, isLoading } = useOrders();
  const { items: shifts } = useShifts();
  const dispatch = useDispatch();

  const user = useMemo(() => shifts.map((_) => _.user).find((_) => _.id === userId), [userId, shifts]);

  const onSuccess = useCallback(
    (order) => {
      set(orders.map((_) => (_.id === order.id ? order : _)));
      dispatch(resetOrdersToClose());
      onComplete && onComplete();
    },
    [dispatch, onComplete, orders, set]
  );

  const onStart = useCallback(() => {
    start();
  }, [start]);

  const onError = useCallback(
    (error) => {
      console.log(error);
      fail();
    },
    [fail]
  );

  const handleOnSubmit = useCallback(
    (values) => {
      const { client, services, startTime } = values;
      const params = {
        clientId: client.id,
        date: moment(startTime).format('YYYY-MM-DD'),
        userId,
        orderServices: services.map(({ id, amount, ..._ }) => ({ id, amount })),
      };

      editOrder({
        id: orderId,
        params,
        onStart,
        onSuccess,
        onError,
      });
    },
    [editOrder, onError, onStart, onSuccess, orderId, userId]
  );

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values }) => {
        return (
          <Form>
            <Box className={classes.row}>
              <Field id="services" name="services" component={ServicesField} user={user} edit />
            </Box>
            <Box className={classes.row}>
              <Grid container justify="flex-end">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || !isValid || isLoading}
                  fullWidth
                >
                  {isLoading && <CircularProgress size={16} className={classes.loadingIcon} />}
                  {t`Submit`}
                </LoadingButton>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

EditOrderForm.propTypes = {};

export default EditOrderForm;
