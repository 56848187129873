import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import Button from '@material-ui/core/Button';

import EditShiftForm from './EditShiftForm';
import { ModalButton, Text } from '../../../../common';
import { useStyles } from './styles';

const toHHmm = (time) => {
  const [, h, m] = /(\d{2}):(\d{2}):.+/.exec(time);
  return `${h}:${m}`;
};

const ShiftCell = (props) => {
  const { shift, color } = props;
  const { startTime, endTime } = shift;
  const classes = useStyles();
  const hiddenRef = useRef();

  const handleOnClick = () => {
    hiddenRef.current.click();
  };

  return (
    <Box onClick={handleOnClick} className={classes.shiftCell} style={{ backgroundColor: color }}>
      <Box>
        <Text h5>
          {toHHmm(startTime)} - {toHHmm(endTime)}
        </Text>
      </Box>
      <ModalButton
        buttonComponent={
          <Button ref={hiddenRef} className={classes.hiddenButton}>
            _
          </Button>
        }
        contentProps={{
          onSubmit: () => {},
          initialValues: {
            startTime: moment(shift.startTime, 'HH:mm'),
            endTime: moment(shift.endTime, 'HH:mm'),
          },
          ...shift,
        }}
        title={moment(shift.date).format('YYYY-MM-DD')}
      >
        <EditShiftForm />
      </ModalButton>
    </Box>
  );
};

ShiftCell.propTypes = {
  shift: PropTypes.object.isRequired,
};

export default ShiftCell;
