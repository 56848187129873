import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    content: {
      maxWidth: 600,
      [theme.breakpoints.up('md')]: {
        minWidth: 400,
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 400,
      },
    },
    paper: {
      padding: theme.spacing(2),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
    dividerText: {
      marginTop: theme.spacing(1),
    },
    centeredRow: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: 100,
      height: 100,
    },
  };
});
