import React from 'react';

import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import DescriptionIcon from '@material-ui/icons/Description';

import i18n from '../../i18n';

const menuItems = [
  {
    name: i18n.t`Order`,
    icon: <BallotOutlinedIcon />,
    subItems: [
      {
        name: i18n.t`Manage orders`,
        path: '/admin/orders',
        icon: <DescriptionIcon fontSize="small" />,
      },
    ],
  },
];

export default menuItems;
