import React from 'react';

import { Page } from '../../../common';
import SuppliersProvider from '../../providers/SuppliersProvider';
import { SuppliersList } from '../../components';

const Suppliers = () => {
  return (
    <SuppliersProvider>
      <Page>
        <SuppliersList />
      </Page>
    </SuppliersProvider>
  );
};

Suppliers.propTypes = {};

export default Suppliers;
