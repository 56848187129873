import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { useMonthDynamic } from '../../../hooks';

import { useStyle } from './styles';

const XAxisTick = (props) => {
  const { x, y, payload } = props;
  const { value } = payload;

  const [date, day] = value.split(', ');

  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={10} x={0} y={0} fill={'#666'} dy={16} textAnchor="end">
        {date}
      </text>
      <text fontSize={10} x={0} y={10} fill={'#666'} dy={16} textAnchor="end">
        {day}
      </text>
    </g>
  );
};

const MonthDynamic = () => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState(moment(new Date()));
  const { loadMonthDynamic, totals } = useMonthDynamic();
  const [data, setData] = useState(null);
  const [accumulates, setAccumulates] = useState(null);
  const classes = useStyle();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (totals) {
      setData(
        totals.map(({ date, total }) => ({
          date: moment(date).format('DD.MM, ddd'),
          total: +total,
        }))
      );

      setAccumulates(
        totals.reduce((acc, { date, total }) => {
          const formattedDate = moment(date).format('DD.MM, ddd');

          if (!acc.length) return [{ date: formattedDate, total: +total }];

          return [
            ...acc,
            {
              date: formattedDate,
              total: acc[acc.length - 1].total + +total,
            },
          ];
        }, [])
      );
    }
  }, [totals]);

  useEffect(() => {
    const [month, year] = [currentValue.format('MM'), currentValue.format('YYYY')];

    loadMonthDynamic({ month, year });
    // eslint-disable-next-line
  }, [currentValue]);

  const handleOnMonthChange = (m) => {
    setCurrentValue(m);
  };

  return (
    <Container maxWidth={false} className={classes.page}>
      <Grid container>
        <Grid item xs={12} className={classes.dateWrapper}>
          <DatePicker
            views={['month']}
            label={t`Month`}
            value={currentValue}
            minDate={new Date('2020-05-01')}
            onChange={handleOnMonthChange}
            format="MMM YYYY"
            animateYearScrolling
            autoOk
          />
        </Grid>
        {data && (
          <Grid item xs={12} className={classes.graphWrapper}>
            <LineChart width={isSmall ? 340 : 700} height={400} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tick={<XAxisTick />} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="total" stroke="#8884d8" />
            </LineChart>
          </Grid>
        )}
        {accumulates && (
          <Grid item xs={12} className={classes.graphWrapper}>
            <LineChart width={isSmall ? 340 : 700} height={400} data={accumulates}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tick={<XAxisTick />} />
              <YAxis />
              <Tooltip />
              <Legend />
              22
              <Line type="monotone" dataKey="total" stroke="#8884d8" />
            </LineChart>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

MonthDynamic.propTypes = {};

export default MonthDynamic;
