import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

const SET_USER = 'SET_USER';
const SET_USER_LOADING = 'SET_USER_LOADING';

const defaultState = {
  user: undefined,
  userLoading: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        userLoading: false,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        userLoading: action.payload,
      };
    default:
      return state;
  }
}

export const useUserStore = () => {
  const dispatch = useDispatch();
  const userState = useSelector(({ user }) => user);

  const setUser = useCallback((payload) => dispatch({ type: SET_USER, payload }), [dispatch]);

  const setUserLoading = useCallback((payload) => dispatch({ type: SET_USER_LOADING, payload }), [dispatch]);

  return { setUser, setUserLoading, userState };
};
