import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editServiceGroupUrl = `${REACT_APP_API_URL}/service-group`;

const useEditServiceGroup = () => {
  const { patch } = useRequest();

  const editServiceGroup = ({ id, params, onStart, onSuccess, onError }) => {
    onStart();
    patch({ url: `${editServiceGroupUrl}/${id}`, body: params })
      .then(onSuccess)
      .catch(onError);
  };

  return { editServiceGroup };
};

export default useEditServiceGroup;
