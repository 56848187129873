import jwt from '../jwt';
import { getI18n } from 'react-i18next';
import { useCallback } from 'react';

export const useRequest = () => {
  const urlWithParams = useCallback((url, queryParams) => {
    if (queryParams) {
      const params = Object.keys(queryParams).reduce((acc, key, idx) => {
        const param = queryParams[key];
        return param !== undefined || true ? `${acc}${idx === 0 ? '' : '&'}${key}=${queryParams[key]}` : acc;
      }, '');
      return `${url}?${params}`;
    }

    return url;
  }, []);

  const getAuthorization = useCallback(() => {
    const accessToken = jwt.getAccessJWT();
    return accessToken ? { Authorization: accessToken } : {};
  }, []);

  const getLanguage = useCallback((skipLanguage) => {
    return skipLanguage ? {} : { ln: getI18n().language };
  }, []);

  const get = useCallback(
    ({ url, skipLanguage, queryParams }) => {
      return fetch(urlWithParams(url, queryParams), {
        headers: {
          ...getAuthorization(),
          ...getLanguage(skipLanguage),
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          const error = await response.json();
          throw error;
        }

        return response.json();
      });
    },
    [getAuthorization, getLanguage, urlWithParams]
  );

  const post = useCallback(
    ({ url, body, skipLanguage }) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorization(),
          ...getLanguage(skipLanguage),
        },
        body: JSON.stringify(body),
      }).then(async (response) => {
        if (response.status >= 300) {
          const error = await response.json();
          throw error;
        }

        return response.json();
      });
    },
    [getAuthorization, getLanguage]
  );

  const patch = useCallback(
    ({ url, body, skipLanguage }) => {
      return fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorization(),
          ...getLanguage(skipLanguage),
        },
        body: JSON.stringify(body),
      }).then(async (response) => {
        if (response.status !== 200) {
          const error = await response.json();
          throw error;
        }

        return response.json();
      });
    },
    [getAuthorization, getLanguage]
  );

  const del = useCallback(
    ({ url, body, skipLanguage }) => {
      return fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorization(),
          ...getLanguage(skipLanguage),
        },
        body: JSON.stringify(body),
      }).then(async (response) => {
        if (response.status !== 200) {
          const error = await response.json();
          throw error;
        }

        return response.json();
      });
    },
    [getAuthorization, getLanguage]
  );

  return { post, patch, get, del };
};
