import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import {
  Scheduler as MaterialScheduler,
  DayView,
  GroupingPanel,
  Resources,
  Appointments,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  EditingState,
  GroupingState,
  IntegratedEditing,
  IntegratedGrouping,
  ViewState,
} from '@devexpress/dx-react-scheduler';

import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

import TimeCell from '../TimeCell';
import TimeScaleLabel from '../TimeScaleLabel';
import DayScaleLayout from '../DayScaleLayout';
import { Appointment, AppointmentTooltipLayout } from '../Appointment';
import { useStyle } from './styles';
import { Skeleton, parseTime, Text } from '../../../../common';
import { useShifts } from '../../../../user/hooks';
import { useOrders } from '../../../hooks';

const Scheduler = (props) => {
  const { date } = props;
  const classes = useStyle();
  const { items: orders, load: loadOrders, reset: resetOrders } = useOrders();
  const { items: shifts, isLoading, load: loadShifts, reset: resetShifts } = useShifts();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);

    return () => {
      resetShifts();
      resetOrders();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (date) {
      loadOrders({ dateFrom: date.format('YYYY-MM-DD') });
    }
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    loadShifts({
      from: date.format('YYYY-MM-DD'),
      to: date.format('YYYY-MM-DD'),
    }); // eslint-disable-next-line
  }, [date]);

  return useMemo(() => {
    if (!shifts || isLoading || !orders || isFirstRender) return <Skeleton rows={20} />;

    if (shifts && !shifts.length) return <>No shifts</>;

    const resources = [
      {
        fieldName: 'userId',
        title: 'Specialist',
        instances: [...shifts]
          .sort((a, b) => {
            const {
              workingPlace: { department: departmentA },
            } = a;
            const {
              workingPlace: { department: departmentB },
            } = b;

            if (departmentA.id > departmentB.id) return 1;
            if (departmentA.id < departmentB.id) return -1;
            return 0;
          })
          .map((_) => ({
            ..._.user,
            text: (
              <Box style={{ padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text h5 style={{ marginBottom: 5 }}>{`${_.user.firstName} ${_.user.lastName}`}</Text>
                <Box>
                  <Chip
                    key={`dep-${_.workingPlace.department.id}`}
                    size="small"
                    label={<Text h5>{_.workingPlace.department.name}</Text>}
                    style={{
                      border: `2px solid ${_.workingPlace.department.color}`,
                      backgroundColor: 'transparent',
                    }}
                  />
                </Box>
              </Box>
            ),
            user: _.user,
          })),
      },
    ];

    const grouping = [{ resourceName: 'userId' }];

    return (
      <div className={classes.content}>
        <Paper>
          <div>
            <MaterialScheduler
              data={orders.map((_) => ({
                ..._,
                startDate: moment(_.date).set(parseTime(_.startTime)),
                endDate: moment(_.date).set(parseTime(_.endTime)),
              }))}
            >
              <ViewState currentDate={date} />
              <GroupingState grouping={grouping} />
              <EditingState
                onCommitChanges={(e) => {
                  console.log(e);
                }}
              />
              <IntegratedEditing />

              <DayView
                startDayHour={8.5}
                endDayHour={22}
                dayScaleLayoutComponent={DayScaleLayout}
                timeTableCellComponent={TimeCell}
                timeScaleLabelComponent={TimeScaleLabel}
              />
              <Appointments appointmentComponent={Appointment} />
              <AppointmentTooltip />

              <Resources data={resources} mainResourceName="userId" />
              <IntegratedGrouping />

              <GroupingPanel />

              <AppointmentTooltip layoutComponent={AppointmentTooltipLayout} />
            </MaterialScheduler>
          </div>
        </Paper>
      </div>
    );
  }, [orders, shifts, isLoading, classes, date, isFirstRender]);
};

Scheduler.propTypes = {};

export default Scheduler;
