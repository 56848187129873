import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
  state: STATE_INIT,
};

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    loadSuppliersStarted(state) {
      return {
        ...state,
        state: STATE_LOADING,
      };
    },
    loadSuppliersSuccess(state, { payload: items }) {
      return {
        ...state,
        items,
        state: STATE_SUCCESS,
      };
    },
    loadSuppliersFailed(state) {
      return {
        ...state,
        state: STATE_FAILED,
      };
    },
    resetSuppliers() {
      return { ...initialState };
    },
  },
});

export const { loadSuppliersStarted, loadSuppliersSuccess, loadSuppliersFailed, resetSuppliers } =
  suppliersSlice.actions;

export default suppliersSlice.reducer;
