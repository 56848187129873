import { useCallback } from 'react';

import { useRequest } from '../../network';

const { REACT_APP_API_URL } = process.env;

const editClientUrl = `${REACT_APP_API_URL}/client`;

const useEditClient = () => {
  const { patch } = useRequest();

  const editClient = useCallback(
    ({ id, params, onStart, onSuccess, onError }) => {
      onStart();
      patch({
        url: `${editClientUrl}/${id}`,
        body: params,
      })
        .then(onSuccess)
        .catch(onError);
    },
    [patch]
  );

  return { editClient };
};

export default useEditClient;
