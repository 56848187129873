import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  expandedButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  editIcon: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  removeIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  servicesContainer: {
    padding: theme.spacing(2),
  },
}));
