import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
  return {
    selectableItem: {
      padding: theme.spacing(1),
      marginBottom: 20,
      cursor: 'pointer',
      backgroundColor: '#e3e3e3',
    },
  };
});
