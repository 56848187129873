import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyle } from './styles';
import { useAppData } from '../../../../storage/providers';

const GlobalLoader = () => {
  const classes = useStyle();
  const { loading } = useAppData();

  if (loading) {
    return (
      <Box className={classes.globalLoaderWrapper}>
        <CircularProgress />
      </Box>
    );
  }

  return null;
};

GlobalLoader.propTypes = {};

export default GlobalLoader;
