import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
  state: STATE_INIT,
};

const sellsSlice = createSlice({
  name: 'sells',
  initialState,
  reducers: {
    loadProductSellsStarted(state) {
      return {
        ...state,
        state: STATE_LOADING,
      };
    },
    loadProductSellsSuccess(state, { payload: items }) {
      return {
        ...state,
        items,
        state: STATE_SUCCESS,
      };
    },
    loadProductSellsFailed(state) {
      return {
        ...state,
        state: STATE_FAILED,
      };
    },
    resetProductSells() {
      return { ...initialState };
    },
  },
});

export const { loadProductSellsStarted, loadProductSellsSuccess, loadProductSellsFailed, resetProductSells } =
  sellsSlice.actions;

export default sellsSlice.reducer;
