import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
  state: STATE_INIT,
};

const clientOrdersSlice = createSlice({
  name: 'clientOrders',
  initialState,
  reducers: {
    loadOrdersStarted(state) {
      return {
        ...state,
        state: STATE_LOADING,
      };
    },
    loadOrdersSuccess(state, { payload: items }) {
      return {
        ...state,
        items,
        state: STATE_SUCCESS,
      };
    },
    loadOrdersFailed(state) {
      return {
        ...state,
        state: STATE_FAILED,
      };
    },
    resetOrders() {
      return { ...initialState };
    },
  },
});

export const { loadOrdersStarted, loadOrdersSuccess, loadOrdersFailed, addOrder, resetOrders } =
  clientOrdersSlice.actions;

export default clientOrdersSlice.reducer;
