export default {
  News: 'News',
  Logout: 'Logout',
  Submit: 'Submit',
  Id: 'Id',
  Name: 'Name',
  Password: 'Password',
  'Confirm password': 'Confirm password',
  'Sign In': 'Sign In',
  'Phone number': 'Phone number',
  Actions: 'Actions',
  Department: 'Department',
  'Create department': 'Create department',
  'Edit department': 'Edit department',
  Edit: 'Edit',
  Delete: 'Delete',
  En: 'En',
  Ru: 'Ru',
  Uk: 'Uk',
  'En Name': 'En Name',
  'Ru Name': 'Ru Name',
  'Uk Name': 'Uk Name',
  'En Description': 'En Description',
  'Ru Description': 'Ru Description',
  'Uk Description': 'Uk Description',
  'Create service group': 'Create service group',
  'Edit service group': 'Edit service group',
  'Create service': 'Create service',
  'Edit service': 'Edit service',
  Price: 'Price',
  'Price TOP': 'Price TOP',
  'Price ART': 'Price ART',
  'Approximate time (min)': 'Approximate time (min)',
  'First name': 'First name',
  'Last name': 'Last name',
  Role: 'Role',
  'Create user': 'Create user',
  'Edit user': 'Edit user',
  'No data': 'No data',
  'Create client': 'Create client',
  Email: 'Email',
  'Birth date': 'Birth date',
  Sex: 'Sex',
  Male: 'Male',
  Female: 'Female',
  'Start time': 'Start time',
  'End time': 'End time',
  'Working place': 'Working place',
  'Select department': 'Select department',
  'Create working place': 'Create working place',
  Departments: 'Departments',
  min: 'min',
  Procedure: 'Procedure',
  Subtotal: 'Subtotal',
  'Approximate time': 'Subtotal',
  'Add services': 'Add services',
  'Edit services': 'Edit services',
  Remove: 'Remove',
  Note: 'Note',
  Notes: 'Notes',
  'Total price': 'Total price',
  'Payment type': 'Payment type',
  Cash: 'Cash',
  Card: 'Card',
  Terminal: 'Terminal',
  'Services prices not matches total price, specify the reason':
    'Services prices not matches total price, specify the reason',
  Complete: 'Complete',
  Decline: 'Decline',
  Fail: 'Fail',
  Status: 'Status',
  Active: 'Active',
  Completed: 'Completed',
  Declined: 'Declined',
  Failed: 'Failed',
  Instagram: 'Instagram',
  Search: 'Search',
  Color: 'Color',
  Ok: 'Ok',
  Change: 'Change',
  'No client chosen': 'No client chosen',
  'Create appointment': 'Create appointment',
  Master: 'Master',
  'Top master': 'Top master',
  'Art director': 'Art director',
  Type: 'Type',
  Percent: 'Percent',
  Client: 'Client',
  List: 'List',
  User: 'User',
  'Users list': 'Users list',
  Shifts: 'Shifts',
  Service: 'Service',
  Order: 'Order',
  'Manage orders': 'Manage orders',
  History: 'History',
  Statistic: 'Statistic',
  'Total by master': 'Total by master',
  Load: 'Load',
  From: 'From',
  To: 'To',
  'Cash amount': 'Cash amount',
  'Card amount': 'Card amount',
  'ATM amount': 'ATM amount',
  'Payments should match total price': 'Payments should match total price',
  Cashbox: 'Cashbox',
  Balance: 'Balance',
  Income: 'Income',
  Outcome: 'Outcome',
  Amount: 'Amount',
  'Show more': 'Show more',
  Services: 'Services',
  Time: 'Time',
  Inactive: 'Inactive',
  Info: 'Info',
  'Client is not selected': 'Client is not selected',
  Month: 'Month',
  'Month dynamic': 'Month dynamic',
  'No payments': 'No payments',
  Yes: 'Yes',
  No: 'No',
  'You are about to delete this note. Please confirm.': 'You are about to delete this note. Please confirm.',
  Calculation: 'Calculation',
  'Change total': 'Change total',
  'Mark as notified?': 'Mark as notified?',
  Default: 'Default',
  'Total cannot be lass then payments': 'Total cannot be lass then payments',
  'Close orders': 'Close orders',
  'Add to completes': 'Add to completes',
  'Pay {{total}} UAH and close': 'Pay {{total}} UAH and close',
  'Clients dynamic': 'Clients dynamic',
  'New clients': 'New clients',
  'Old clients': 'Old clients',
  'Leave a message': 'Leave a message',
  'You sent': 'You sent',
  'Not returned clients': 'Not returned clients',
  Date: 'Date',
  Statements: 'Statements',
  'Too many requests': 'Too many requests',
  'Statement update will be available in {{secondsToRetry}} seconds':
    'Statement update will be available in {{secondsToRetry}} seconds',
  Volume: 'Volume',
  Product: 'Product',
  'Manage suppliers': 'Manage suppliers',
  'Manage products': 'Manage products',
  'Create product': 'Create product',
  'Start typing product name': 'Start typing product name',
  Apply: 'Apply',
  'Edit supplier': 'Edit supplier',
  'Sell products': 'Sell products',
  'Unit of measure': 'Unit of measure',
  Supplier: 'Supplier',
  Quantity: 'Quantity',
  Cost: 'Cost',
  Clear: 'Clear',
  'Who sold': 'Who sold',
  'Sell by cost': 'Sell by cost',
  Sells: 'Sells',
  'Get sells': 'Get sells',
  'Bought by {{name}}': 'Bought by {{name}}',
  'Create supplier': 'Create supplier',
  Percentage: 'Percentage',
};
