import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CommentIcon from '@material-ui/icons/Comment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useTheme } from '@material-ui/core';

import IsClientNotified from './IsClientNotified';
import ClientWidget from '../ClientWidget';
import { Text, removeMillisFromTime, formatPhone, Modal } from '../../../../common';
import { Access, Roles } from '../../../../auth';
import { AppointmentTooltipContent } from '.';
import { OrderStatus } from '../../../constants';
import { useStyle } from './styles';

const classHeaderStatusMap = {
  1: 'createdOrderHeader',
  2: 'completedOrderHeader',
  3: 'declinedOrderHeader',
  4: 'failedOrderHeader',
};

const classStatusMap = {
  1: 'createdOrder',
  2: 'completedOrder',
  3: 'declinedOrder',
  4: 'failedOrder',
};

const Appointment = (props) => {
  const { children, ...restProps } = props;
  const classes = useStyle();
  const { data } = restProps;
  const { id: orderId, isClientNotified, notes, status, client } = data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const { items } = useSelector(({ ordersToClose }) => ordersToClose);

  const isSelected = useMemo(() => items.some((_) => _.id === data.id), [data, items]);

  const headerColor = useMemo(() => {
    const statusColorMapper = {
      [OrderStatus.CREATED]: theme.palette.warning.light,
      [OrderStatus.COMPLETED]: theme.palette.success.light,
      [OrderStatus.DECLINED]: theme.palette.action.disabled,
      [OrderStatus.FAILED]: theme.palette.error.light,
    };

    return statusColorMapper[status];
  }, [status, theme]);

  const [from, to] = useMemo(() => {
    return [removeMillisFromTime(data.startTime), removeMillisFromTime(data.endTime)];
  }, [data]);

  const rightHeaderIcon = useMemo(() => {
    if (isSelected) return <DoneAllIcon fontSize="small" style={{ color: theme.palette.success.main }} />;

    return (
      <>
        <IsClientNotified isClientNotified={isClientNotified} orderId={orderId} />
        {!!notes.length && <CommentIcon style={{ marginLeft: 4 }} fontSize="small" color="disabled" />}
      </>
    );
  }, [isSelected, notes, isClientNotified, orderId, theme]);

  const triggerModal = useCallback(
    (e) => {
      e && e.stopPropagation && e.stopPropagation();
      e && e.preventDefault && e.preventDefault();
      setIsModalOpen(!isModalOpen);
    },
    [setIsModalOpen, isModalOpen]
  );

  return (
    <Appointments.Appointment
      {...restProps}
      className={clsx(classes.appointment, classes[classStatusMap[props.data.status]])}
      onClick={triggerModal}
    >
      <Box className={classes.appointmentDataContainer}>
        <Box className={clsx(classes.header, classes[classHeaderStatusMap[props.data.status]])}>
          <Box>
            <Text h5 bold>
              {from} - {to}
            </Text>
            <Text h5 upper newLine>
              {data.client.firstName}
            </Text>
          </Box>
          <Box>
            <Grid container alignItems="center">
              {rightHeaderIcon}
            </Grid>
          </Box>
        </Box>
        <Access roles={[Roles.superAdmin, Roles.manager]}>
          <Box className={classes.content}>
            {data.client.notes && (
              <Box className={classes.row}>
                <Grid container>
                  {data.client.notes.map(({ id, note }) => (
                    <Grid key={`c-${id}`} item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <AttachFileIcon style={{ marginRight: 10, fontSize: 10 }} />
                      <Text h5>{note}</Text>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {data.client.phoneNumber && (
              <Box className={classes.row}>
                <Text h5 bold>
                  {formatPhone(data.client.phoneNumber)}
                </Text>
              </Box>
            )}
          </Box>
        </Access>
        <Modal
          isOpen={isModalOpen}
          onClose={triggerModal}
          onCloseIconClick={triggerModal}
          headerColor={headerColor}
          title={
            <Box>
              <Grid container direction="column" alignItems="center" justify="center">
                <Text bold h3>{`#${orderId}`}</Text>
                <ClientWidget client={client} orderId={orderId} isClientNotified={isClientNotified} />
              </Grid>
            </Box>
          }
        >
          <AppointmentTooltipContent appointmentData={data} onComplete={triggerModal} />
        </Modal>
      </Box>
    </Appointments.Appointment>
  );
};

Appointment.propTypes = {};

export default Appointment;
