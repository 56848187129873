import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useProducts } from '../hooks';

export const ProductsContext = createContext({});

const ProductsProvider = (props) => {
  const { children } = props;
  const { isLoading, items: products, load, create, edit, sellProducts } = useProducts();

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <ProductsContext.Provider value={{ isLoading, products, create, edit, sellProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};

ProductsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductsProvider;

export const useProductsProvider = () => {
  const { isLoading, products, create, edit, sellProducts } = useContext(ProductsContext);

  return { isLoading, products, create, edit, sellProducts };
};
