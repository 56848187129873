import { createSlice } from '@reduxjs/toolkit';

export const STATE_INIT = 'STATE_INIT';
export const STATE_LOADING = 'STATE_LOADING';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAILED = 'STATE_FAILED';

const initialState = {
  items: [],
  state: STATE_INIT,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    loadProductsStarted(state) {
      return {
        ...state,
        state: STATE_LOADING,
      };
    },
    loadProductsSuccess(state, { payload: items }) {
      return {
        ...state,
        items,
        state: STATE_SUCCESS,
      };
    },
    loadProductsFailed(state) {
      return {
        ...state,
        state: STATE_FAILED,
      };
    },
    resetProducts() {
      return { ...initialState };
    },
  },
});

export const { loadProductsStarted, loadProductsSuccess, loadProductsFailed, resetProducts } = productsSlice.actions;

export default productsSlice.reducer;
