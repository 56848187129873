import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormInput, FormColorPicker } from '../../../../form/adapters';
import { useStyles } from './styles';
import { useCreateDepartment } from '../../../hooks';
import { DepartmentContext } from '../../../providers';

const initialValues = {
  enName: '',
  ruName: '',
  ukName: '',
  color: '',
};

const validationSchema = object().shape({
  enName: string().required('Required'),
  ruName: string().required('Required'),
  ukName: string().required('Required'),
  color: string().required('Required'),
});

const CreateDepartmentForm = (props) => {
  const { onSubmit } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { createDepartment } = useCreateDepartment();
  const { loading, setLoading, setDepartments, departments } = useContext(DepartmentContext);

  const onStart = () => {
    setLoading(true);
  };

  const onSuccess = ({ id, names }) => {
    const { name } = names.find((_) => _.locale === i18n.language);
    setDepartments([...departments, { id, name }]);
    setLoading(false);
    onSubmit();
  };

  const onError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleSubmit = (values) => {
    const params = {
      names: [
        { locale: 'ru', name: values.ruName },
        { locale: 'uk', name: values.ukName },
        { locale: 'en', name: values.enName },
      ],
      color: values.color,
    };
    createDepartment({ params, onStart, onSuccess, onError });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(props) => {
        const { dirty, isValid } = props;

        return (
          <Form>
            <div className={classes.row}>
              <Field
                id="enName"
                name="enName"
                label={t`En`}
                placeholder={t`En`}
                component={FormInput}
                disabled={loading}
              />
            </div>
            <div className={classes.row}>
              <Field
                id="ruName"
                name="ruName"
                label={t`Ru`}
                placeholder={t`Ru`}
                component={FormInput}
                disabled={loading}
              />
            </div>
            <div className={classes.row}>
              <Field
                id="ukName"
                name="ukName"
                label={t`Uk`}
                placeholder={t`Uk`}
                component={FormInput}
                disabled={loading}
              />
            </div>
            <div className={classes.row}>
              <Field
                id="color"
                name="color"
                label={t`Color`}
                placeholder={t`Color`}
                component={FormColorPicker}
                disabled={loading}
              />
            </div>
            <div className={classes.row}>
              <Grid container justify="flex-end">
                <Button type="submit" variant="contained" disabled={!dirty || !isValid || loading} fullWidth>
                  {loading && <CircularProgress size={16} className={classes.loadingIcon} />}
                  {t`Submit`}
                </Button>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateDepartmentForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default CreateDepartmentForm;
