import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../Text';

const NoData = () => {
  const { t } = useTranslation();

  return <Text h5 ghost>{t`No data`}</Text>;
};

NoData.propTypes = {};

export default NoData;
